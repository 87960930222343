import React, { Component } from 'react';

/** Redux process */
import { BrowserRouter as Router, Route,Link } from 'react-router-dom';
import history from './history';
import { Provider } from 'react-redux';
import store from './store';
import jwt_decode from 'jwt-decode';
import setAuthToken from './setAuthToken';
import { setCurrentCompanyUser,logoutUser } from './actions/authentication';
import Header from './components/Header';
import Footer from './components/Footer';
import Layout from './components/Layout';
import AppConstants from './AppConstants';

if(localStorage.jwtToken) {
  setAuthToken(localStorage.jwtToken);
  const decoded = jwt_decode(localStorage.jwtToken);
  store.dispatch(setCurrentCompanyUser(decoded));

  const currentTime = Date.now() / 1000;
  if(decoded.exp < currentTime) {
    store.dispatch(logoutUser());
    window.location.href = AppConstants.STAGRFP+'/'
  }
}

class App extends Component {
  render() {
    return (
      <Provider store = { store }>
        <Router history={history}>
            <div className="App">      
              <Header/>
              <Layout/>
              <Footer/>
            </div>
          
        </Router>
      </Provider>
    );
  }
}

export default App;
