import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import axios from 'axios';
import PropTypes from 'prop-types';
import logo from '../images/main-logo.png';
import { BrowserRouter as Router, Route, Link, NavLink } from "react-router-dom";
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import Login from '../pages/login';
import Register from '../pages/register';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { logoutUser, getProfile, getVendorProfile } from '../actions/authentication';
import Dropdown, { DropdownTrigger, DropdownContent } from 'react-simple-dropdown';
import nologo from '../pages/logo.jpg';
import home from "../pages/home";
import Home from '../pages/home';
import about from "../pages/about";
import services from "../pages/services";
import companydashboard from "../pages/companydashboard";
import companies from "../pages/companies";
import companyvendors from "../pages/companyvendors";
import postrfprfi from "../pages/postrfprfi";
import viewrfpinfo from "../pages/rfpviewdetails";
import rfpinfo from "../pages/rfpinfo";
import vendordetails from "../pages/viewvendordetails";
import viewcompanydetails from "../pages/viewcompanydetails";
import editrfprfi from "../pages/editrfprfi";
import companyinfo from "../pages/company-info";
import vendorinfo from "../pages/vendor-info";
import dashboard from "../pages/dashboard";
import rfprfis from "../pages/rfprfis";
import vendors from "../pages/vendors";
import changecompanypassword from "../pages/changecompanypassword";
import Changevendorpassword from "../pages/changevendorpassword";
import setcustomerpassword from "../pages/setcustomerpassword";
import viewcustomerrfprfidetails from '../pages/viewcustomerrfprfidetails';
// import Login from '../pages/Login';
import setvendorpassword from "../pages/setvendorpassword";
import allrfprfis from "../pages/allrfprfis";
import allfavoritevendors from "../pages/allfavoritevendors";
import subscribedvendors from "../pages/suscribedvendors";
import rfplikedvendors from "../pages/rfplikedvendors";
import vendorrfpfeedbacks from "../pages/vendorrfpfeedbacks";
import activerfprfis from "../pages/activerfprfis";
import likedrfprfis from "../pages/likedrfprfis";
import subscribedcompanyreports from "../pages/subscribedcompanyreports";
import appliedrfpsreport from "../pages/appliedrfpsreport";
import notappliedrfpsreport from "../pages/notappliedrfpsreport";
import wonrfprfisreport from "../pages/wonrfprfisreport";
import lostrfprfisreport from "../pages/lostrfprfisreport";
import rfpwonlosedetails from "../pages/rfpwonlosedetails";
import totalrfprfis from "../pages/totalrfprfis";
import viewfeedback from "../pages/viewfeedback";
import likedrfpvendors from "../pages/likedrfpvendors";
import appliedrfpvendors from "../pages/appliedrfpvendors";
import noprofileimg from "../pages/logo.jpg";
import AppConstants from '../AppConstants';
import rfpdetails from "../pages/rfpdetails";
import totalrfpdetails from "../pages/totalrfpdetails";
import activerfprfisdetails from "../pages/activerfprfisdetails";
import likedrfprfisdetails from "../pages/likedrfprfisdetails";
import appliedrfpdetails from "../pages/appliedrfpdetails";
import notappliedrfpdetails from "../pages/notappliedrfpdetails";
import wonrfpdetails from "../pages/wonrfpdetails";
import lostrfpdetails from "../pages/lostrfpdetails";
import companyrfpdetails from "../pages/companyrfpdetails";
import Forgotpassword from '../pages/forgotpassword';
import ReactGA from 'react-ga';
import Sitelogo from '../images/main-logo.png'
import store from '../store';

const MAX_ITEMS = 10;
class Header extends React.Component {
  initializeReactGA() {
    //console.log("hello")
    ReactGA.initialize('UA-147237199-1');
    ReactGA.pageview('/');
  }
  constructor(props) {
    //console.log("header",props);
    super(props);
    this.state = {
      isPaneOpen: false,
      isPaneOpenLeft: false,
      paneName: 'login',
      paneName1: '',
      _id: this.props.auth.user._id,
      type: this.props.auth.user.type,
      vendor_company_name: this.props.auth.user.vendor_company_name,
      vendor_company_email: this.props.auth.user.vendor_company_email,
      companyLogo: this.props.auth.user.companyLogo || nologo,

      company_name: this.props.auth.user.company_name,
      company_email: this.props.auth.user.company_email,
      company_logo: this.props.auth.user.company_logo,

      userChanged: false,
      vendornotificationcount: 0,
      companynotificationcount: 0,
      companynotificationdata: [],
      vendornotificationdata: [],
      errMsg: '',
      //activeClass: 'dashboard',
      activeClass1: 'vendordashboard',
      worksPath:"",
      hrefVal:"",
      partnersHrefVal:"",
      aboutHrefVal:"",
      homeHrefVal:""

    };
    this.myCallback = this.myCallback.bind(this);
    this.myCallback1 = this.myCallback1.bind(this);
    this.handleLinkClick = this.handleLinkClick.bind(this);

    this.myCallback2 = this.myCallback2.bind(this);
    this.myCallback3 = this.myCallback3.bind(this);
    // this.handleMenuClick=this.handleMenuClick.bind(this);
    /* if (props.auth.user.type == 'vendor') {
      //alert("You can't login if you are logged in!")
      props.history.push('/dashboard')
    }

    if (props.auth.user.type == 'company') {
      //alert("You can't login if you are logged in!")
      props.history.push('/companydashboard')
    } */

    this.workHandleLink = this.workHandleLink.bind(this);
    this.partnersHandleLink = this.partnersHandleLink.bind(this);
    this.aboutHandleLink = this.aboutHandleLink.bind(this);
    this.homeHandleLink = this.homeHandleLink.bind(this);

  }

  workHandleLink(val) { 
    this.setState({hrefVal:"#works"});
    this.props.history.push(`${AppConstants.STAGRFP}/${val}`);
  }

  partnersHandleLink(val) {
    this.setState({partnersHrefVal:"#partners"});
    this.props.history.push(`${AppConstants.STAGRFP}/${val}`);
  }
  aboutHandleLink(val) {
    this.setState({aboutHrefVal:"#about"});
    this.props.history.push(`${AppConstants.STAGRFP}/${val}`);
  }
  homeHandleLink(val){
    this.setState({homeHrefVal:"#home"});
    this.props.history.push(`${AppConstants.STAGRFP}/${val}`);
  }

  
  handleLinkClick() {
    this.refs.dropdown.hide();
    
  }
  myCallback3(e) {
    //console.log(e)
    this.setState({ paneName1: "login" })
  }
  myCallback2(e) {
    //console.log(e)
    this.setState({ paneName1: "forgotpassword" })

  }
  myCallback = (dataFromChild) => {
    // console.log(dataFromChild);
    this.setState({ paneName: 'login' });
  }
  myCallback1(e) {
    //console.log(e)
    this.setState({ paneName: 'register' });
  }
  /* componentWillReceiveProps(nextProps) {  
    console.log(this.props.auth);
  } */
  componentDidMount() {
    // console.log(this.props.auth.user.type)
    // console.log(window.location.hash.substr(1))
    // console.log(window.location.pathname)

     this.setState({worksPath:document.getElementById('works')});
   
    if (localStorage.jwtToken != undefined && this.props.auth.isAuthenticated == true && this.props.auth.user.type == 'vendor') {
      this.props.getVendorProfile(this.props.auth.user._id)
      //Notification Count
      fetch(AppConstants.API + '/reports/getallvedornotificationcount/' + this.props.auth.user._id).then(response => response.json())
        .then(data => {
          if (data.Status == "Success") {
            this.setState({

              vendornotificationcount: data.vendornotificationcount

            });
          }
          else {
            this.setState({ vendornotificationcount: 0 });
          }
        });
    }
    if (localStorage.jwtToken != undefined && this.props.auth.isAuthenticated == true && this.props.auth.user.type == 'company') {
      //console.log(this.props.auth.user);
      this.props.getProfile(this.props.auth.user._id)
      fetch(AppConstants.API + '/reports/getallcompanynotificationcount/' + this.props.auth.user._id).then(response => response.json())
        .then(data => {
          if (data.Status == "Success") {
            this.setState({
              companynotificationcount: data.companynotificationcount
            });
          }
          else {
            this.setState({ companynotificationcount: 0 });
          }
        });
    }
  }


  companynotifications = params => e => {
    //alert('hii');
    e.preventDefault();
    //console.log(params);
    const companynotificationdata = {
      company_id: params
    }
    //console.log(companynotificationdata);
    axios.post(AppConstants.API + '/notifications/companynotifications', companynotificationdata)
      .then(res => {
        //console.log(res.data);
        if (res.data.Status == "Success") {
          this.setState({ companynotificationdata: res.data.companynotificationdata });
          fetch(AppConstants.API + '/reports/getallcompanynotificationcount/' + this.props.auth.user._id).then(response => response.json())
            .then(data => {
              if (data.Status == "Success") {
                this.setState({
                  companynotificationcount: data.companynotificationcount
                });
              }
              else {
                this.setState({ companynotificationcount: 0 });
              }
            });
        }
        else {
          this.setState({ errMsg: res.data.msg });
        }
      });
  }


  vendornotifications = params => e => {
    // alert('hii');
    e.preventDefault();
    //console.log(params);
    const vendordata = {
      vendor_id: params
    }
    //console.log(vendordata);
    axios.post(AppConstants.API + '/notifications/vendornotifications', vendordata)
      .then(res => {
        //console.log(res.data);
        if (res.data.Status == "Success") {
          this.setState({ vendornotificationdata: res.data.vendornotificationdata });
          fetch(AppConstants.API + '/reports/getallvedornotificationcount/' + this.props.auth.user._id).then(response => response.json())
            .then(data => {
              if (data.Status == "Success") {
                this.setState({
                  vendornotificationcount: data.vendornotificationcount
                });
              }
              else {
                this.setState({ vendornotificationcount: 0 });
              }
            });
        }
        else {
          this.setState({ errMsg: res.data.msg });
        }
      });
  }

  onLogout(e) {
    e.preventDefault();
    this.props.logoutUser(this.props.history);
  }

  render() {
    this.initializeReactGA();
    // console.log(this.props,this.props.auth.isAuthenticated);
    if (localStorage.jwtToken != undefined && this.props.auth.isAuthenticated == true && this.props.auth.user._id != '' && this.props.auth.user.type == 'company') {

      const { location } = this.props;
      const companydashboardClass = location.pathname.match(/^\/companydashboard/) ? "active" : "";
      const companiesClass = location.pathname.match(/^\/companies/) ? "active" : "";
      const vendorslistClass = location.pathname.match(/^\/vendorslist/) ? "active" : "";
      const postrfprfiClass = location.pathname.match(/^\/postopportunity/) ? "active" : "";

      let userImage;
      if (this.props.auth.user.company_logo == '' || this.props.auth.user.company_logo == undefined) {
        userImage = noprofileimg;
      }
      else {
        userImage = this.props.auth.user.company_logo;
      }

      return <Router>
          <div id="o-wrapper" className="o-wrapper">
            <div id="c-mask" className="c-mask" />
            <header>
              <Container>
                <div className="inner-header">
                  <Row>
                    <Col md="2">
                      <div className="logo">
                        <a href={`${AppConstants.SITEURL}`}>
                          <img src={logo} alt="" />
                        </a>
                      </div>
                      {/* <div className="logo"><Link to="/"><img src={logo} alt=""  /></Link></div> */}
                    </Col>
                    <Col md="10">
                      {location.pathname == AppConstants.STAGRFP + "/" ? <div className="header-right user-header">
                          <div className="nav-m">
                            <nav id="c-menu--slide-right" className="c-menu c-menu--slide-right">
                              <button className="c-menu__close">Close Menu </button>
                              <ul className="slimmenu dropmenu navbar-nav">
                                <li className="active">
                                  <a href="#home" className=" nav-link">
                                    Home
                                  </a>
                                </li>
                                <li title="How It Works">
                                  <a href="#works" className=" nav-link">
                                    How It Works
                                  </a>
                                </li>
                                {/* <li><Link to='/' >About Us</Link></li> */}
                                <li titledashclass="Partners">
                                  <a href="#partners" className=" nav-link">
                                    Partners
                                  </a>
                                </li>
                                {/* <a href="/companydashboard">Dashboard</a> </li>*/}
                                <li title="About Us">
                                  <a href="#about" className=" nav-link">
                                    About Us
                                  </a>
                                </li>
                                <li title="Dashboard">
                                  <a href={`${AppConstants.STAGRFP}${"/companydashboard"}`}>Dashboard</a>
                                </li>
                              </ul>
                            </nav>
                          </div>
                          {/* <button id="c-button--slide-right" className="c-button"></button>                         */}
                          {/* {this.props.auth.user.company_name.substring(0,5)} */}
                          <Dropdown>
                            <DropdownTrigger>
                              <div className="user-login-avatar">
                                <img src={userImage} />
                              </div> <em>
                                <span>{this.props.auth.user.company_name}</span>
                              </em>
                            </DropdownTrigger>
                            <DropdownContent className="profile-drop">
                              <ul>
                                <li title="Edit Profile">
                                  {/* <Link to="/companyinfo" title="Edit Profile"><i class="fa fa-edit" aria-hidden="true"></i>Edit Profile</Link> */}
                                  <a href={`${AppConstants.STAGRFP}${"/companyinfo"}`} title="Edit Profile">
                                    <i class="fa fa-edit" aria-hidden="true" />
                                    Edit Profile
                                  </a>
                                </li>
                                <li title="Change Password">
                                  <Link to={`${AppConstants.STAGRFP}${"/changepassword"}`} title="Change Password">
                                    <i class="fa fa-lock" aria-hidden="true" />
                                    Change Password
                                  </Link>
                                </li>

                                <li className="mobile-nav" title="Dashboard">
                                  <a href={`${AppConstants.STAGRFP}${"/companydashboard"}`} className={companydashboardClass}>
                                    <i class="fa fa-dashboard" aria-hidden="true" />
                                    Dashboard
                                  </a>
                                </li>

                                <li className="mobile-nav" title="Companies">
                                  <Link to={`${AppConstants.STAGRFP}${"/companies"}`} className={companiesClass}>
                                    <i class="fa fa-building" aria-hidden="true" />
                                    Companies
                                  </Link>
                                </li>
                                {/* <li className="mobile-nav" title="Vendors"><Link to={`${AppConstants.STAGRFP}${"/vendorslist"}`} className={vendorslistClass}><i class="fa fa-users" aria-hidden="true"></i>Vendors</Link></li> */}
                                <li className="mobile-nav" title="Post Opportunity">
                                  <Link to={`${AppConstants.STAGRFP}${"/postopportunity"}`} className={postrfprfiClass}>
                                    <i class="fa fa-clock-o" aria-hidden="true" />
                                    Post Opportunity
                                  </Link>
                                </li>
                                <li>
                                  <a href="javascript:void(0);" onClick={this.onLogout.bind(this)} title="Log Out">
                                    <i class="fa fa-sign-out" aria-hidden="true" />
                                    Log Out
                                  </a>
                                </li>
                              </ul>
                            </DropdownContent>
                          </Dropdown>
                        </div> : <div className="header-right user-header">
                          <div className="nav-m">
                            <nav id="c-menu--slide-right" className="c-menu c-menu--slide-right">
                              <button className="c-menu__close">Close Menu </button>
                              <ul className="slimmenu dropmenu ">
                                <li className={this.props.auth.activeClass == "dashboard" ? "active" : ""} title="Dashboard">
                                  <Link to={`${AppConstants.STAGRFP}${"/companydashboard"}`}>Dashboard</Link>
                                </li>

                                <li className={this.props.auth.activeClass == "companies" ? "active" : ""} title="Companies">
                                  <Link to={`${AppConstants.STAGRFP}${"/companies"}`}>Companies</Link>
                                </li>
                                {/* <li className={this.props.auth.activeClass == 'vendors' ? 'active' : ''}
                                title="Vendors"><Link to={`${AppConstants.STAGRFP}${"/vendorslist"}`}>Vendors</Link></li> */}
                                <li className={this.props.auth.activeClass == "rfp" ? "active" : ""} title="Post Opportunity">
                                  <Link to={`${AppConstants.STAGRFP}${"/postopportunity"}`}>
                                    Post Opportunity
                                  </Link>
                                </li>
                              </ul>
                            </nav>
                          </div>
                          <Dropdown className="notification-active">
                            <DropdownTrigger>
                              <div className="notification-icon" onClick={this.companynotifications(this.props.auth.user._id)}>
                                <i class="fa fa-bell" aria-hidden="true" />
                                {this.state.companynotificationcount == 0 ? "" : <samp>
                                    {this.state.companynotificationcount}
                                  </samp>}
                              </div>
                            </DropdownTrigger>
                            <DropdownContent className="notification-list">
                              <ul>
                                {this.state.companynotificationdata && this.state.companynotificationdata.map(
                                    (recipe) => {
                                      return (
                                        <li>
                                          <samp>
                                            <img src={recipe.companyLogo ? recipe.companyLogo : noprofileimg} />
                                          </samp>
                                          <a href="#">
                                            {recipe.vendor_company_name} {recipe.notificationtype}{" "}
                                            {recipe.name_ofrfp}
                                          </a>
                                          {/* <a href="#">{recipe.notificationtype} for {recipe.vendor_company_name}</a> */}
                                        </li>
                                      );
                                    }
                                  )}
                                {this.state.companynotificationdata.length == 0 && <li>No Notifications</li>}
                              </ul>
                            </DropdownContent>
                          </Dropdown>
                          {/* {this.props.auth.user.company_name.substring(0,5)} */}
                          <Dropdown ref="dropdown">
                            <DropdownTrigger>
                              <div className="user-login-avatar">
                                <img src={userImage} />
                              </div> <em>
                                <span>{this.props.auth.user.company_name}</span>
                              </em>
                            </DropdownTrigger>
                            <DropdownContent onClick={() => {
                                this.refs.dropdown.hide();
                              }} className="profile-drop">
                              <ul>
                                <li title="Edit Profile">
                                  <Link to={`${AppConstants.STAGRFP}${"/companyinfo"}`} title="Edit Profile">
                                    <i class="fa fa-edit" aria-hidden="true" />
                                    Edit Profile
                                  </Link>
                                </li>
                                <li title="Change Password">
                                  <Link to={`${AppConstants.STAGRFP}${"/changepassword"}`} title="Change Password">
                                    <i class="fa fa-lock" aria-hidden="true" />
                                    Change Password
                                  </Link>
                                </li>
                                <li className="mobile-nav" title="Dashboard">
                                  <Link to={`${AppConstants.STAGRFP}${"/companydashboard"}`} className={companiesClass}>
                                    <i class="fa fa-dashboard" aria-hidden="true" />
                                    Dashboard
                                  </Link>
                                </li>
                                <li className="mobile-nav" title="Companies">
                                  <Link to={`${AppConstants.STAGRFP}${"/companies"}`} className={companiesClass}>
                                    <i class="fa fa-building" aria-hidden="true" />
                                    Companies
                                  </Link>
                                </li>
                                {/* <li className="mobile-nav" title="Vendors"><Link to={`${AppConstants.STAGRFP}${"/vendorslist"}`} className={vendorslistClass}><i class="fa fa-users" aria-hidden="true"></i>Vendors</Link></li> */}
                                <li className="mobile-nav" title="Post Opportunity">
                                  <Link to={`${AppConstants.STAGRFP}${"/postopportunity"}`} className={postrfprfiClass}>
                                    <i class="fa fa-clock-o" aria-hidden="true" />
                                    Post Opportunity
                                  </Link>
                                </li>
                                <li>
                                  <a href="javascript:void(0);" onClick={this.onLogout.bind(this)} title="Log Out">
                                    <i class="fa fa-sign-out" aria-hidden="true" />
                                    Log Out
                                  </a>
                                </li>
                              </ul>
                            </DropdownContent>
                          </Dropdown>
                        </div>}
                    </Col>
                  </Row>
                </div>
              </Container>
            </header>
            <Route exact path={`${AppConstants.STAGRFP}${"/"}`} component={home} />
            <Route path={`${AppConstants.STAGRFP}${"/companydashboard"}`} component={companydashboard} />
            <Route path={`${AppConstants.STAGRFP}${"/vendorslist"}`} component={companyvendors} />
            <Route path={`${AppConstants.STAGRFP}${"/companies"}`} component={companies} />
            <Route path={`${AppConstants.STAGRFP}${"/postopportunity"}`} component={postrfprfi} />
            <Route path={`${AppConstants.STAGRFP}${"/opportunityinfo/:id"}`} component={rfpinfo} />
            <Route path={`${AppConstants.STAGRFP}${"/viewopportunityinfo/:id"}`} component={viewrfpinfo} />
            <Route path={`${AppConstants.STAGRFP}${"/viewvendordetails/:id"}`} component={vendordetails} />
            <Route path={`${AppConstants.STAGRFP}${"/viewcompanydetails/:id"}`} component={viewcompanydetails} />
            <Route path={`${AppConstants.STAGRFP}${"/editopportunityinfo/:id"}`} component={editrfprfi} />
            <Route path={`${AppConstants.STAGRFP}${"/companyinfo"}`} component={companyinfo} />
            <Route path={`${AppConstants.STAGRFP}${"/viewcustomeropportunitiesdetails/:vendor_id/:rfprfi_id"}`} component={viewcustomerrfprfidetails} />
            <Route path={`${AppConstants.STAGRFP}${"/allopportunities"}`} component={allrfprfis} />
            <Route path={`${AppConstants.STAGRFP}${"/allfavoritevendors"}`} component={allfavoritevendors} />
            <Route path={`${AppConstants.STAGRFP}${"/subscribedvendors"}`} component={subscribedvendors} />
            <Route path={`${AppConstants.STAGRFP}${"/opportunitylikedvendors"}`} component={rfplikedvendors} />
            <Route path={`${AppConstants.STAGRFP}${"/vendoropportunityfeedbacks"}`} component={vendorrfpfeedbacks} />
            <Route path={`${AppConstants.STAGRFP}${"/opportunitieswonlosedetails/:vendor_id/:rfprfi_id"}`} component={rfpwonlosedetails} />
            <Route path={`${AppConstants.STAGRFP}${"/viewfeedback/:rfprfi_id"}`} component={viewfeedback} />
            <Route path={`${AppConstants.STAGRFP}${"/likedopportunityvendors/:rfprfi_id"}`} component={likedrfpvendors} />
            <Route path={`${AppConstants.STAGRFP}${"/appliedopportunityvendors/:rfprfi_id"}`} component={appliedrfpvendors} />
            <Route path={`${AppConstants.STAGRFP}${"/opportunitydetails/:id"}`} component={rfpdetails} />
            <Route path={`${AppConstants.STAGRFP}${"/changepassword"}`} component={changecompanypassword} />
          </div>
        </Router>;
    } else if (localStorage.jwtToken != undefined && this.props.auth.isAuthenticated == true && this.props.auth.user._id != '' && this.props.auth.user.type == 'vendor') {
      // console.log(this.props.auth.user);

      const { location } = this.props;
      const dashboardClass = location.pathname.match(/^\/dashboard/) ? "active" : "";
      const vendorsClass = location.pathname.match(/^\/vendors/) ? "active" : "";
      const companyClass = location.pathname.match(/^\/companies/) ? "active" : "";
      const rfprfisClass = location.pathname.match(/^\/totalopportunitieslist/) ? "active" : "";

      let userImage;
      if (this.props.auth.user.companyLogo == '' || this.props.auth.user.companyLogo == undefined) {
        userImage = noprofileimg;
      }
      else {
        userImage = this.props.auth.user.companyLogo;
      }

      return <Router>
          <div id="o-wrapper" className="o-wrapper">
            <div id="c-mask" className="c-mask" />
            <header>
              <Container>
                <div className="inner-header">
                  <Row>
                    <Col md="2">
                      <div className="logo">
                        <a href={`${AppConstants.SITEURL}`}>
                          <img src={logo} alt="" />
                        </a>
                      </div>
                      {/* <div className="logo"><Link to="/"><img  src={logo} alt="" /></Link></div> */}
                    </Col>
                    <Col md="10">
                      {location.pathname == AppConstants.STAGRFP + "/" ? <div className="header-right user-header">
                          <div className="nav-m">
                            <nav id="c-menu--slide-right" className="c-menu c-menu--slide-right">
                              <button className="c-menu__close">Close Menu</button>
                              <ul className="slimmenu dropmenu navbar-nav">
                                <li className="active" title="Home">
                                  <a href="#home" className=" nav-link">
                                    Home
                                  </a>
                                </li>
                                <li title="How It Works">
                                  <a href="#works" className=" nav-link">
                                    How It Works
                                  </a>
                                </li>
                                {/* <li><Link to='/' >About Us</Link></li> */}
                                <li title="Partners">
                                  <a href="#partners" className=" nav-link">
                                    Partners
                                  </a>
                                </li>
                                {/* <a href="/companydashboard">Dashboard</a> </li>*/}
                                <li title="About Us">
                                  <a href="#about" className=" nav-link">
                                    About Us
                                  </a>
                                </li>
                                <li title="Dashboard">
                                  <a href={`${AppConstants.STAGRFP}${"/dashboard"}`}>Dashboard</a>
                                </li>
                              </ul>
                            </nav>
                          </div>
                          {/* <button id="c-button--slide-right" className="c-button"></button> */}

                          <Dropdown>
                            <DropdownTrigger>
                              <div className="user-login-avatar">
                                <img src={userImage} />
                              </div>
                              <em>
                                <span>{this.props.auth.user.vendor_company_name}</span>
                              </em>{" "}
                            </DropdownTrigger>
                            <DropdownContent>
                              <ul>
                                <li title="Edit Profile">
                                  {/* <Link to="/vendorinfo" title="Edit Profile"><i class="fa fa-edit" aria-hidden="true"></i>Edit Profile</Link> */}
                                  <a href={`${AppConstants.STAGRFP}${"/vendorinfo"}`} title="Edit Profile">
                                    <i class="fa fa-edit" aria-hidden="true" />
                                    Edit Profile
                                  </a>
                                </li>
                                <li title="Change Password">
                                  <Link to={`${AppConstants.STAGRFP}${"/changevendorpassword"}`} title="Change Password">
                                    <i class="fa fa-lock" aria-hidden="true" />
                                    Change Password
                                  </Link>
                                </li>

                                <li className="mobile-nav" title="Dashboard">
                                  <a href={`${AppConstants.STAGRFP}${"/dashboard"}`} className={dashboardClass}>
                                    <i class="fa fa-dashboard" aria-hidden="true" />
                                    Dashboard
                                  </a>
                                </li>

                                <li className="mobile-nav" title="Vendors">
                                  <Link to={`${AppConstants.STAGRFP}${"/vendors"}`} className={vendorsClass}>
                                    <i class="fa fa-users" aria-hidden="true" />
                                    Vendors
                                  </Link>
                                </li>
                                {/* <li className="mobile-nav" title="Companies"><Link to={`${AppConstants.STAGRFP}${"/companies"}`} className={companyClass}><i class="fa fa-building" aria-hidden="true"></i>Companies</Link></li> */}
                                <li className="mobile-nav" title="Opportunities">
                                  <Link to={`${AppConstants.STAGRFP}${"/totalopportunitieslist"}`} className={rfprfisClass}>
                                    <i class="fa fa-clock-o" aria-hidden="true" />
                                    Opportunities
                                  </Link>
                                </li>
                                <li>
                                  <a href="javascript:void(0);" onClick={this.onLogout.bind(this)} title="Log Out">
                                    <i class="fa fa-sign-out" aria-hidden="true" />
                                    Log Out
                                  </a>
                                </li>
                              </ul>
                            </DropdownContent>
                          </Dropdown>
                        </div> : <div className="header-right user-header">
                          <div className="nav-m">
                            <nav id="c-menu--slide-right" className="c-menu c-menu--slide-right">
                              <button className="c-menu__close">Close Menu </button>
                              <ul className="slimmenu dropmenu">
                                <li className={this.props.auth.activeClass == "vendordashboard" ? "active" : ""} title="Dashboard">
                                  <Link to={`${AppConstants.STAGRFP}${"/dashboard"}`}>Dashboard</Link>
                                </li>
                                <li className={this.props.auth.activeClass == "vendorvendors" ? "active" : ""} title="Vendors">
                                  <Link to={`${AppConstants.STAGRFP}${"/vendors"}`}>Vendors</Link>
                                </li>
                                {/* <li className={this.props.auth.activeClass == 'vendorcompanies' ? 'active' : ''} title="Companies"><Link to={`${AppConstants.STAGRFP}${"/companies"}`}>Companies</Link></li> */}
                                <li className={this.props.auth.activeClass == "vendorrfps" ? "active" : ""} title="Opportunities">
                                  <Link to={`${AppConstants.STAGRFP}${"/totalopportunitieslist"}`}>
                                    Opportunities
                                  </Link>
                                </li>
                              </ul>
                            </nav>
                          </div>
                          {/* <button id="c-button--slide-right" className="c-button"></button> */}
                          <Dropdown className="notification-active">
                            <DropdownTrigger>
                              <div className="notification-icon" onClick={this.vendornotifications(this.props.auth.user._id)}>
                                <i class="fa fa-bell" aria-hidden="true" />
                                {this.state.vendornotificationcount == 0 ? "" : <samp>
                                    {this.state.vendornotificationcount}
                                  </samp>}
                              </div>
                            </DropdownTrigger>
                            <DropdownContent className="notification-list">
                              <ul>
                                {this.state.vendornotificationdata && this.state.vendornotificationdata.map(
                                    (recipe) => {
                                      return (
                                        <li>
                                          <samp>
                                            <img src={recipe.companyLogo ? recipe.companyLogo : noprofileimg} />
                                          </samp>
                                          <a href="#">
                                            {recipe.notificationtype} {recipe.name_ofrfp}
                                          </a>
                                          {/* <a href="#">{recipe.notificationtype} for {recipe.company_name}</a> */}
                                        </li>
                                      );
                                    }
                                  )}
                                {this.state.vendornotificationdata.length == 0 && <li>No Notifications</li>}
                              </ul>
                            </DropdownContent>
                          </Dropdown>
                          <Dropdown ref="dropdown">
                            <DropdownTrigger>
                              <div className="user-login-avatar">
                                <img src={userImage} />
                              </div> <em>
                                <span>{this.props.auth.user.vendor_company_name}</span>
                              </em>
                            </DropdownTrigger>
                            <DropdownContent onClick={() => {
                                this.refs.dropdown.hide();
                              }}>
                              <ul>
                                <li title="Edit Profile">
                                  <Link to={`${AppConstants.STAGRFP}${"/vendorinfo"}`} title="Edit Profile">
                                    <i class="fa fa-edit" aria-hidden="true" />
                                    Edit Profile
                                  </Link>
                                </li>
                                <li title="Change Password">
                                  <Link to={`${AppConstants.STAGRFP}${"/changevendorpassword"}`} title="Change Password">
                                    <i class="fa fa-lock" aria-hidden="true" />
                                    Change Password
                                  </Link>
                                </li>
                                <li className="mobile-nav" title="Dashboard">
                                  <Link to={`${AppConstants.STAGRFP}${"/dashboard"}`} className={vendorsClass}>
                                    <i class="fa fa-dashboard" aria-hidden="true" />
                                    Dashboard
                                  </Link>
                                </li>
                                <li className="mobile-nav" title="Vendors">
                                  <Link to={`${AppConstants.STAGRFP}${"/vendors"}`} className={vendorsClass}>
                                    <i class="fa fa-users" aria-hidden="true" />
                                    Vendors
                                  </Link>
                                </li>
                                {/* <li className="mobile-nav" title="Companies"><Link to={`${AppConstants.STAGRFP}${"/companies"}`} className={companyClass}><i class="fa fa-building" aria-hidden="true"></i>Companies</Link></li> */}
                                <li className="mobile-nav" title="Opportunities">
                                  <Link to={`${AppConstants.STAGRFP}${"/totalopportunitieslist"}`} className={rfprfisClass}>
                                    <i class="fa fa-clock-o" aria-hidden="true" />
                                    Opportunities
                                  </Link>
                                </li>
                                <li>
                                  <a href="javascript:void(0);" onClick={this.onLogout.bind(this)} title="Log Out">
                                    <i class="fa fa-sign-out" aria-hidden="true" />
                                    Log Out
                                  </a>
                                </li>
                              </ul>
                            </DropdownContent>
                          </Dropdown>
                        </div>}
                    </Col>
                  </Row>
                </div>
              </Container>
            </header>
            <Route exact path={`${AppConstants.STAGRFP}`} component={home} />
            <Route path={`${AppConstants.STAGRFP}${"/dashboard"}`} component={dashboard} />
            <Route path={`${AppConstants.STAGRFP}${"/vendors"}`} component={vendors} />
            <Route path={`${AppConstants.STAGRFP}${"/companies"}`} component={companies} />
            <Route path={`${AppConstants.STAGRFP}${"/relatedopportunities"}`} component={rfprfis} />
            <Route path={`${AppConstants.STAGRFP}${"/vendorinfo"}`} component={vendorinfo} />
            <Route path={`${AppConstants.STAGRFP}${"/totalbasicdetails/:id"}`} component={totalrfpdetails} />
            <Route path={`${AppConstants.STAGRFP}${"/allopportunitiesdetails/:id"}`} component={activerfprfisdetails} />
            <Route path={`${AppConstants.STAGRFP}${"/likedopportunitiesdetails/:id"}`} component={likedrfprfisdetails} />
            <Route path={`${AppConstants.STAGRFP}${"/appliedopportunitiesdetails/:id"}`} component={appliedrfpdetails} />
            <Route path={`${AppConstants.STAGRFP}${"/notappliedopportunitiesdetails/:id"}`} component={notappliedrfpdetails} />
            <Route path={`${AppConstants.STAGRFP}${"/wonopportunitiesdetails/:id"}`} component={wonrfpdetails} />
            <Route path={`${AppConstants.STAGRFP}${"/lostopportunitiesdetails/:id"}`} component={lostrfpdetails} />
            <Route path={`${AppConstants.STAGRFP}${"/companyopportunitiesdetails/:id"}`} component={companyrfpdetails} />
            <Route path={`${AppConstants.STAGRFP}${"/viewopportunityinfo/:id"}`} component={viewrfpinfo} />
            <Route path={`${AppConstants.STAGRFP}${"/viewvendordetails/:id"}`} component={vendordetails} />
            <Route path={`${AppConstants.STAGRFP}${"/viewcompanydetails/:id"}`} component={viewcompanydetails} />
            <Route path={`${AppConstants.STAGRFP}${"/viewcustomeropportunitiesdetails/:vendor_id/:rfprfi_id"}`} component={viewcustomerrfprfidetails} />
            <Route path={`${AppConstants.STAGRFP}${"/allopportunities"}`} component={activerfprfis} />
            <Route path={`${AppConstants.STAGRFP}${"/likedopportunities"}`} component={likedrfprfis} />
            <Route path={`${AppConstants.STAGRFP}${"/subscribedcompanyreports"}`} component={subscribedcompanyreports} />
            <Route path={`${AppConstants.STAGRFP}${"/appliedopportunitiesreport"}`} component={appliedrfpsreport} />
            <Route path={`${AppConstants.STAGRFP}${"/notappliedopportunitiesreport"}`} component={notappliedrfpsreport} />
            <Route path={`${AppConstants.STAGRFP}${"/wonopportunitiesreport"}`} component={wonrfprfisreport} />
            <Route path={`${AppConstants.STAGRFP}${"/lostopportunitiesreport"}`} component={lostrfprfisreport} />
            <Route path={`${AppConstants.STAGRFP}${"/opportunitieswonlosedetails/:vendor_id/:rfprfi_id"}`} component={rfpwonlosedetails} />
            <Route path={`${AppConstants.STAGRFP}${"/totalopportunitieslist"}`} component={totalrfprfis} />
            <Route path={`${AppConstants.STAGRFP}${"/opportunitydetails/:id"}`} component={rfpdetails} />
            <Route path={`${AppConstants.STAGRFP}${"/changevendorpassword"}`} component={Changevendorpassword} />
          </div>
        </Router>;
    }
    //  else if(localStorage.length===0){
    //   console.log("df")
    //   return(
    //     <div>
    //       <Home/>
    //     </div>
    //   )

    // }
    else {

      return <Router>
          <div id="o-wrapper" className="o-wrapper">
            <div id="c-mask" className="c-mask" />
            <header>
              <Container>
                <div className="inner-header">
                  <Row>
                    <Col md="2">
                      <div className="logo">
                        {/* <a href="/" ><img src={logo} alt="" style={{width:180, height:80}} /></a> */}
                        <Link to={`${AppConstants.SITEURL}`}>
                          <img src={Sitelogo} alt="" />
                        </Link>
                      </div>
                    </Col>
                    <Col md="10">
                      <div className="header-right">
                        <div className="nav-m">
                          <nav id="c-menu--slide-right" className="c-menu c-menu--slide-right">
                            <button className="c-menu__close">Close Menu </button>
                            <ul className="slimmenu navbar-nav">
                              <li className="active" title="Home">
                                <a className=" nav-link" onClick={() => this.homeHandleLink("#home")} href={this.state.homeHrefVal}>
                                  Home
                                </a>
                              </li>
                              {/* <li><Link to='/' >About Us</Link></li> */}
                              {/* <a href="/services">Partners</a> </li>*/}

                              {/* <li title="How It Works"> <a onClick={this.handleLink} href={this.state.hrefVal}> How It Works </a></li> */}
                              <li title="How It Works">
                                <a className="nav-link" onClick={() => this.workHandleLink("#works")} href={this.state.hrefVal}>
                                  How It Works
                                </a>
                              </li>
                              <li title="Partners">
                                <a className="nav-link" onClick={() => this.partnersHandleLink("#partners")} href={this.state.partnersHrefVal}>
                                  Partners
                                </a>
                              </li>
                              <li title="About Us">
                                <a className="nav-link" onClick={() => this.aboutHandleLink("#about")} href={this.state.aboutHrefVal}>
                                  About Us
                                </a>
                              </li>
                              <li className="login-btn">
                                <Link to={`${AppConstants.STAGRFP}${"/login"}`}>Login</Link>
                              </li>
                              <li className="register-btn">
                                <Link to={`${AppConstants.STAGRFP}${"/register"}`}>Register</Link>
                              </li>
                              {/* <li className='login-btn'><Link to={`${AppConstants.STAGRFP}${"/userlogin"}`} className='login-btn'>Login</Link></li> */}
                              {/* <Route path={`${AppConstants.STAGRFP}${"/userlogin"}`} component={UserLogin} / > */}
                              {/* <li title="Login" className='login-btn'><a  onClick={() => this.setState({ isPaneOpen: true,paneName:'login',paneName1:'login' })} className="btn btn-white-bg btn-sm">Login</a></li> */}
                              {/* <li title="Register" className='register-btn'><a onClick={() => this.setState({ isPaneOpen: true,paneName:'register' })} className="btn btn-sm register">Register</a></li> */}
                            </ul>
                          </nav>
                        </div>

                        <button id="c-button--slide-right" className="c-button" />
                        <SlidingPane className={this.state.paneName} overlayClassName="some-custom-overlay-class" isOpen={this.state.isPaneOpen} onRequestClose={() => {
                            // triggered on "<" on left top click or on outside click
                            this.setState({ isPaneOpen: false });
                          }}>
                          {this.state.paneName == "login" ? this.state.paneName1 == "forgotpassword" ? <Forgotpassword callbackFromParent3={this.myCallback3} /> : <Login callbackFromParent1={this.myCallback1} callbackFromParent2={this.myCallback2} /> : <Register callbackFromParent={this.myCallback} />}
                        </SlidingPane>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Container>
            </header>
            {/* <Home/> */}
            {/* <Route exact  path="/" component={home} /> */}
            {/* <Route path="/about" component={about} /> */}
            {/* <Route path="/home" component={home} />  */}
            {/* <Route path="/services" component={services} /> */}
            <Route exact path={`${AppConstants.STAGRFP}`} component={Home} />
            <Route path={`${AppConstants.STAGRFP}${"/login"}`} component={Login} />
            <Route path={`${AppConstants.STAGRFP}${"/register"}`} component={Register} />
            <Route path={`${AppConstants.STAGRFP}${"/forgotpassword"}`} component={Forgotpassword} />
            <Route path={`${AppConstants.STAGRFP}${"/changevendorpassword"}`} component={Changevendorpassword} />
            <Route exact path={`${AppConstants.STAGRFP}${"/viewcustomeropportunitiesdetails/:vendor_id/:rfprfi_id"}`} component={viewcustomerrfprfidetails} />
            <Route path={`${AppConstants.STAGRFP}${"/setcustomerpassword/:id"}`} component={setcustomerpassword} />
            <Route path={`${AppConstants.STAGRFP}${"/setvendorpassword/:id"}`} component={setvendorpassword} />
            <Route exact path={`${AppConstants.STAGRFP}${"/opportunitieswonlosedetails/:vendor_id/:rfprfi_id"}`} component={rfpwonlosedetails} />
            <Route path={`${AppConstants.STAGRFP}${"/opportunitydetails/:id"}`} component={rfpdetails} />
            {/* <Route exact path={`${AppConstants.STAGRFP}${"/userlogin"}`} component={UserLogin} /> */}
            {/* <Home/> */}
            {/* {window.location.pathname!=="/"?<Home/>:""} */}
          </div>
        </Router>;
    }

  }
}
Header.propTypes = {
  auth: PropTypes.object.isRequired
};
const mapStateToProps = (state) => ({
  auth: state.auth,
})

//export default Header;
export default connect(mapStateToProps, { getProfile, getVendorProfile, logoutUser })(withRouter(Header));
