import React, { Component } from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import axios from "axios";
import PropTypes from "prop-types";
import { Container, Row, Col } from "reactstrap";
import AppConstants from "../AppConstants";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import BrowserHistory from "react-router/lib/browserHistory";
import {
  logoutUser,
  getProfile,
  dashboard,
  handlevendordashboard,
} from "../actions/authentication";
import { ReactTitle } from "react-meta-tags";
import swal from "sweetalert";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";

//export default class  vendordetail extends Component{
class rfpviewdetails extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      id: this.props.match.params.id,
      name_ofrfp: "",
      minority_business: false,
      women_owned_business: false,
      rfprfiprofilesdata: [],
      certifications: [],
      vendordashboarddata: [],
      certification_documents: [],
      customer_id: "",
      checkrfpappliedornot: 0,
      checklikedislike: 0,
      naic_code: [],
    };
  }

  componentDidMount() {
    if (this.props.auth.user.type == "company") {
      this.props.dashboard("dashboard");
    } else {
      this.props.handlevendordashboard("vendordashboard");
    }
    window.scrollTo(0, 0);
    //Get Rfp Rfi profile data
    fetch(
      AppConstants.API +
        "/rfprfiprofiles/viewrfprfiprofile/" +
        this.props.match.params.id
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.Status == "Success") {
          //posted date
          var posteddate = data.rfprfiprofilesdata.rfp_post_date;
          var posteddate = new Date(posteddate);
          var postdate =
            ("0" + (posteddate.getMonth() + 1)).slice(-2) +
            "-" +
            (posteddate.getDate() < 10 ? "0" : "") +
            posteddate.getDate() +
            "-" +
            posteddate.getFullYear();

          //Due date
          var duedate = data.rfprfiprofilesdata.due_dateandtime;
          var duedate = new Date(duedate);
          var hours = duedate.getHours();
          var minutes = duedate.getMinutes();
          var ampm = hours >= 12 ? "PM" : "AM";
          hours = hours % 12;
          hours = hours ? hours : 12; // the hour '0' should be '12'
          minutes = minutes < 10 ? "0" + minutes : minutes;
          var strTime = hours + ":" + minutes + " " + ampm;
          var duedt =
            ("0" + (duedate.getMonth() + 1)).slice(-2) +
            "-" +
            (duedate.getDate() < 10 ? "0" : "") +
            duedate.getDate() +
            "-" +
            duedate.getFullYear() +
            " " +
            strTime;

          //Questions date
          var deadlinedate = data.rfprfiprofilesdata.deadline_for_questions;
          var deadlinedate = new Date(deadlinedate);
          var deaddate =
            ("0" + (deadlinedate.getMonth() + 1)).slice(-2) +
            "-" +
            (deadlinedate.getDate() < 10 ? "0" : "") +
            deadlinedate.getDate() +
            "-" +
            deadlinedate.getFullYear();

          this.setState({
            customer_id: data.rfprfiprofilesdata.customer_id,
            name_ofrfp: data.rfprfiprofilesdata.name_ofrfp,
            contact_first_name: data.rfprfiprofilesdata.contact_first_name,
            contact_last_name: data.rfprfiprofilesdata.contact_last_name,
            contact_phonenumber: data.rfprfiprofilesdata.contact_phonenumber,
            contact_email: data.rfprfiprofilesdata.contact_email,
            rfp_post_date: postdate,
            due_dateandtime: duedt,
            description: data.rfprfiprofilesdata.description,
            department: data.rfprfiprofilesdata.department,
            bid_link: data.rfprfiprofilesdata.bid_link,
            deadline_for_questions: deaddate,
            minority_business: data.rfprfiprofilesdata.minority_business,
            women_owned_business: data.rfprfiprofilesdata.women_owned_business,
            need_certifications: data.rfprfiprofilesdata.need_certifications,
            bid_rfp_reference_number:
              data.rfprfiprofilesdata.bid_rfp_reference_number,
            status: data.rfprfiprofilesdata.status,
            documents: data.rfprfiprofilesdata.documents,
            prev_document: data.rfprfiprofilesdata.documents,
            view_document: data.rfprfiprofilesdata.documents,
            naic_code: data.rfprfiprofilesdata.naiccodesdes,
            certifications: data.rfprfiprofilesdata.certifications,
            certification_documents:
              data.rfprfiprofilesdata.certification_documents,
          });
        } else {
          this.setState({ getfprfiprofiledata: "" });
        }
      });
    fetch(
      AppConstants.API +
        "/vendors/companyrfprfinaicrelatedvendors/" +
        this.props.match.params.id
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.Status == "Success") {
          this.setState({ vendordashboarddata: data.vendordashboarddata });
        } else {
          this.setState({ vendordashboarddata: [] });
        }
      });
    //CHeck RFP Applied
    fetch(
      AppConstants.API +
        "/rfprfilikes/checkappliednotapplied/" +
        this.props.auth.user._id +
        "/" +
        this.props.match.params.id
    )
      .then((response) => response.json())
      .then((data) => {
        //console.log(data);
        if (data.Status == "Success") {
          this.setState({ checkrfpappliedornot: data.checkrfpappliedornot });
        } else {
          this.setState({ checkrfpappliedornot: 0 });
        }
      });
    //CHeck RFP like
    fetch(
      AppConstants.API +
        "/rfprfilikes/checklikedisplike/" +
        this.props.auth.user._id +
        "/" +
        this.props.match.params.id
    )
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        if (data.Status == "Success") {
          this.setState({ checklikedislike: data.checklikedislike });
        } else {
          this.setState({ checklikedislike: 0 });
        }
      });
  }
  renderShowsTotal(start, to, total) {
    return (
      <p className="page-show">
        Showing rows {start} to {to} of {total}
      </p>
    );
  }
  actionBtn(cell, row) {
    return (
      <div className="action-block">
        <Link
          to={`${AppConstants.STAGRFP}${"/viewvendordetails/"}${row._id}`}
          className="view-icon"
          title="View Vendor Details"
        >
          <i class="fa fa-eye" aria-hidden="true"></i>
        </Link>
      </div>
    );
  }
  logoInfo(cell, row) {
    return (
      <div>
        {row.vendor_company_name}
        {/* <div className="vendor-logo"><img  src={row.companyLogo || nologo} alt={row.companyLogo || nologo} className="img-responsive"/></div> */}
      </div>
    );
  }
  companyInfo(cell, row) {
    return (
      <div className="contact-info">
        {/* <b>Name: </b>{row.vendor_company_name} <br/>  */}
        <span className="location">
          {row.vendor_company_address} <br />
          {row.state},{row.city} {row.zipcode}{" "}
        </span>
        {row.vendor_company_phoneno && row.vendor_company_phoneno != "" ? (
          <span className="phone-no"> {row.vendor_company_phoneno}</span>
        ) : (
          ""
        )}
        {row.vendor_company_website && row.vendor_company_website != "" ? (
          <span className="website">
            <a href={row.vendor_company_website} target="_blank">
              {row.vendor_company_website}
            </a>
          </span>
        ) : (
          ""
        )}
      </div>
    );
  }
  // website(cell,row) {
  //     return  <div><a href={row.vendor_company_website} target="_blank" style={{"color":"#212529"}}>{row.vendor_company_website}</a></div>;
  // }
  naicInfo(cell, row) {
    return (
      <div>
        {row.industry}
        <br />
        <b>NAICS Codes:</b>
        <br />
        <span className="naiccodes">
          <ul style={{ listStyle: "none", padding: "0" }}>
            {row.naiccodesdes.map((recipe) => {
              return (
                <li>
                  {recipe}
                  <span>,</span>
                </li>
              );
            })}
          </ul>
          {row.naiccodesdes == "" && <span>No Naics Codes</span>}
        </span>
      </div>
    );
  }
  applyRfp = (rfp_id, company_id) => (e) => {
    e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "You want to Apply for this Opportunity?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        swal("Opportunity has been Applied!", {
          icon: "success",
        });
        const appliedrfp = {
          vendor_id: this.props.auth.user._id,
          rfprfi_id: this.props.match.params.id,
          company_id: this.state.customer_id,
        };
        //console.log(appliedrfp);
        axios
          .post(AppConstants.API + "/rfprfiinterestes/interest", appliedrfp)
          .then((res) => {
            if (res.data.Status == "Success") {
              //CHeck RFP
              fetch(
                AppConstants.API +
                  "/rfprfilikes/checkappliednotapplied/" +
                  this.props.auth.user._id +
                  "/" +
                  this.props.match.params.id
              )
                .then((response) => response.json())
                .then((data) => {
                  //console.log(data);
                  if (data.Status == "Success") {
                    this.setState({
                      checkrfpappliedornot: data.checkrfpappliedornot,
                    });
                  } else {
                    this.setState({ checkrfpappliedornot: 0 });
                  }
                });
            } else {
              this.setState({
                errMsg: res.data.msg,
              });
            }
          });
      }
    });
  };
  likeRfp = (rfp_id, company_id) => (e) => {
    NotificationManager.success("Opportunity Liked", "", 500);
    e.preventDefault();
    const vendorlikerfp = {
      vendor_id: this.props.auth.user._id,
      rfprfi_id: this.props.match.params.id,
      company_id: this.state.customer_id,
    };
    axios
      .post(AppConstants.API + "/rfprfilikes/like", vendorlikerfp)
      .then((res) => {
        if (res.data.Status == "Success") {
          //CHeck RFP like
          fetch(
            AppConstants.API +
              "/rfprfilikes/checklikedisplike/" +
              this.props.auth.user._id +
              "/" +
              this.props.match.params.id
          )
            .then((response) => response.json())
            .then((data) => {
              // console.log(data);
              if (data.Status == "Success") {
                this.setState({ checklikedislike: data.checklikedislike });
              } else {
                this.setState({ checklikedislike: 0 });
              }
            });
        } else {
          this.setState({
            errMsg: res.data.msg,
          });
        }
      });
  };
  unlikelikeRfp = (rfp_id, company_id) => (e) => {
    NotificationManager.success("Opportunity Dis Liked", "", 500);
    e.preventDefault();
    const vendorlikerfp = {
      vendor_id: this.props.auth.user._id,
      rfprfi_id: this.props.match.params.id,
      company_id: this.state.customer_id,
    };
    axios
      .post(AppConstants.API + "/rfprfilikes/unlike", vendorlikerfp)
      .then((res) => {
        if (res.data.Status == "Success") {
          //CHeck RFP like
          fetch(
            AppConstants.API +
              "/rfprfilikes/checklikedisplike/" +
              this.props.auth.user._id +
              "/" +
              this.props.match.params.id
          )
            .then((response) => response.json())
            .then((data) => {
              console.log(data);
              if (data.Status == "Success") {
                this.setState({ checklikedislike: data.checklikedislike });
              } else {
                this.setState({ checklikedislike: 0 });
              }
            });
        } else {
          this.setState({
            errMsg: res.data.msg,
          });
        }
      });
  };

  render() {
    let minority = "";
    let business = "";
    /* console.log(this.state.certifications);
      var test = this.state.certifications;
      var myVar2 = test.join(', '); */
    if (this.state.minority_business == true) {
      minority = "Yes";
    } else {
      minority = "No";
    }
    if (this.state.women_owned_business == true) {
      business = "Yes";
    } else {
      business = "No";
    }
    const options = {
      page: 1, // which page you want to show as default
      sizePerPageList: [
        {
          text: "10",
          value: 10,
        },
        {
          text: "25",
          value: 25,
        },
        {
          text: "All",
          value: this.state.vendordashboarddata.length,
        },
      ], // you can change the dropdown list for size per page
      sizePerPage: 10, // which size per page you want to locate as default
      pageStartIndex: 1, // where to start counting the pages
      paginationSize: 5, // the pagination bar size.
      prePage: "<", // Previous page button text
      nextPage: ">", // Next page button text
      firstPage: "First", // First page button text
      lastPage: "Last", // Last page button text
      paginationShowsTotal: this.renderShowsTotal, // Accept bool or function
      paginationPosition: "bottom", // default is bottom, top and both is all available
    };

    if (localStorage.jwtToken != undefined) {
      var k = 1;
      return (
        <div>
          <ReactTitle title="Opportunity Details" />
          <div className="inner-banner"></div>
          <div className="inner-content rfpviewdetail">
            <Container>
              <div className="white-bg pad50 information-block">
                <Row className="justify-content-center">
                  <Col md="12">
                    {/* <h1 className="color-yellow">RFP Details</h1> */}
                    <div>
                      {/* <h3>RFP Basic Information</h3>
                                            <Link to="/allrfprfis" className="btn btn-sm fright" title="Back">Back</Link> */}
                      <Row className="justify-content-center opportunity-sec row">
                        <Col md="8">
                          <h3>Opportunity Basic Information</h3>
                        </Col>
                        <Col md="4" className="info-grid">
                          {/* {this.props.auth.user.type =='company' ? <button onClick={BrowserHistory.goBack} className="btn btn-sm fright back-btn" >Back</button> :''} */}

                          {/* <button onClick={BrowserHistory.goBack} className="btn btn-sm fright back-btn" >Back</button> */}

                          {/* <Link to={`${AppConstants.STAGRFP}${"/dashboard"}`} className="btn btn-sm back-btn fright" title="Back" id="rfp_back">Back</Link> */}

                          {this.props.auth.user.type == "company" ? (
                            <button
                              onClick={BrowserHistory.goBack}
                              className="btn btn-sm fright back-btn"
                            >
                              Back
                            </button>
                          ) : (
                            ""
                          )}

                          {this.props.auth.user.type == "vendor" ? (
                            <div className="d-flex mt-2">
                              {this.state.checkrfpappliedornot == 0 ? (
                                <a
                                  href="javascript:void(0);"
                                  className="applied not-applied rfpliked"
                                  title="Apply Opportunity"
                                  id="edit_rfp"
                                  onClick={this.applyRfp(
                                    this.props.match.params.id,
                                    this.state.customer_id
                                  )}
                                >
                                  <em></em>
                                  <span>Apply</span>
                                </a>
                              ) : (
                                <a
                                  href="javascript:void(0);"
                                  className="applied rfpliked"
                                  title="Applied Opportunity"
                                  id="edit_rfp"
                                >
                                  <em></em>
                                  <span>Applied</span>
                                </a>
                              )}

                              {this.state.checklikedislike == 0 ? (
                                <a
                                  href="javascript:void(0);"
                                  className="applied unliked rfpliked"
                                  title="Like Opportunity"
                                  id="rfp_back"
                                  onClick={this.likeRfp(
                                    this.props.match.params.id,
                                    this.state.customer_id
                                  )}
                                >
                                  <em></em>
                                  <span>Like</span>
                                </a>
                              ) : (
                                <a
                                  href="javascript:void(0);"
                                  className="applied liked rfpliked bg-none"
                                  title="Liked Opportunity"
                                  id="rfp_back"
                                  onClick={this.unlikelikeRfp(
                                    this.props.match.params.id,
                                    this.state.customer_id
                                  )}
                                  style={{ background: "none !important" }}
                                >
                                  <em></em>
                                  <span>Liked</span>
                                </a>
                              )}
                            </div>
                          ) : (
                            ""
                          )}
                          <NotificationContainer />
                        </Col>
                      </Row>

                      <div className="vendot-info-detail pb-0">
                        <div className="">
                          <Row className="marg">
                            <div className="form-field col-md-4">
                              <span>Name Of Opportunity</span>
                            </div>
                            <div className="form-field col-md-8">
                              <h6>{this.state.name_ofrfp}</h6>
                            </div>
                          </Row>
                          <Row className="marg">
                            <div className="form-field col-md-4">
                              <span>Department</span>
                            </div>
                            <div className="form-field col-md-8">
                              <h6>{this.state.department}</h6>
                            </div>
                          </Row>
                          <Row className="marg">
                            <div className="form-field col-md-4">
                              <span>Biz Link</span>
                            </div>
                            <div className="form-field col-md-8">
                              <h6>
                                {this.state.bid_link ? (
                                  <a href={this.state.bid_link} target="_blank">
                                    {this.state.bid_link}
                                  </a>
                                ) : (
                                  <div>N/A</div>
                                )}
                              </h6>
                            </div>
                          </Row>
                          <Row className="marg">
                            <div className="form-field col-md-4">
                              <span>NAICS Codes</span>
                            </div>
                            {/* <div className="form-field col-md-8">
                                                                <h6>{this.state.naic_code}</h6>
                                                            </div> */}
                            <div className="form-field  col-md-8">
                              <ul
                                className="recipe-list"
                                style={{ listStyle: "none", padding: "0" }}
                              >
                                {this.state.naic_code.map((recipe) => {
                                  return <li>{recipe}</li>;
                                })}
                                {this.state.naic_code.length == 0 && (
                                  <li>No NAICS Codes</li>
                                )}
                              </ul>
                            </div>
                          </Row>
                          <Row className="marg">
                            <div className="form-field col-md-4">
                              <span>Posted Date</span>
                            </div>
                            <div className="form-field col-md-8">
                              <h6>{this.state.rfp_post_date}</h6>
                            </div>
                          </Row>
                          <Row className="marg">
                            <div className="form-field col-md-4">
                              <span>Due Date & Time</span>
                            </div>
                            <div className="form-field col-md-8">
                              <h6>{this.state.due_dateandtime || "N/A"}</h6>
                            </div>
                          </Row>
                          <Row className="marg">
                            <div className="form-field col-md-4">
                              <span>Deadline for questions</span>
                            </div>
                            <div className="form-field col-md-8">
                              <h6>
                                {this.state.deadline_for_questions || "N/A"}
                              </h6>
                            </div>
                          </Row>
                          <Row className="marg">
                            <div className="form-field col-md-4">
                              <span>Reference Number</span>
                            </div>
                            <div className="form-field col-md-8">
                              <h6>
                                {this.state.bid_rfp_reference_number || "N/A"}
                              </h6>
                            </div>
                          </Row>
                          <Row className="marg">
                            <div className="form-field col-md-4">
                              <span>Description</span>
                            </div>
                            <div className="form-field col-md-8">
                              <h6>{this.state.description}</h6>
                            </div>
                          </Row>

                          <Row>
                            <div className="form-field col-md-12">
                              <h6>
                                Would you like to give special consideration to
                                either group below?
                              </h6>
                            </div>
                          </Row>
                          <Row className="marg">
                            <div className="form-field col-md-4">
                              <span>Minority Business</span>
                            </div>
                            <div className="form-field col-md-8">
                              <h6>{minority}</h6>
                            </div>
                          </Row>
                          <Row className="marg">
                            <div className="form-field col-md-4">
                              <span>Women Owned Business</span>
                            </div>
                            <div className="form-field col-md-8">
                              <h6>{business}</h6>
                            </div>
                          </Row>
                          <Row className="marg">
                            <div className="form-field col-md-4">
                              <span>Supporting Document</span>
                            </div>
                            <div className="form-field col-md-8">
                              <div class="file-field input-field fileupload">
                                <div class="filebtn">
                                  {this.state.documents ? (
                                    <a
                                      href={this.state.documents}
                                      target="_blank"
                                    >
                                      <i
                                        class="fa fa-download"
                                        aria-hidden="true"
                                      ></i>{" "}
                                      Download Document{" "}
                                    </a>
                                  ) : (
                                    <h6>N/A</h6>
                                  )}
                                </div>
                              </div>
                            </div>
                          </Row>
                          <Row className="marg">
                            <div className="form-field col-md-4">
                              <span>
                                Would you like the applying company to be
                                certified?
                              </span>
                            </div>
                            <div className="form-field col-md-8">
                              <ul
                                className="recipe-list"
                                style={{ listStyle: "none", padding: "0" }}
                              >
                                {this.state.certifications != undefined &&
                                  this.state.certifications.map((recipe) => {
                                    if (recipe != null) {
                                      return <li>{recipe}</li>;
                                    } else {
                                      return <li>No Certifications</li>;
                                    }
                                  })}
                                {this.state.certifications.length == 0 && (
                                  <li>No Certifications</li>
                                )}
                              </ul>
                            </div>
                          </Row>
                        </div>
                        <div className="">
                          <h3 className="vi-title">Contact Information</h3>
                          <Row className="marg">
                            <div className="form-field col-md-4">
                              <span>First Name </span>
                            </div>
                            <div className="form-field col-md-8">
                              <h6>{this.state.contact_first_name || "N/A"}</h6>
                            </div>
                          </Row>
                          <Row className="marg">
                            <div className="form-field col-md-4">
                              <span>Last Name </span>
                            </div>
                            <div className="form-field col-md-8">
                              <h6>{this.state.contact_last_name || "N/A"}</h6>
                            </div>
                          </Row>
                          <Row className="marg">
                            <div className="form-field col-md-4">
                              <span>Email</span>
                            </div>
                            <div className="form-field col-md-8">
                              <h6>
                                {this.state.contact_email
                                  ? this.state.contact_email
                                  : "N/A"}
                              </h6>
                            </div>
                          </Row>
                          <Row className="marg">
                            <div className="form-field col-md-4">
                              <span>Phone Number</span>
                            </div>
                            <div className="form-field col-md-8">
                              <h6>{this.state.contact_phonenumber || "N/A"}</h6>
                            </div>
                          </Row>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              {this.props.auth.user.type == "company" && (
                <div className="dashboard-rfp-list view-info-sec">
                  <div className="dashboard-rfp-head">
                    <h6>Opportunity Related Vendors List</h6>
                  </div>
                  <BootstrapTable
                    data={this.state.vendordashboarddata}
                    pagination={true}
                    options={options}
                    search={true}
                    searchPlaceholder={
                      "Search by Name or Industry or NAICS Codes"
                    }
                  >
                    {/* <TableHeaderColumn dataField='_id' isKey={ true } dataSort={ true } hidden={true}>ID</TableHeaderColumn> */}
                    {/* <TableHeaderColumn dataField='vendor_company_name' dataFormat={ this.logoInfo.bind(this) } isKey={ true } dataSort={ true }>Name</TableHeaderColumn>
                                    <TableHeaderColumn dataField='vendor_primary_contact_firstname' dataSort={ true } dataFormat={ this.companyInfo.bind(this) }>Company Address </TableHeaderColumn> */}
                    {/* <TableHeaderColumn dataField='naiccodesdes' dataFormat={ this.naicInfo.bind(this) } dataSort={ true }>Industry</TableHeaderColumn>     */}
                    {/* <TableHeaderColumn dataField='industry' dataFormat={ this.naicInfo.bind(this) } dataSort={ true }>Industry Type</TableHeaderColumn>     */}
                    {/* <TableHeaderColumn dataField='vendor_company_website' dataFormat={ this.website.bind(this) } dataSort={ true }>Website</TableHeaderColumn>                         */}
                    {/* <TableHeaderColumn dataField='Action'  width={'10%'} dataFormat={ this.actionBtn.bind(this) }>Actions</TableHeaderColumn> */}
                    <TableHeaderColumn
                      dataField="vendor_company_name"
                      dataFormat={this.logoInfo.bind(this)}
                      isKey={true}
                      dataSort={true}
                      width={"20%"}
                    >
                      Name
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      dataField="vendor_company_address"
                      dataSort={true}
                      dataFormat={this.companyInfo.bind(this)}
                      width={"25%"}
                    >
                      Company Address{" "}
                    </TableHeaderColumn>

                    <TableHeaderColumn
                      dataField="state"
                      dataSort={true}
                      hidden={true}
                    >
                      state
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      dataField="city"
                      dataSort={true}
                      hidden={true}
                    >
                      city
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      dataField="zipcode"
                      dataSort={true}
                      hidden={true}
                    >
                      zipcode
                    </TableHeaderColumn>

                    <TableHeaderColumn
                      dataField="naiccodesdes"
                      dataSort={true}
                      hidden={true}
                    >
                      NAICS Codes
                    </TableHeaderColumn>

                    <TableHeaderColumn
                      dataField="industry"
                      dataFormat={this.naicInfo.bind(this)}
                      dataSort={true}
                    >
                      Industry Type / NAICS Codes
                    </TableHeaderColumn>

                    <TableHeaderColumn
                      dataField="Action"
                      width={"15%"}
                      dataFormat={this.actionBtn.bind(this)}
                    >
                      Actions
                    </TableHeaderColumn>
                  </BootstrapTable>
                </div>
              )}
            </Container>
          </div>
        </div>
      );
    } else {
      window.location.href = AppConstants.STAGRFP + "/";
    }
  }
}
rfpviewdetails.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  dashboard: PropTypes.func.isRequired,
  handlevendordashboard: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, {
  logoutUser,
  getProfile,
  dashboard,
  handlevendordashboard,
})(withRouter(rfpviewdetails));
