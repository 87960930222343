import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { MDBInput, MDBBtn } from "mdbreact";
import { Row, Col } from "reactstrap";
import axios from "axios";
import "react-sliding-pane/dist/react-sliding-pane.css";
import Login from "../pages/login";
import AppConstants from "../AppConstants";
import NumberFormat from "react-number-format";
import Customerotp from "../pages/customerotp";
import Vendorotp from "../pages/vendorotp";
import { connect } from "react-redux";
import { Typeahead } from "react-bootstrap-typeahead";
import AvatarUploader from "react-avatar-uploader";
import Loading from "../pages/loader.gif";
import CompanyIcon from "../images/company-icon.png";
import VenderIcon from "../images/vendor-icon.png";

let companyid = "";
let vendorid = "";
class Register extends React.Component {
  //export default class  Register extends Component  {
  constructor(props) {
    super(props);
    this.state = {
      isPaneOpen: false,
      isPaneOpenLeft: false,
      paneName: "login",
      company_name: "",
      company_logo: "",
      company_email: "",
      company_type: "",
      company_industrey: "",
      company_address: "",
      company_phoneno: "",
      naic_code: [],
      created_by: null,
      country: "",
      state: "",
      city: "",
      zipcode: "",
      status: "Active",
      companiesdata: [],
      industriesdata: [],
      statesdata: [],
      naiccodes: [],
      countries: [],
      states: [],
      USAstates: [
        { name: "Alabama", abbreviation: "AL" },
        { name: "Alaska", abbreviation: "AK" },
        { name: "American Samoa", abbreviation: "AS" },
        { name: "Arizona", abbreviation: "AZ" },
        { name: "Arkansas", abbreviation: "AR" },
        { name: "California", abbreviation: "CA" },
        { name: "Colorado", abbreviation: "CO" },
        { name: "Connecticut", abbreviation: "CT" },
        { name: "Delaware", abbreviation: "DE" },
        { name: "District Of Columbia", abbreviation: "DC" },
        { name: "Federated States Of Micronesia", abbreviation: "FM" },
        { name: "Florida", abbreviation: "FL" },
        { name: "Georgia", abbreviation: "GA" },
        { name: "Guam", abbreviation: "GU" },
        { name: "Hawaii", abbreviation: "HI" },
        { name: "Idaho", abbreviation: "ID" },
        { name: "Illinois", abbreviation: "IL" },
        { name: "Indiana", abbreviation: "IN" },
        { name: "Iowa", abbreviation: "IA" },
        { name: "Kansas", abbreviation: "KS" },
        { name: "Kentucky", abbreviation: "KY" },
        { name: "Louisiana", abbreviation: "LA" },
        { name: "Maine", abbreviation: "ME" },
        { name: "Marshall Islands", abbreviation: "MH" },
        { name: "Maryland", abbreviation: "MD" },
        { name: "Massachusetts", abbreviation: "MA" },
        { name: "Michigan", abbreviation: "MI" },
        { name: "Minnesota", abbreviation: "MN" },
        { name: "Mississippi", abbreviation: "MS" },
        { name: "Missouri", abbreviation: "MO" },
        { name: "Montana", abbreviation: "MT" },
        { name: "Nebraska", abbreviation: "NE" },
        { name: "Nevada", abbreviation: "NV" },
        { name: "New Hampshire", abbreviation: "NH" },
        { name: "New Jersey", abbreviation: "NJ" },
        { name: "New Mexico", abbreviation: "NM" },
        { name: "New York", abbreviation: "NY" },
        { name: "North Carolina", abbreviation: "NC" },
        { name: "North Dakota", abbreviation: "ND" },
        { name: "Northern Mariana Islands", abbreviation: "MP" },
        { name: "Ohio", abbreviation: "OH" },
        { name: "Oklahoma", abbreviation: "OK" },
        { name: "Oregon", abbreviation: "OR" },
        { name: "Palau", abbreviation: "PW" },
        { name: "Pennsylvania", abbreviation: "PA" },
        { name: "Puerto Rico", abbreviation: "PR" },
        { name: "Rhode Island", abbreviation: "RI" },
        { name: "South Carolina", abbreviation: "SC" },
        { name: "South Dakota", abbreviation: "SD" },
        { name: "Tennessee", abbreviation: "TN" },
        { name: "Texas", abbreviation: "TX" },
        { name: "Utah", abbreviation: "UT" },
        { name: "Vermont", abbreviation: "VT" },
        { name: "Virgin Islands", abbreviation: "VI" },
        { name: "Virginia", abbreviation: "VA" },
        { name: "Washington", abbreviation: "WA" },
        { name: "West Virginia", abbreviation: "WV" },
        { name: "Wisconsin", abbreviation: "WI" },
        { name: "Wyoming", abbreviation: "WY" },
      ],
      cities: [],
      errors: {},
      errMsg: "",
      successMsg: "",
      vendor_company_name: "",
      vendor_company_email: "",
      vendor_company_type: "",
      vendor_company_industrey: "",
      vendor_company_email: "",
      vendor_company_address: "",
      vendor_company_phoneno: "",
      vendor_zipcode: "",
      vendor_country: "",
      vendor_state: "",
      vendor_city: "",
      accolades: "",
      media: "",
      multiplevalues: [],
      multipleaccolades: [],
      companyid: "",
      vendorid: null,
      vendor_naic_code: [],
      certifications: [],
      membership: "",
      multiplemembership: [],
      loading: "",
      company_business_model: "",
      vendor_business_model: "",
      vendor_product_needs: "",
      vendor_other_industry_type: "",
      showTextField: false,
      hiring_needs: "",
      company_product_needs: "",
      showCompanyTextField: false,
      company_other_industry_type: "",
      urlWithoutId: window.location.href.split("/")[4],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.handlePhoneChange = this.handlePhoneChange.bind(this);
    // this.handleCountryChange = this.handleCountryChange.bind(this);
    // this.handleStateChange = this.handleStateChange.bind(this);
    //this.handleCountryChange1 = this.handleCountryChange1.bind(this);
    //this.handleStateChange1 = this.handleStateChange1.bind(this);
    //this.handleMultiChange = this.handleMultiChange.bind(this);
    this.handleInputFileChange = this.handleInputFileChange.bind(this);
    this.onChangeNumberkey = this.onChangeNumberkey.bind(this);
    this.loginClicked = this.loginClicked.bind(this);

    this.handleCompanyModelChange = this.handleCompanyModelChange.bind(this);
    this.handleVendorModelChange = this.handleVendorModelChange.bind(this);
    this.handleSelectInputChange = this.handleSelectInputChange.bind(this);
    this.handleCompanySelectInputChange = this.handleCompanySelectInputChange.bind(this);
  }
  handleCompanyModelChange(event) {
    //console.log(event.target.value);
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleVendorModelChange(event) {
    //console.log(event.target.value);
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handlePhoneChange(e) {
    const re1 = /^[0-9)\(+ '-]+$/g;
    // var phone_num = e.target.value.replace(/\D/g,'');
    if (e.target.value === "" || re1.test(e.target.value)) {
      // phone_num = this.phoneFormat(phone_num)
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  }
  phoneFormat(input) {
    // Strip all characters from the input except digits
    // Trim the remaining input to ten characters, to preserve phone number format
    input = input.substring(0, 10);
    // Based upon the length of the string, we add formatting as necessary
    var size = input.length;
    if (size == 0) {
      input = input;
    } else if (size < 4) {
      input = "(" + input;
    } else if (size < 7) {
      input = "(" + input.substring(0, 3) + ") " + input.substring(3, 6);
    } else {
      input = "(" + input.substring(0, 3) + ") " + input.substring(3, 6) + "-" + input.substring(6, 10);
    }
    return input;
  }

  loginClicked(p) {
    //console.log(p)
    this.props.callbackFromParent(this.state);
  }
  handleInputChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleSelectInputChange(e) {
    //console.log(e.target.value)
    if (e.target.value === "Other") {
      this.setState({
        [e.target.name]: e.target.value,
        showTextField: true,
      });
    } else {
      this.setState({
        [e.target.name]: e.target.value,
        showTextField: false,
        vendor_other_industry_type: "",
      });
    }
  }

  handleCompanySelectInputChange(e) {
    //console.log(e.target.value)
    if (e.target.value === "Other") {
      this.setState({
        [e.target.name]: e.target.value,
        showCompanyTextField: true,
      });
    } else {
      this.setState({
        [e.target.name]: e.target.value,
        showCompanyTextField: false,
        company_other_industry_type: "",
      });
    }
  }

  handleKeyUp(e) {
    // console.log(this.state.value);
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  onChangeNumberkey(e) {
    const zip = this.state;
    const re = /^[0-9)\(+ '-]+$/g;
    if (e.target.value === "" || re.test(e.target.value)) {
      zip[e.target.name] = e.target.value;
      this.setState({ zip });
    }
  }
  handleInputFileChange(e) {
    //console.log('hii');
    this.setState({
      [e.target.name]: e.target.files[0],
    });
  }

  /** Validations */
  handleValidation() {
    // console.log(this.state.naic_code.length);
    let errors = {};
    let formIsValid = true;
    if (this.actionInput.value == "company") {
      /* if(!this.state.company_logo){
            formIsValid = false;
            errors["company_logo"] = "Please upload logo";
          } */

      if (!this.state.company_name) {
        formIsValid = false;
        errors["company_name"] = "Please enter company name";
      }
      if (!this.state.company_email) {
        formIsValid = false;
        errors["company_email"] = "Please enter email";
      }
      if (typeof this.state.company_email !== "undefined") {
        let lastAtPos = this.state.company_email.lastIndexOf("@");
        let lastDotPos = this.state.company_email.lastIndexOf(".");

        if (
          !(
            lastAtPos < lastDotPos &&
            lastAtPos > 0 &&
            this.state.company_email.indexOf("@@") == -1 &&
            lastDotPos > 2 &&
            this.state.company_email.length - lastDotPos > 2
          )
        ) {
          formIsValid = false;
          errors["company_email"] = "Please enter valid email";
        }
      }

      if (!this.state.company_industrey) {
        formIsValid = false;
        errors["company_industrey"] = "Please select industry type";
      }
      if (!this.state.company_address) {
        formIsValid = false;
        errors["company_address"] = "Please enter address";
      }
      //   if(!this.state.company_phoneno){
      //     formIsValid = false;
      //     errors["company_phoneno"] = "Please enter phone number";
      //   }
      //   else if(this.state.company_phoneno.length !=14){
      //     formIsValid = false;
      //     errors["company_phoneno"] = "Please enter 10 digits  phone number";
      //   }
      if (!this.state.zipcode) {
        formIsValid = false;
        errors["zipcode"] = "Please enter zipcode";
      } else if (this.state.zipcode.length > 15) {
        formIsValid = false;
        errors["zipcode"] = "Please enter valid zipcode";
      }
      /* if(!this.state.country){
            formIsValid = false;
            errors["country"] = "Please select country";
          } */
      if (!this.state.state) {
        formIsValid = false;
        errors["state"] = "Please enter state";
      }
      if (!this.state.city) {
        formIsValid = false;
        errors["city"] = "Please enter city";
      }
      if (this.state.naic_code.length == 0) {
        formIsValid = false;
        errors["naic_code"] = "Please select naics codes";
      }

      if (!this.state.company_other_industry_type && this.state.company_industrey === "Other") {
        formIsValid = false;
        errors["company_other_industry_type"] = "Please enter other industry type";
      }

      //   else if(this.state.naic_code.length > 7){
      //     formIsValid = false;
      //     errors["naic_code"] = "Please select max 7 naics codes";
      //   }
      //   if (!this.state.company_business_model) {
      //     formIsValid = false;
      //     errors["company_business_model"] = "Please select business model";
      //   }
    } else {
      //console.log(this.state.vendor_naic_code.length);
      if (!this.state.vendor_company_name) {
        formIsValid = false;
        errors["vendor_company_name"] = "Please enter company name";
      }
      if (!this.state.vendor_company_email) {
        formIsValid = false;
        errors["vendor_company_email"] = "Please enter email";
      }
      if (typeof this.state.vendor_company_email !== "undefined") {
        let lastAtPos = this.state.vendor_company_email.lastIndexOf("@");
        let lastDotPos = this.state.vendor_company_email.lastIndexOf(".");

        if (
          !(
            lastAtPos < lastDotPos &&
            lastAtPos > 0 &&
            this.state.vendor_company_email.indexOf("@@") == -1 &&
            lastDotPos > 2 &&
            this.state.vendor_company_email.length - lastDotPos > 2
          )
        ) {
          formIsValid = false;
          errors["vendor_company_email"] = "Please enter valid email";
        }
      }
      // if(!this.state.vendor_company_type){
      //     formIsValid = false;
      //     errors["vendor_company_type"] = "Please select company type";
      // }

      if (!this.state.vendor_company_address) {
        formIsValid = false;
        errors["vendor_company_address"] = "Please enter address";
      }
      //   if(!this.state.vendor_company_phoneno){
      //       formIsValid = false;
      //       errors["vendor_company_phoneno"] = "Please enter phone number";
      //   }
      //   else if(this.state.vendor_company_phoneno.length !=14){
      //       formIsValid = false;
      //       errors["vendor_company_phoneno"] = "Please enter 10 digits phone number";
      //   }
      if (!this.state.vendor_zipcode) {
        formIsValid = false;
        errors["vendor_zipcode"] = "Please enter zipcode";
      } else if (this.state.vendor_zipcode.length > 15) {
        formIsValid = false;
        errors["vendor_zipcode"] = "Please enter valid zipcode";
      }
      /* if(!this.state.vendor_country){
            formIsValid = false;
            errors["vendor_country"] = "Please select country";
        } */
      if (!this.state.vendor_state) {
        formIsValid = false;
        errors["vendor_state"] = "Please enter state";
      }
      if (!this.state.vendor_city) {
        formIsValid = false;
        errors["vendor_city"] = "Please enter city";
      }

      if (!this.state.vendor_company_industrey) {
        formIsValid = false;
        errors["vendor_company_industrey"] = "Please select industry type";
      }

      if (!this.state.vendor_other_industry_type && this.state.vendor_company_industrey === "Other") {
        formIsValid = false;
        errors["vendor_other_industry_type"] = "Please enter other industry type";
      }

      /* if(this.state.vendor_naic_code.length ==0){
            formIsValid = false;
            errors["vendor_naic_code"] = "Please select naics codes";
        }
        else if(this.state.vendor_naic_code.length > 7){
            formIsValid = false;
            errors["vendor_naic_code"] = "Please select max 7 naics codes";
          }
        if(!this.state.vendor_business_model){
            formIsValid = false;
            errors["vendor_business_model"] = "Please select business model";
          } */
    }
    this.setState({ errors: errors });
    return formIsValid;
  }

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.actionInput.value == "company") {
      // console.log("company");
      if (this.handleValidation()) {
        // console.log("company ddd");
        this.setState({ loading: 1 });
        let formData = new FormData();
        formData.append("company_name", this.state.company_name);
        //formData.append('company_logo', this.state.company_logo);
        formData.append("company_email", this.state.company_email);
        formData.append("company_type", this.state.company_type);
        formData.append("company_industrey", this.state.company_industrey);
        formData.append("company_phoneno", this.state.company_phoneno);
        formData.append("company_address", this.state.company_address);
        formData.append("zipcode", this.state.zipcode);
        formData.append("country", this.state.country);
        formData.append("state", this.state.state);
        formData.append("city", this.state.city);
        formData.append("status", "Active");
        formData.append("deleted", 0);
        formData.append("naic_code", JSON.stringify(this.state.naic_code));
        formData.append("request_from", "web");

        formData.append("hiring_needs", this.state.hiring_needs);
        formData.append("company_product_needs", this.state.company_product_needs);
        formData.append("company_other_industry_type", this.state.company_other_industry_type);

        // formData.append(
        //   "company_business_model",
        //   this.state.company_business_model
        // );
        axios.post(AppConstants.API + "/cutomers/addcustomer", formData).then((res) => {
          //console.log(res);
          if (res.data.Status == "Success") {
            this.setState({ loading: "" });
            setTimeout((e) => {
              window.location.href = AppConstants.STAGRFP + "/customerotp/" + res.data.customer_id;
            }, 2000);
            this.setState({
              successMsg: res.data.msg,
            });
          } else {
            setTimeout(() => {
              this.setState({
                errMsg: false,
              });
            }, 2000);
            this.setState({ loading: "" });
            this.setState({
              errMsg: res.data.msg,
              company_name: "",
              company_email: "",
              company_type: "",
              company_industrey: "",
              company_phoneno: "",
              company_address: "",
              zipcode: "",
              country: "",
              state: "",
              city: "",
              status: "",
              naic_code: "",
              request_from: "",
              naic_code: [],
              company_business_model: "",
              hiring_needs: "",
              company_product_needs: "",
              company_other_industry_type: "",
            });
          }
        });

        // this.setState({
        //     'company_name':'',
        //     'company_email':'',
        //     'company_type':'',
        //     'company_industrey':'',
        //     'company_phoneno':'',
        //     'company_address':'',
        //     'zipcode':'',
        //     'country':'',
        //     'state':'',
        //     'city':'',
        //     'status':'',
        //     'naic_code':'',
        //     'request_from':''
        // })
      }
    } else {
      if (this.handleValidation()) {
        let formData = new FormData();
        formData.append("vendorid", this.state.vendorid);
        formData.append("vendor_company_name", this.state.vendor_company_name);
        //formData.append('company_logo', this.state.company_logo);
        formData.append("vendor_company_email", this.state.vendor_company_email);
        formData.append("vendor_company_type", this.state.vendor_company_type);
        formData.append("vendor_company_industrey", this.state.vendor_company_industrey);
        formData.append("vendor_company_phoneno", this.state.vendor_company_phoneno);
        formData.append("vendor_company_address", this.state.vendor_company_address);
        formData.append("zipcode", this.state.vendor_zipcode);
        formData.append("country", this.state.vendor_country);
        formData.append("state", this.state.vendor_state);
        formData.append("city", this.state.vendor_city);
        formData.append("status", "Active");
        formData.append("deleted", 0);
        //formData.append('naic_code',JSON.stringify(this.state.naic_code));
        formData.append("naic_code", JSON.stringify(this.state.vendor_naic_code));
        formData.append("accolades", this.state.accolades);
        formData.append("multipleaccolades", JSON.stringify(this.state.multipleaccolades));
        formData.append("media", this.state.media);
        formData.append("multipleValues", JSON.stringify(this.state.multiplevalues));
        formData.append("request_from", "web");
        formData.append("certifications", JSON.stringify(this.state.certifications));
        formData.append("membership", this.state.membership);
        formData.append("vendor_business_model", this.state.vendor_business_model);
        formData.append("multiplemembership", JSON.stringify(this.state.multiplemembership));

        formData.append("vendor_product_needs", this.state.vendor_product_needs);

        formData.append("vendor_other_industry_type", this.state.vendor_other_industry_type);

        axios.post(AppConstants.API + "/vendors/addvendor", formData).then((res) => {
          // console.log(res);
          if (res.data.Status == "Success") {
            this.setState({ loading: "" });
            setTimeout((e) => {
              window.location.href = AppConstants.STAGRFP + "/vendorotp/" + res.data.vendor_id;
            }, 2000);
            this.setState({
              successMsg: res.data.msg,
            });
          } else {
            setTimeout(() => {
              this.setState({
                errMsg: false,
              });
            }, 2000);
            this.setState({ loading: "" });
            this.setState({
              errMsg: res.data.msg,
              vendor_company_name: "",
              vendor_company_email: "",
              vendor_company_type: "",
              vendor_company_industrey: "",
              vendor_company_phoneno: "",
              vendor_company_address: "",
              vendor_zipcode: "",
              country: "",
              vendor_state: "",
              vendor_city: "",
              status: "",
              naic_code: "",
              accolades: "",
              multipleaccolades: "",
              certifications: "",
              vendor_naic_code: [],
              vendor_business_model: "",
              vendor_product_needs: "",
            });
          }
        });

        // this.setState({
        //     'vendor_company_name':'',
        //     'vendor_company_email':'',
        //     'vendor_company_type':'',
        //     'vendor_company_industrey':'',
        //     'vendor_company_phoneno':'',
        //     'vendor_company_address':'',
        //     'vendor_zipcode':'',
        //     'country':'',
        //     'vendor_state':'',
        //     'vendor_city':'',
        //     'status':'',
        //     'naic_code':'',
        //     'accolades':'',
        //     'multipleaccolades':'',
        //     'certifications':''
        // })
      }
    }
  };

  componentDidMount() {
    const { history, location } = this.props;
    const currentPath = location.pathname;
    // Split the path into segments
    const segments = currentPath.split("/");
    if (segments[2] === "2" || segments[2] === "0") {
      // Remove the last segment
      segments.pop();
      // Construct the new URL without the last segment
      const newUrl = segments.join("/");
      // Redirect to the new URL without the last segment
      history.push(newUrl);
    }

    //Get States data
    // fetch(AppConstants.API + '/states/getallstates/1')
    this.setState({ statesdata: this.state.USAstates.name });

    //Get Industries data
    fetch(AppConstants.API + "/industries/getallindustries")
      .then((response) => response.json())
      .then((data) => {
        if (data.Status == "Success") {
          this.setState({ industriesdata: data.industriesdata });
        } else {
          this.setState({ industriesdata: [] });
        }
      });
    //Get companies data
    fetch(AppConstants.API + "/companies/getallcompanies")
      .then((response) => response.json())
      .then((data) => {
        //console.log(" companies ", data);
        if (data.Status == "Success") {
          this.setState({ companiesdata: data.companiesdata });
        } else {
          this.setState({ companiesdata: [] });
        }
      });

    //Get naic codes
    fetch(AppConstants.API + "/users/getallnaiccodes")
      .then((response) => response.json())
      .then((data) => {
        //console.log(" naic codes ", data);
        if (data.Status == "Success") {
          this.setState({ naiccodes: data.naiccodes });
        } else {
          this.setState({ naiccodes: [] });
        }
      });
  }

  render() {
    // console.log(this.state.urlWithoutId)
    // console.log(this.state.USAstates);
    // console.log(this.state.industriesdata);

    return (
      <div className="login-block" id="login-grid">
        <div className="container">
          <div className="login-grid-in">
            <h3>Register</h3>
            <div className="form-block">
              <Tabs defaultIndex={this.state.urlWithoutId}>
                <TabList className="row">
                  <Tab className="col-md-6">
                    <em>
                      <span className="vender-icon">
                        <img src={VenderIcon} alt="" />
                      </span>
                      <span>Vendor</span>
                    </em>
                  </Tab>
                  <Tab className="col-md-6">
                    <em>
                      <span className="company-icon">
                        <img src={CompanyIcon} alt="" />
                      </span>
                      <span>Company</span>
                    </em>
                  </Tab>{" "}
                </TabList>
                <TabPanel>
                  <form name="vendorregistration" onSubmit={this.handleSubmit}>
                    <span style={{ color: "green" }}>{this.state.successMsg}</span>
                    <span style={{ color: "red" }}>{this.state.errMsg}</span>
                    {/*  <div className="avatar-block">
                                <AvatarUploader
                                size={150}
                                uploadURL="../images/"
                                fileType={"image/png"}/>
                                <span>Upload Logo</span>
                            </div> */}
                    <div className="form-block">
                      <input
                        type="hidden"
                        name="type"
                        value="vendor"
                        ref={(input) => {
                          this.actionInput = input;
                        }}
                      />
                      <Row>
                        <div className="form-field col-md-12">
                          <MDBInput
                            type="text"
                            name="vendor_company_name"
                            onChange={this.handleInputChange}
                            placeHolder="Company Name *"
                          />
                          <span className="error" style={{ color: "red" }}>
                            {this.state.errors["vendor_company_name"]}
                          </span>
                        </div>
                      </Row>
                      <Row>
                        <div className="form-field col-md-6">
                          <MDBInput
                            type="text"
                            name="vendor_company_email"
                            onChange={this.handleInputChange}
                            placeHolder="Email *"
                          />
                          <span className="error" style={{ color: "red" }}>
                            {this.state.errors["vendor_company_email"]}
                          </span>
                        </div>
                        <div className="form-field col-md-6">
                          <MDBInput
                            type="text"
                            name="vendor_company_phoneno"
                            value={this.state.vendor_company_phoneno}
                            onChange={this.handlePhoneChange}
                            maxlength="15"
                            placeHolder="Phone Number"
                          />
                          {/* <span className="error" style={{color: "red"}}>
                                {this.state.errors["vendor_company_phoneno"]}
                            </span> */}
                        </div>
                      </Row>
                      <Row>
                        <div className="form-field col-md-6">
                          <MDBInput
                            type="text"
                            name="vendor_company_address"
                            onChange={this.handleInputChange}
                            placeHolder="Address *"
                          />
                          <span className="error" style={{ color: "red" }}>
                            {this.state.errors["vendor_company_address"]}
                          </span>
                        </div>

                        <div className="form-field col-md-6">
                          <MDBInput
                            type="text"
                            name="vendor_city"
                            onChange={this.handleInputChange}
                            placeHolder="City *"
                          />
                          <span className="error" style={{ color: "red" }}>
                            {this.state.errors["vendor_city"]}
                          </span>
                        </div>
                      </Row>
                      <Row>
                        {/* <div className="form-field col-md-6">
                          <MDBInput
                            type="text"
                            name="vendor_state"
                            onChange={this.handleInputChange}
                            placeHolder="State *"
                          />
                          <span className="error" style={{ color: 'red' }}>
                            {this.state.errors['vendor_state']}
                          </span>
                        </div> */}
                        {/*Vendor State select input */}
                        <div className="form-field col-md-6">
                          <div className="md-form">
                            <select
                              name="vendor_state"
                              class="cs-select cs-skin-elastic form-control"
                              onChange={this.handleSelectInputChange}
                            >
                              <option value="">State *</option>
                              {this.state.USAstates.map((states, index) => {
                                {
                                  return (
                                    <option key={index} value={states.name}>
                                      {states.name}
                                    </option>
                                  );
                                }
                              })}
                              {/* <option value="Other">Other</option> */}
                            </select>
                            {this.state.vendor_state ? <label className="active" /> : <label />}
                          </div>
                          <em className="input-arrow">
                            <i class="fa fa-chevron-down" />
                          </em>
                          <span className="error" style={{ color: "red" }}>
                            {this.state.errors["vendor_state"]}
                          </span>
                        </div>
                        <div className="form-field col-md-6">
                          <MDBInput
                            type="text"
                            name="vendor_zipcode"
                            onChange={this.onChangeNumberkey}
                            value={this.state.vendor_zipcode}
                            placeHolder="Zip Code *"
                          />
                          <span className="error" style={{ color: "red" }}>
                            {this.state.errors["vendor_zipcode"]}
                          </span>
                        </div>
                      </Row>

                      <Row>
                        <div className="form-field col-md-6">
                          <div className="md-form">
                            <select
                              name="vendor_company_industrey"
                              class="cs-select cs-skin-elastic form-control"
                              onChange={this.handleSelectInputChange}
                            >
                              <option value="">Industry Type *</option>
                              {this.state.industriesdata.map((industries, index) => {
                                if (industries.status == "Active") {
                                  return (
                                    <option key={index} value={industries._id}>
                                      {industries.industryname}
                                    </option>
                                  );
                                }
                              })}
                              <option value="Other">Other</option>
                            </select>
                            {this.state.vendor_company_industrey ? <label className="active" /> : <label />}
                          </div>
                          <em className="input-arrow">
                            <i class="fa fa-chevron-down" />
                          </em>
                          <span className="error" style={{ color: "red" }}>
                            {this.state.errors["vendor_company_industrey"]}
                          </span>
                        </div>

                        <div className="form-field col-md-6">
                          <MDBInput
                            type="text"
                            name="vendor_product_needs"
                            onChange={this.handleInputChange}
                            value={this.state.vendor_product_needs}
                            placeHolder="Product Needs"
                          />
                        </div>
                      </Row>

                      {this.state.showTextField === true ? (
                        <Row>
                          <div className="form-field col-md-6">
                            <MDBInput
                              type="text"
                              name="vendor_other_industry_type"
                              onChange={this.handleInputChange}
                              value={this.state.vendor_other_industry_type}
                              placeHolder="Other Industry Type *"
                            />
                            <span className="error" style={{ color: "red" }}>
                              {this.state.errors["vendor_other_industry_type"]}
                            </span>
                          </div>
                        </Row>
                      ) : (
                        ""
                      )}

                      <Row>
                        <div className="form-field col-md-12">
                          <MDBBtn type="submit" title="Vendor Registration">
                            Register
                            <div
                              style={this.state.loading ? {} : { display: "none" }}
                              class="image-fill w-25 loader-login-div "
                            >
                              <img src={Loading} alt="No Image" className="can-click " />
                            </div>
                          </MDBBtn>
                        </div>
                      </Row>
                    </div>
                  </form>
                </TabPanel>
                <TabPanel>
                  <form name="companyregistration" onSubmit={this.handleSubmit}>
                    <span style={{ color: "green" }}>{this.state.successMsg}</span>
                    <span style={{ color: "red" }}>{this.state.errMsg}</span>
                    {/* <div className="avatar-block">
                            <AvatarUploader
                            size={150}
                            fileType={"image/png"}
                            name="avatar"/>
                            <span>Upload Logo</span>
                            <span className="error" style={{color: 'red'}}>{this.state.errors["company_logo"]}</span>
                        </div> */}
                    <div className="form-block">
                      <input
                        type="hidden"
                        name="type"
                        value="company"
                        ref={(input) => {
                          this.actionInput = input;
                        }}
                      />
                      <Row>
                        <div className="form-field col-md-12">
                          <MDBInput
                            type="text"
                            name="company_name"
                            onChange={this.handleInputChange}
                            placeHolder="Company Name *"
                          />
                          <span className="error" style={{ color: "red" }}>
                            {this.state.errors["company_name"]}
                          </span>
                        </div>
                      </Row>

                      <Row>
                        <div className="form-field col-md-6">
                          <MDBInput
                            type="text"
                            name="company_email"
                            onChange={this.handleInputChange}
                            placeHolder="Email *"
                          />
                          <span className="error" style={{ color: "red" }}>
                            {this.state.errors["company_email"]}
                          </span>
                        </div>
                        <div className="form-field col-md-6">
                          <div className="md-form">
                            <MDBInput
                              type="text"
                              name="company_phoneno"
                              value={this.state.company_phoneno}
                              onChange={this.handlePhoneChange}
                              maxlength="15"
                              placeHolder="Phone Number"
                            />
                          </div>
                          {/* <span className="error" style={{color: 'red'}}>{this.state.errors["company_phoneno"]}</span>  */}
                        </div>
                      </Row>
                      <Row>
                        <div className="form-field col-md-6">
                          <MDBInput
                            type="text"
                            name="company_address"
                            onChange={this.handleInputChange}
                            placeHolder="Address *"
                          />
                          <span className="error" style={{ color: "red" }}>
                            {this.state.errors["company_address"]}
                          </span>
                        </div>
                        <div className="form-field col-md-6">
                          <MDBInput
                            type="text"
                            name="city"
                            value={this.state.city}
                            onChange={this.handleInputChange}
                            placeHolder="City *"
                          />
                          <span className="error" style={{ color: "red" }}>
                            {this.state.errors["city"]}
                          </span>
                        </div>
                      </Row>
                      <Row>
                        {/* <div className="form-field col-md-6">
                          <MDBInput
                            type="text"
                            name="state"
                            value={this.state.state}
                            onChange={this.handleInputChange}
                            placeHolder="State *"
                          />
                          <span className="error" style={{ color: 'red' }}>
                            {this.state.errors['state']}
                          </span>
                        </div> */}
                        {/*Company State select input */}
                        <div className="form-field col-md-6">
                          <div className="md-form">
                            <select
                              name="state"
                              class="cs-select cs-skin-elastic form-control"
                              onChange={this.handleCompanySelectInputChange}
                            >
                              <option value="">State *</option>
                              {this.state.USAstates.map((states, index) => {
                                {
                                  return (
                                    <option key={index} value={states.name}>
                                      {states.name}
                                    </option>
                                  );
                                }
                              })}
                              {/* <option value="Other">Other</option> */}
                            </select>
                            {this.state.state ? <label className="active" /> : <label />}
                          </div>
                          <em className="input-arrow">
                            <i class="fa fa-chevron-down" />
                          </em>
                          <span className="error" style={{ color: "red" }}>
                            {this.state.errors["state"]}
                          </span>
                        </div>
                        <div className="form-field col-md-6">
                          <MDBInput
                            type="text"
                            name="zipcode"
                            value={this.state.zipcode}
                            onChange={this.onChangeNumberkey}
                            placeHolder="Zip Code *"
                          />
                          <span className="error" style={{ color: "red" }}>
                            {this.state.errors["zipcode"]}
                          </span>
                        </div>
                      </Row>

                      <Row>
                        <div className="form-field col-md-6">
                          <MDBInput
                            type="text"
                            name="hiring_needs"
                            value={this.state.hiring_needs}
                            onChange={this.handleInputChange}
                            placeHolder="Hiring Needs"
                          />
                        </div>
                        <div className="form-field col-md-6">
                          <MDBInput
                            type="text"
                            name="company_product_needs"
                            value={this.state.company_product_needs}
                            onChange={this.handleInputChange}
                            placeHolder="Product Needs"
                          />
                        </div>
                      </Row>

                      <Row>
                        <div className="form-field col-md-12 form-tags">
                          {/* <MDBInput label="Naics Codes" />NAICS */}
                          <div className="md-form">
                            <p>NAICS Codes *</p>
                            <Typeahead
                              clearButton
                              labelKey={(option) => `${option.NAICS_Description}` + `(${option.NAICS})`}
                              multiple
                              options={this.state.naiccodes}
                              name="naic_code"
                              value={this.state.naiccodes}
                              onChange={(selected) => {
                                this.setState({ naic_code: selected });
                              }}
                            />
                            {/* {this.state.vendor_naic_code.length > 0 ? <label className="active">NAICS Codes *</label> : <label>NAICS Codes *</label>} */}
                          </div>
                          <span className="error" style={{ color: "red" }}>
                            {this.state.errors["naic_code"]}
                          </span>
                        </div>
                      </Row>

                      <Row>
                        <div className="form-field col-md-6">
                          <div className="md-form">
                            <select
                              name="company_industrey"
                              class="cs-select cs-skin-elastic form-control"
                              onChange={this.handleCompanySelectInputChange}
                            >
                              <option value="">Industry Type *</option>
                              {this.state.industriesdata.map((industries, index) => {
                                if (industries.status == "Active") {
                                  return (
                                    <option key={index} value={industries._id}>
                                      {industries.industryname}
                                    </option>
                                  );
                                }
                              })}
                              <option value="Other">Other</option>
                            </select>
                            {this.state.company_industrey ? <label className="active" /> : <label />}
                          </div>
                          <em className="input-arrow" id="input-icon">
                            <i class="fa fa-chevron-down" />
                          </em>
                          <span className="error" style={{ color: "red" }}>
                            {this.state.errors["company_industrey"]}
                          </span>
                        </div>

                        {this.state.showCompanyTextField === true ? (
                          <div className="form-field col-md-6">
                            <MDBInput
                              type="text"
                              name="company_other_industry_type"
                              onChange={this.handleInputChange}
                              value={this.state.company_other_industry_type}
                              placeHolder="Other Industry Type *"
                            />
                            <span className="error" style={{ color: "red" }}>
                              {this.state.errors["company_other_industry_type"]}
                            </span>
                          </div>
                        ) : (
                          ""
                        )}
                      </Row>

                      <Row>
                        <div className="form-field col-md-12 mt-4">
                          <MDBBtn type="submit" title="Company Register">
                            Register
                            <div
                              style={this.state.loading ? {} : { display: "none" }}
                              class="image-fill w-25 loader-login-div "
                            >
                              <img src={Loading} alt="No Image" className="can-click " />
                            </div>
                          </MDBBtn>
                        </div>
                      </Row>
                    </div>
                  </form>
                </TabPanel>
              </Tabs>
              <Row>
                {/* <div className="forgot-block text-center col-md-12">Already have an account <a onClick={() => this.setState({ isPaneOpen: true,paneName:'login' })} className="und">Login</a></div> */}

                <div className="forgot-block text-center col-md-12">
                  Already have an account? <a href="/login">Login</a>
                </div>
              </Row>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
Register.propTypes = {
  //companyid: PropTypes.object.isRequired
};
const mapStateToProps = (state) => ({
  //companyid:state.companyid
});
export default connect(
  mapStateToProps,
  {}
)(Register);
