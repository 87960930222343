import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';

export default class  Footer extends Component  {
  render() {
    return (
    <footer>
      <Container>
        <Row>
          <Col md="12">
           <p> &copy; {(new Date()).getFullYear()} - BuyingDiverse. All rights reserved</p>
          </Col>
          {/* <Col md="6">
            
            <span>Created by <a href="https://www.thinkebiz.net/" target="_blank" style={{color:"white"}}>eBiz Solutions</a></span>
          </Col> */}
        </Row>
      </Container>
    </footer>
    );
  }
}
