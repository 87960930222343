import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import axios from 'axios';
import { MDBInput } from 'mdbreact';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {getVendorProfile,dashboard} from '../actions/authentication';
import AppConstants from '../AppConstants';
import "react-datepicker/dist/react-datepicker.css";
import Loading from '../pages/loader.gif';
import {ReactTitle} from 'react-meta-tags';
import { withRouter } from 'react-router-dom';
import CompanyIcon from "../images/company-icon.png"
import VenderIcon from "../images/vendor-icon.png"

class Changevendorpassword extends React.Component { 
    constructor(props,context){
        super(props,context);
        this.state = {
          "vendor_id" : this.props.auth.user._id,
            errors: {},
            errMsg:'',
            successerrMsg:'',
            "loading": '',
            "oldpassword":"",
            "newpassword":"",
            "renewpassword":""
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
       }
      
       handleInputChange(e) {  
        //console.log(this.state.revanuerange);
        this.setState({
            [e.target.name]: e.target.value
        });
      }

      
      /** Validations */
      handleValidation(){
        let errors = {};
        let formIsValid = true;   
        if(!this.state.oldpassword){
          formIsValid = false;
          errors["oldpassword"] = "Please enter current password";
        }
        if(!this.state.newpassword){
          formIsValid = false;
          errors["newpassword"] = "Please enter new password";
        }
        if(!this.state.renewpassword){
          formIsValid = false;
          errors["renewpassword"] = "Please re enter new password";
        }else  if(this.state.newpassword != this.state.renewpassword){
          formIsValid = false;
          errors["renewpassword"] = "New password does not match";
        }

        this.setState({errors: errors});
        return formIsValid;
      }

    
    handleSubmit = event => {
        event.preventDefault();
        if(this.handleValidation()){
            this.setState({loading:1});      
            const changepwd = {
              id:this.props.auth.user._id,
              oldpassword: this.state.oldpassword,
              newpassword: this.state.newpassword
            }
            axios.post(AppConstants.API+'/vendors/changevendorpwd',changepwd)
            .then(res => {   
              //console.log(res);   
            if(res.data.Status == "Success"){
              this.setState({loading:''});
              setTimeout((e) => {           
                this.props.history.push(AppConstants.STAGRFP+'/dashboard');
              }, 2000); 
              this.setState({
                successerrMsg: res.data.msg
              });
            }
            else{              
              this.setState({loading:''});
              setTimeout((e) => {
                this.setState({
                    errMsg: false,
                });
            }, 2000);
              this.setState({
                errMsg: res.data.msg
              });
            }
          });
        } 
    }
    componentDidMount() {
      this.props.dashboard("");
     // console.log(this.props.auth.user._id);
      window.scrollTo(0, 0);
    } 
    
    
  render() {

    if(localStorage.jwtToken != undefined){
    return (
      <div className="login-block" id='login-grid'>
      <div className='container'>           
       <div className="login-grid-in change-pwd">
       <h3>Change Password</h3>              
        <div>
            <ReactTitle title="Change Password" />
            {/* <div className="inner-banner">
            </div> */}
                        {/* <Col md="10">
                        <Row className="justify-content-center">
                        <Col md="8" className="text-center"><h1 className="color-yellow">Change Password</h1></Col>
                    </Row>
                  </Col> */}
                       
                  <form name="vendorprofile" onSubmit= { this.handleSubmit }>
                        <div>
                                <div className="form-block">
                                    <Row>
                                        <div className="form-field col-md-12">
                                            <MDBInput name="oldpassword" type="password" onChange={ this.handleInputChange } placeHolder="Current Password *" />
                                            <span className="error" style={{color: 'red'}}>{this.state.errors["oldpassword"]}</span>
                                            </div>
                                    </Row>
                                    <Row>
                                        <div className="form-field col-md-12">
                                              <MDBInput name="newpassword" type="password" onChange={ this.handleInputChange } placeHolder="New Password *" />
                                            <span className="error" style={{color: 'red'}}>{this.state.errors["newpassword"]}</span>
                                            </div>
                                    </Row>
                                    <Row>
                                        <div className="form-field col-md-12">
                                              <MDBInput name="renewpassword" type="password" onChange={ this.handleInputChange } placeHolder="Re-enter New Password *" /> 
                                              <span className="error" style={{color: 'red'}}>{this.state.errors["renewpassword"]}</span>                        
                                          </div>
                                    </Row>

                                    <span className="fleft" style={{color: 'green'}}>{this.state.successerrMsg}</span>
                      <span className="fleft" style={{color: 'red'}}>{this.state.errMsg}</span>
                      <div className="form-field sub-btn">
                      <button class="btn btn-default" 
                      onClick={ this.handleSubmit }
                        title="Change Password">Change Password<div style={this.state.loading ? {} : { display: 'none' }} class="image-fill w-25 loader-login-div postrfp_load"><img src={Loading} alt="No Image" className="can-click " /></div></button>
</div>

                                  </div>
                        </div>
                      
                          </form>
        </div>
        </div>
        </div>
        </div>
        );
    }else
    {
        window.location.href = AppConstants.STAGRFP+'/';
    }
  }
}
Changevendorpassword.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    dashboard:PropTypes.func.isRequired
  };
  const mapStateToProps = (state) => ({
    auth: state.auth,
    errors: state.errors
  });
  
  //export default ChangepasswordPage;
  export  default connect(mapStateToProps, {getVendorProfile,dashboard})
  (withRouter(Changevendorpassword));
