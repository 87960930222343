import React, { Component } from "react";
import { MDBInput, MDBBtn } from "mdbreact";
import { Row, Col } from "reactstrap";
import { browserHistory } from "react-router";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Forgotpassword from "../pages/forgotpassword";
import { connect } from "react-redux";
import { loginCompany, loginVendor } from "../actions/authentication";
import PropTypes from "prop-types";
import Register from "../pages/register";
import Loading from "../pages/loader.gif";
import CompanyIcon from "../images/company-icon.png";
import VenderIcon from "../images/vendor-icon.png";
//import Cookies from 'universal-cookie';
//const cookies = new Cookies();

/* function LoadPane(props) {
    const isOpenPaneName = props.isOpenPaneName;
    //console.log(" first ", isOpenPaneName);
    if (isOpenPaneName == 'forgotpassword') {
      //console.log(isOpenPaneName);   
      return <Forgotpassword />;
     // isOpenPaneName = null;
    }
    else if(isOpenPaneName == 'register'){
      console.log(isOpenPaneName);   
      return <Register />;
     // isOpenPaneName = null;
    }
    
  } */

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isPaneOpen: false,
      isPaneOpenLeft: false,
      paneName: "login",
      fields: {},
      errors: {},
      invalidUser: "",
      company_email: "",
      company_password: "",
      vendor_company_email: "",
      vendor_company_password: "",
      loading: "",
      forgetPasswordTabValue: "",
      hrefVal: "",
    };
    this.onclickregister = this.onclickregister.bind(this);
    this.handlepassword = this.handlepassword.bind(this);
    this.handleForgetPasswordTab = this.handleForgetPasswordTab.bind(this);
  }
  onclickregister(p) {
    //console.log(p)
    this.props.callbackFromParent1(this.state);
  }
  handleChange(field, e) {
    //console.log(this.actionInput.value);
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  }
  handlepassword() {
    this.setState({ isPaneOpen: true, paneName: "forgotpassword" });
    this.props.callbackFromParent2(this.state);
  }

  /** Validations */
  handleValidation() {
    // console.log(this.state.fields);
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (this.actionInput.value == "company") {
      //console.log(this.actionInput.value);
      //Email
      //this.state.fields["vendor_company_email"] ='';
      // this.state.fields["vendor_company_password"] ='';

      if (!fields["company_email"]) {
        formIsValid = false;
        errors["company_email"] = "Please enter email";
      }
      if (typeof fields["company_email"] !== "undefined") {
        let lastAtPos = fields["company_email"].lastIndexOf("@");
        let lastDotPos = fields["company_email"].lastIndexOf(".");

        if (
          !(
            lastAtPos < lastDotPos &&
            lastAtPos > 0 &&
            fields["company_email"].indexOf("@@") == -1 &&
            lastDotPos > 2 &&
            fields["company_email"].length - lastDotPos > 2
          )
        ) {
          formIsValid = false;
          errors["company_email"] = "Please enter valid email";
        }
      }
      //Password
      if (!fields["company_password"]) {
        formIsValid = false;
        errors["company_password"] = "Please enter password";
      }
    } else {
      //Email
      //this.state.fields["company_email"] ='';
      //this.state.fields["company_password"] ='';

      if (!fields["vendor_company_email"]) {
        formIsValid = false;
        errors["vendor_company_email"] = "Please enter email";
      }
      if (typeof fields["vendor_company_email"] !== "undefined") {
        let lastAtPos = fields["vendor_company_email"].lastIndexOf("@");
        let lastDotPos = fields["vendor_company_email"].lastIndexOf(".");

        if (
          !(
            lastAtPos < lastDotPos &&
            lastAtPos > 0 &&
            fields["vendor_company_email"].indexOf("@@") == -1 &&
            lastDotPos > 2 &&
            fields["vendor_company_email"].length - lastDotPos > 2
          )
        ) {
          formIsValid = false;
          errors["vendor_company_email"] = "Please enter valid email";
        }
      }
      //Password
      if (!fields["vendor_company_password"]) {
        formIsValid = false;
        errors["vendor_company_password"] = "Please enter password";
      }
    }
    this.setState({ errors: errors });
    return formIsValid;
  }

  handleForgetPasswordTab(e) {
    let data
    this.actionInput.value == "company" ? data = "company": data = "vendor"
    this.setState({ hrefVal: "/forgotpassword" });
    this.props.history.push({ pathname: "/forgotpassword", state: data }); // your data array of objects
  }

  handleSubmit = (event) => {
    if (this.actionInput.value == "company") {
      event.preventDefault();
      if (this.handleValidation()) {
        this.setState({ loading: 1 });
        const user = {
          company_email: this.state.fields["company_email"],
          company_password: this.state.fields["company_password"],
        };
        // console.log(user);
        this.props.loginCompany(user, this.props.history);
        //let fields={}
        //fields["company_email"]='';
        //fields["company_password"]='';
        //this.setState({fields:fields});
      }
    } else {
      event.preventDefault();
      if (this.handleValidation()) {
        this.setState({ loading: 1 });
        const vendorlogin = {
          vendor_company_email: this.state.fields["vendor_company_email"],
          vendor_company_password: this.state.fields["vendor_company_password"],
        };
        //console.log(vendorlogin);
        this.props.loginVendor(vendorlogin);
        //let fields={}
        //fields["vendor_company_email"]='';
        //fields["vendor_company_password"]='';
        //this.setState({fields:fields});
      }
    }
  };

  componentDidMount() {
    //console.log("David: ",window.location.pathname);
    //var newurl_link = cookies.get('myurl');
    //var newurl = newurl_link.split("/",3);
    //console.log("newurl",newurl)
    //console.log( "newurl_link login: ", newurl_link);

    console.log(this.props.auth);
    if (this.props.auth.isAuthenticated == true && this.props.auth.user.type == "vendor") {
      this.setState({ loading: "" });
      this.props.history.push("/dashboard");
    }
    if (this.props.auth.isAuthenticated == true && this.props.auth.user.type == "company") {
      this.setState({ loading: "" });
      this.props.history.push("/companydashboard");
    }
  }

  componentWillReceiveProps(nextProps) {
    // console.log(this.props.auth);
    if (nextProps.auth.isAuthenticated == true && nextProps.auth.user.type == "vendor") {
      //window.location.href = '/dashboard'
      this.setState({ loading: "" });
      this.props.history.push("/dashboard");
      //this.props.history.push('/services')
    }
    if (nextProps.auth.isAuthenticated == true && nextProps.auth.user.type == "company") {
      //window.location.href = '/dashboard'
      this.setState({ loading: "" });
      this.props.history.push("/companydashboard");
      //this.props.history.push('/services')
    }
    if (nextProps.errors.Status == "Failed") {
      this.setState({
        invalidUser: nextProps.errors.msg,
      });
      this.setState({ loading: "" });
      setTimeout((e) => {
        this.setState({
          invalidUser: "",
        });
      }, 2000);
    }
  }

  render() {
    return (
      <div className="login-block" id="login-grid">
        <aside>
          <div className="container">
            <div className="login-grid-in">
              <h3>Login</h3>
              <div className="form-block">
                <Tabs>
                  <TabList className="row">
                    <Tab className="col-md-6">
                      <em>
                        <span className="vender-icon">
                          <img src={VenderIcon} alt="" />
                        </span>
                        <span>Vendor</span>
                      </em>
                    </Tab>
                    <Tab className="col-md-6">
                      <em>
                        <span className="company-icon">
                          <img src={CompanyIcon} alt="" />
                        </span>
                        <span>Company</span>
                      </em>
                    </Tab>
                  </TabList>

                  <TabPanel>
                    <form onSubmit={this.handleSubmit.bind(this)}>
                      <span className="error-invalid" style={{ color: "red" }}>
                        {this.state.invalidUser}
                      </span>
                      <Row>
                        {/* <div className="form-field col-md-12">
    <div class="input-group">
      <div className='md-form'>
      <input type="email" class="form-control" id="inlineFormInputGroupUsername" placeholder="email *" />
      </div>
    </div>
  </div>
  <div className="form-field col-md-12">
    <div class="input-group">
    <div className='md-form'>
      <input type="password" class="form-control" id="inlineFormInputGroupUsername" placeholder="Password * " />
    </div>    
    </div>
  </div> */}
                        <div className="form-field col-md-12">
                          <input
                            type="hidden"
                            name="type"
                            value="vendor"
                            id="inlineFormInputGroupUsername"
                            placeholder="Username"
                            ref={(input) => {
                              this.actionInput = input;
                            }}
                          />
                          <MDBInput
                            type="text"
                            name="vendor_company_email"
                            onChange={this.handleChange.bind(this, "vendor_company_email")}
                            placeHolder="Email *"
                          />
                          <span className="error" style={{ color: "red" }}>
                            {this.state.errors["vendor_company_email"]}
                          </span>
                        </div>
                        <div className="form-field col-md-12">
                          <MDBInput
                            type="password"
                            name="vendor_company_password"
                            onChange={this.handleChange.bind(this, "vendor_company_password")}
                            placeHolder="Password *"
                          />
                          <span className="error" style={{ color: "red" }}>
                            {this.state.errors["vendor_company_password"]}
                          </span>
                        </div>
                        <div className="form-field col-md-12">
                          <MDBBtn type="submit" title="Vendor Login">
                            Login
                            <div
                              style={this.state.loading ? {} : { display: "none" }}
                              class="image-fill w-25 loader-login-div "
                            >
                              <img src={Loading} alt="No Image" className="can-click " />
                            </div>
                          </MDBBtn>
                        </div>
                      </Row>
                    </form>
                  </TabPanel>

                  <TabPanel>
                    <form onSubmit={this.handleSubmit.bind(this)}>
                      <span className="error-invalid" style={{ color: "red" }}>
                        {this.state.invalidUser}
                      </span>
                      <Row>
                        <div className="form-field col-md-12">
                          <input
                            type="hidden"
                            name="type"
                            value="company"
                            ref={(input) => {
                              this.actionInput = input;
                            }}
                            placeholder="Username"
                          />
                          <MDBInput
                            type="text"
                            name="company_email"
                            onChange={this.handleChange.bind(this, "company_email")}
                            placeHolder="Email *"
                          />
                          <span className="error" style={{ color: "red" }}>
                            {this.state.errors["company_email"]}
                          </span>
                        </div>

                        <div className="form-field col-md-12">
                          <MDBInput
                            type="password"
                            name="company_password"
                            onChange={this.handleChange.bind(this, "company_password")}
                            placeHolder="Password *"
                          />
                          <span className="error" style={{ color: "red" }}>
                            {this.state.errors["company_password"]}
                          </span>
                        </div>
                        <div className="form-field col-md-12">
                          <MDBBtn type="submit" title="Company Login">
                            Login
                            <div
                              style={this.state.loading ? {} : { display: "none" }}
                              class="image-fill w-25 loader-login-div "
                            >
                              <img src={Loading} alt="No Image" className="can-click " />
                            </div>
                          </MDBBtn>
                        </div>
                      </Row>
                    </form>
                  </TabPanel>
                </Tabs>
                {/*  <div className="forgot-block text-center"><a onClick={() => this.setState({ isPaneOpen: true,paneName:'forgotpassword' })}>Forgot Password</a></div>
                <div className="forgot-block text-center col-md-12 mar-top">Don’t have an account <a onClick={() => this.setState({ isPaneOpen: true,paneName:'register' })} className="und">Register</a></div> */}

                {/* <div className="forgot-block text-center"><a onClick={() => this.setState({ isPaneOpen: true,paneName:'forgotpassword' })} >Forgot Password</a></div> */}
                {/* <div className="forgot-block text-center"><a onClick={this.handlepassword} >Forgot Password</a></div> */}
                <div className="forgot-block text-center">
                  <a onClick={() => this.handleForgetPasswordTab("/forgotpassword")} href={this.state.hrefVal}>
                    Forgot Password
                  </a>
                </div>
                {/* <div className="forgot-block text-center col-md-12 mar-top">Don’t have an account? <a onClick={this.onclickregister} >Register</a></div> */}
                <div className="forgot-block text-center col-md-12 mar-top">
                  Don’t have an account? <a href="/register">Register</a>
                </div>
              </div>

              <SlidingPane
                className={this.state.paneName}
                overlayClassName="some-custom-overlay-class"
                isOpen={this.state.isPaneOpen}
                onRequestClose={() => {
                  // triggered on "<" on left top click or on outside click
                  this.setState({ isPaneOpen: false });
                }}
              >
                <Forgotpassword />
              </SlidingPane>
            </div>
          </div>
        </aside>
      </div>
    );
  }
}

Login.propTypes = {
  loginCompany: PropTypes.func.isRequired,
  loginVendor: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});
export default connect(
  mapStateToProps,
  { loginCompany, loginVendor }
)(Login);
