const AppConstants = {
  //Prod

  API: 'https://bizlinkbuyingdiverse.com/api',
  STAGRFP: '',
  SITEURL: '/',
  // API: 'http://bidlinkapi.buyingdiverse.com/api',
  // STAGRFP: '',
  // SITEURL: '/',

  //Stag
  //API: 'https://www.bidlinkmemphis.com/stagapi',
  //STAGRFP: '/stagrfp',
  //SITEURL: 'https://www.bidlinkmemphis.com/stagrfp'
  
  
  //Local
  //API: 'http://localhost:2223/stagapi',
  //STAGRFP: '/stagrfp',
  //SITEURL: 'https://www.bidlinkmemphis.com/stagrfp'


}
export default AppConstants;
