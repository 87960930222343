import React, { Component } from 'react';
import { MDBInput,MDBBtn  } from 'mdbreact';
import {Row} from 'reactstrap';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import axios from 'axios';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import AppConstants from '../AppConstants';
import Companypassword from '../pages/companypassword';
import Loading from '../pages/loader.gif';

let companyid = '';
class Customerotp extends React.Component {
    constructor(props){
      console.log(props);
        super(props);
        this.state = {
            fields: {},
            errors: {},
            invalidUser:'',
            errMsg:'',
            "customer_id":this.props.match.params.company_id,
            "otp":'',
            "companyid":'',
            loading: '',
            successMsg:'',
        }
    }    
    
    handleChange(field, e){    		
        let fields = this.state.fields;
        fields[field] = e.target.value;        
        this.setState({fields});
    }
    /** Validations */
  handleValidation(){
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    
    if(!fields["otp"]){
      formIsValid = false;
      errors["otp"] = "Please enter Verification Code";
    }
    this.setState({errors: errors});
    return formIsValid;
    }
    handleSubmit = event => {
        //console.log(process.env.REACT_APP_API_URL+'/users/login');
        event.preventDefault();
        if(this.handleValidation()){
          this.setState({loading:1});
          const companyotp = {
            customer_id:this.props.match.params.company_id,
            otp: this.state.fields['otp']
          }
          console.log('companyotp', companyotp)
          axios.post(AppConstants.API+'/cutomers/customer_otp_auth',companyotp)
            .then(res => {   
             if(res.data.Status == "Success"){
              this.setState({loading:''});
              setTimeout((e) => {
                window.location.href = AppConstants.STAGRFP+'/companypassword/'+res.data.customer_id;
            }, 5000)         
              this.setState({
                successMsg: res.data.msg
              });          
            }
            else{   
              this.setState({loading:''});       
              this.setState({
                errMsg: res.data.msg
              });
              setTimeout((e) => {
                this.setState({
                  errMsg: ''
                });
               },5000);       
            } 
          });      
        }
      };
      ResendOtpSubmit = event => {
        event.preventDefault();
        this.setState({loading:1});
        const resendcompanyrefno = {
          customer_id:this.props.match.params.company_id
        }
        axios.post(AppConstants.API+'/cutomers/customer_otp_resend',resendcompanyrefno)
        .then(res => {
         if(res.data.Status == "Success"){ 
          this.setState({loading:''});  
          setTimeout((e) => {
            this.setState({
              successMsg: false
            });
           },5000);       
          this.setState({
            successMsg: res.data.msg
          });          
        }
        else{
          this.setState({loading:''});         
          this.setState({
            errMsg: res.data.msg
          });
          setTimeout((e) => {
            this.setState({
              errMsg: ''
            });
           },5000);       
        } 
      });
    }
      componentDidMount() {
        console.log("registered company: "+this.props.match.params.company_id);
        //console.log("test ", this.props);
      }      

    render() {     
      return (          
        <div className="login-block" id='login-grid'>
        <div className='container'>           
         <div className="login-grid-in">
          {this.state.companyid}
              <h6 className="color-yellow">Verification Code has been sent to your email.<br/>
              Please enter the Verification Code below to verify your email address.
              </h6>
              
              <div className="form-block">
                <form name="otpauthentication" onSubmit= {this.handleSubmit.bind(this)}>
                    <span className="error-inva" style={{color: 'green', position: 'relative',    top: '-10px'}}>{this.state.successMsg}</span>
                    <span className="error-inva" style={{color: 'red', position: 'relative',    top: '-10px'}}>{this.state.errMsg}</span>
                    <Row>
                    <div className="form-field  col-md-12">
                        <MDBInput  type="text" name="otp" onChange={this.handleChange.bind(this, "otp")} value={this.state.fields["otp"]}  placeHolder="Enter Verification Code *"  />
                        <span className="error" style={{color: 'red'}}>{this.state.errors["otp"]}</span>
                    </div>
                    <div className="form-field  col-md-12">
                        <MDBBtn type="submit" title="Submit Verification Code">Submit<div style={this.state.loading ? {} : { display: 'none' }} class="image-fill w-25 loader-login-div "><img src={Loading} alt="No Image" className="can-click " /></div></MDBBtn>
                    </div>
                    
                      <div className="forgot-block text-center col-md-12">Re-send Verification Code <a onClick={this.ResendOtpSubmit.bind(this)} title="Re-send Verification Code"> Click Here</a></div>
                  </Row>
                </form>                
                </div>
          </div>  
          </div>
          </div>        
      );
    }
  }

  Customerotp.propTypes = {
    //companyid: PropTypes.object.isRequired
  };
  const mapStateToProps = (state) => ({
    //company__id:state
  });
  export  default connect(mapStateToProps, {})(Customerotp);