import React, { Component } from 'react';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import { Container, Row, Col } from 'reactstrap';
import AppConstants from '../AppConstants';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import {logoutUser,getProfile,dashboard} from '../actions/authentication';
import {ReactTitle} from 'react-meta-tags';
import PropTypes from 'prop-types';
import BrowserHistory from "react-router/lib/browserHistory";


//export default class  dashboard extends Component{
class viewfeedback extends React.Component {
    constructor(props) {
        //console.log(props);
        super(props);
        this.state = {      
            feedbackdata: [],
            errors: {},
            errMsg:false,
            rfprfiname:''
      };
    }

    componentDidMount() { 
      this.props.dashboard("dashboard");      
      window.scrollTo(0, 0); 
        fetch(AppConstants.API+'/rfprfiprofiles/feedbackvendors/'+this.props.match.params.rfprfi_id).then(response => response.json())
         .then(data => {
       // fetch(AppConstants.API+'/rfprfiprofiles/getallrfprfiprofiles').then(response => response.json())
        //.then(data => {
          if(data.Status == "Success")
          {
            this.setState({ feedbackdata: data.feedbackdata });
          }
          else
          {
            this.setState({ feedbackdata: []});
          }    
        });
        fetch(AppConstants.API+'/rfprfiprofiles/getrfpname/'+this.props.match.params.rfprfi_id).then(response => response.json())
         .then(data => {
          if(data.Status == "Success")
          {
            this.setState({ rfprfiname: data.rfprfiname });
          }
          else
          {
            this.setState({ rfprfiname: ''});
          }    
        });          
    }

    renderShowsTotal(start, to, total) {
        return (
          <p className="page-show">
          Showing rows { start } to { to } of { total }
          </p>
        );
    }
    actionBtn(cell,row) {     
    return  <div className="action-block"><Link to={`${AppConstants.STAGRFP}${"/viewvendordetails/"}${row.vendor_id}`} className="view-icon" title="View Vendor Details"><i class="fa fa-eye" aria-hidden="true"></i></Link></div>; 
    }

    dateFormat(cell,row) {
      // var date = row.rfp_post_date;
      var posteddate = row.created_date;
      var posteddate= new Date(posteddate);             
      return   ("0" + (posteddate.getMonth() + 1)).slice(-2) + "-" + (posteddate.getDate() < 10 ? '0' : '')+ posteddate.getDate() + "-" +  posteddate.getFullYear();      

    }
    
render() {    
    
const options = {
  page: 1,  // which page you want to show as default
  sizePerPageList: [ {
    text: '10', value: 10
  }, {
    text: '25', value: 25
  }, {
    text: 'All', value: this.state.feedbackdata.length
  } ], // you can change the dropdown list for size per page
  sizePerPage: 10,  // which size per page you want to locate as default
  pageStartIndex: 1, // where to start counting the pages
  paginationSize: 5,  // the pagination bar size.
  prePage: '<', // Previous page button text
  nextPage: '>', // Next page button text
  firstPage: 'First', // First page button text
  lastPage: 'Last', // Last page button text
  paginationShowsTotal: this.renderShowsTotal,  // Accept bool or function
  paginationPosition: 'bottom'  // default is bottom, top and both is all available
 
};
if(localStorage.jwtToken != undefined && this.props.auth.user.type =='company'){
    return <div>
        <ReactTitle title="Feedback Vendors" />
        <div className="inner-banner" />
        <div className="inner-content">
          <Container>

              <button onClick={BrowserHistory.goBack} className="btn btn-sm fright back-btn">
                Back
              </button>
          
            <div className="dashboard-rfp-list">
              <div className="dashboard-rfp-head">
                <h6>Opportunity Name : {this.state.rfprfiname}</h6>
                {/* <span className="applied">Applied</span>
                            <span className="not-applied">Not Applied</span>
                            <span className="liked">Liked</span> */}
              </div>
              <BootstrapTable data={this.state.feedbackdata} pagination={true} options={options} search>
                {/* <TableHeaderColumn dataField='_id' isKey={ true } dataSort={ true } hidden={true}>ID</TableHeaderColumn> */}

                <TableHeaderColumn dataField="vendor_name" isKey={true} dataSort={true}>
                  Vendor Name
                </TableHeaderColumn>
                <TableHeaderColumn dataField="feedback" dataSort={true}>
                  Feedback
                </TableHeaderColumn>
                <TableHeaderColumn dataField="created_date" dataFormat={this.dateFormat.bind(this)} dataSort={true}>
                  Feedback Date
                </TableHeaderColumn>
                <TableHeaderColumn dataField="Action" dataFormat={this.actionBtn.bind(this)}>
                  Actions
                </TableHeaderColumn>
              </BootstrapTable>
            </div>
          </Container>
        </div>
      </div>;
    }
    else
    {
        window.location.href = AppConstants.STAGRFP+'/';
    }
  }
}
viewfeedback.propTypes = {
  dashboard:PropTypes.func.isRequired
};
const mapStateToProps = (state) => ({
    auth: state.auth
  })
  //export default Header;
  export default connect(mapStateToProps, {logoutUser,getProfile,dashboard})(withRouter(viewfeedback));
