import React, {Component} from "react";
import {MDBInput, MDBBtn} from "mdbreact";
import {Row, Col} from "reactstrap";
import axios from "axios";
import "react-sliding-pane/dist/react-sliding-pane.css";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import AppConstants from "../AppConstants";
import SlidingPane from "react-sliding-pane";

class setcustomerpassword extends React.Component {
  constructor(props) {
    super(props);
    //console.log(props);
    this.state = {
      fields: {},
      errors: {},
      invalidUser: "",
      errMsg: "",
      passwordid: this.props.match.params.id,
      successMsg: "",
    };
  }
  componentDidMount() {
    this.passwordid = this.props.match.params.id;
  }
  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({fields});
  }
  /** Validations */
  handleValidation() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    //Email
    if (!fields["password"]) {
      formIsValid = false;
      errors["password"] = "Please enter password";
    }
    if (!fields["cnfpassword"]) {
      formIsValid = false;
      errors["cnfpassword"] = "Please enter confirm password";
    } else if (fields["password"] != fields["cnfpassword"]) {
      formIsValid = false;
      errors["cnfpassword"] = "Confirm password does not match";
    }
    this.setState({errors: errors});
    return formIsValid;
  }
  handleSubmit = (event) => {
    //console.log(this.props);
    //console.log(process.env.REACT_APP_API_URL+'/users/login');
    event.preventDefault();
    if (this.handleValidation()) {
      const setcompanypwd = {
        id: this.state.passwordid,
        company_password: this.state.fields["password"],
      };
      axios
        .post(AppConstants.API + "/cutomers/customersetpassword", setcompanypwd)
        .then((res) => {
          //console.log(res);
          if (res.data.Status == "Success") {
            setTimeout((e) => {
              //this.props.history.push('/');
              window.location.href = AppConstants.STAGRFP + "/";
            }, 1000);
            this.setState({
              successMsg: res.data.msg,
            });
          } else {
            this.setState({
              errMsg: res.data.msg,
            });
          }
        });
    }
  };
  render() {
    return (
      <div className="login-block" id="login-grid">
        <div className="container">
        <div className="login-grid-in change-pwd">
        <h3 className="color-yellow">Set Company Password</h3>
        <div className="form-block">
          <form onSubmit={this.handleSubmit.bind(this)}>
            <span
              className="error-inva"
              style={{color: "green", position: "relative", top: "-10px"}}
            >
              {this.state.successMsg}
            </span>
            <span
              className="error-inva"
              style={{color: "red", position: "relative", top: "-10px"}}
            >
              {this.state.errMsg}
            </span>
            <Row>
              <div className="form-field col-md-12">
                <MDBInput
                  placeHolder="Password *"
                  type="password"
                  name="password"
                  onChange={this.handleChange.bind(this, "password")}
                  value={this.state.fields["password"]}
                />
                <span className="error" style={{color: "red"}}>
                  {this.state.errors["password"]}
                </span>
              </div>
              </Row>
              <Row>
              <div className="form-field col-md-12">
                <MDBInput
                  placeHolder="Confirm Password *"
                  type="password"
                  name="cnfpassword"
                  onChange={this.handleChange.bind(this, "cnfpassword")}
                  value={this.state.fields["cnfpassword"]}
                />
                <span className="error" style={{color: "red"}}>
                  {this.state.errors["cnfpassword"]}
                </span>
              </div>
              <div className="form-field col-md-12">
                <MDBBtn type="submit" title="Set Company Password">
                  Submit
                </MDBBtn>
              </div>
            </Row>
          </form>
        </div>
        </div>
       
        </div>
       
      </div>
    );
  }
}

setcustomerpassword.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});
export default connect(
  mapStateToProps,
  {}
)(setcustomerpassword);
