import React, { Component } from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import { MDBInput, MDBBtn } from 'mdbreact';
import axios from 'axios';
import AvatarUploader from 'react-avatar-uploader';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getVendorProfile, updateVendorProfile, handlevendordashboard } from '../actions/authentication';
import AppConstants from '../AppConstants';
import NumberFormat from 'react-number-format';
// import DayPickerInput from 'react-day-picker/DayPickerInput';
// import 'react-day-picker/lib/style.css';
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';
// import InfiniteCalendar from 'react-infinite-calendar';
// import 'react-infinite-calendar/styles.css';
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';

import MomentLocaleUtils, { formatDate } from 'react-day-picker/moment';
import { Typeahead } from 'react-bootstrap-typeahead';
import moment from 'moment';
import nologo from './logo.jpg';
import Loading from '../pages/loader.gif';
import { ReactTitle } from 'react-meta-tags';

var uncheckedids = [];
var certification_newids = [];
var filedata = [];
var errors1 = {};
var formIsValid1 = true;

class vendorinfo extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      id: '',
      vendor_id: '',
      vendor_company_name: '',
      vendor_company_email: '',
      vendor_company_type: '',
      vendor_company_industrey: '',
      vendor_company_address: '',
      vendor_company_phoneno: '',
      vendor_company_website: '',
      vendor_primary_contact_firstname: '',
      vendor_primary_contact_lastname: '',
      vendor_primary_contact_middlename: '',
      vendor_primary_contact_title: '',
      vendor_primary_contact_phoneno: '',
      vendor_primary_email: '',
      vendor_primary_cellno: '',
      vendor_primary_officeno: '',
      vendor_primary_department: '',
      vendor_product_needs: '',
      certificationsandmembership: '',
      naic_code: [],
      descriptionofproductsandservices: '',
      companyleadership: '',
      department_min_revenue: '',
      department_max_revenue: '',
      employees: '',
      locationsOfOffices: [],
      latitude: '',
      longitude: '',
      country: '',
      state: '',
      city: '',
      zipcode: '',
      companyLogo: '',
      view_companyLogo: '',
      prev_companyLogo: '',
      uploadVideo: '',
      view_uploadVideo: '',
      prev_uploadVideo: '',
      instagramLink: '',
      facebookLink: '',
      linkedInLink: '',
      youTubeLik: '',
      twitterLink: '',
      businessStartedDate: new Date(),
      accolades: [],
      media: [],
      companiesdata: [],
      naiccodes: [],
      multiplevalues: '',
      multipleaccolades: '',
      industriesdata: [],
      certifications: [],
      certificationsdata: [],
      vendor_company_address2: '',
      leadershipemail: '',
      leadershiptitle: '',
      errors: {},
      urlError:{},
      errMsg: '',
      successerrMsg: '',
      info1: true,
      info2: false,
      info3: false,
      info4: false,
      showSocialInfo: false,
      showContactInfo: false,
      showBasic: true,
      loading: '',
      // vendor_business_model: '',
      certification_documents: [],
      multidocs: [],
      multidocids: [],
      certificationid: [],
      certificationfiles: [],
      vendorid: this.props.auth.user._id,
      errors1: {},
      muslim_owned_business: 'No',
      memphis_chamber_mmember: 'No',
      countMembership: '',
      countMedia: '',
      countaccolades: '',
      subindustriesdata: [],
      subindustries: [],
      years_in_business:'',
      no_of_jobs_currently_ongoing: '',
      order_size_capable: '',
      size_of_largest_order: '',
      avg_fulfillment_time: '',
      exp_in_category: '',
      mwbe_certification_category: '',
      desc_for_wrk_exp: '',
      desc_for_wrk_exp_in_category: '',
      references: '',
    };
    this.handleBasic = this.handleBasic.bind(this);
    this.handleContactInfo = this.handleContactInfo.bind(this);
    this.handleSocialInfo = this.handleSocialInfo.bind(this);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleInputFileChange = this.handleInputFileChange.bind(this);
    // this.handleInputVideoChange=this.handleInputVideoChange.bind(this);
    this.onChangeNumberkey = this.onChangeNumberkey.bind(this);
    this.handleInputChangeFund = this.handleInputChangeFund.bind(this);
    this.handleDayChange = this.handleDayChange.bind(this);
    // this.handleVendorModelChange = this.handleVendorModelChange.bind(this);
    this.handleBusinessModelChange = this.handleBusinessModelChange.bind(this);
    this.handleChamberModelChange = this.handleChamberModelChange.bind(this);

    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handlePhoneChange = this.handlePhoneChange.bind(this);
    this.handleIndustryChange = this.handleIndustryChange.bind(this);
    this.handleSubCatChange = this.handleSubCatChange.bind(this);
  }

  handleIndustryChange(event) {
    //console.log(event.target.value);
    let vendor_company_industrey = '';
    if (event) {
      vendor_company_industrey = event.target.value;
      this.setState({ subindustries: [] });
    } else {
      vendor_company_industrey = this.state.vendor_company_industrey;
    }

    if (vendor_company_industrey != '') {
      fetch(AppConstants.API + '/industries/getsubindustries/' + vendor_company_industrey)
        .then((response) => response.json())
        .then((data) => {
          // console.log(' sub industries ', data.subindustriesdata);
          if (data.Status == 'Success') {
            this.setState({
              subindustriesdata: data.subindustriesdata,
              vendor_company_industrey: vendor_company_industrey,
            });
          } else {
            this.setState({
              vendor_company_industrey: vendor_company_industrey,
            });
          }
        });
    } else {
      this.setState({
        vendor_company_industrey: vendor_company_industrey,
      });
    }
  }

  handleClick(e) {
    let index;
    if (e.target.checked) {
      certification_newids.push(e.target.value);
      document.getElementById('cert_' + e.target.value).style.display = 'block';
      filedata.push(document.getElementById('cert_' + e.target.value).style.display);
    } else {
      //document.getElementById('cert_'+e.target.value).style.display='none';
      document.getElementById('cert_' + e.target.value).style.display = 'none';
      index = filedata.indexOf(document.getElementById('cert_' + e.target.value));
      filedata.splice(index, 1);
    }
  }

  handlePhoneChange(e) {
    //const re1 =/^[0-9)\(+ -]+$/g;
    const re1 = /^[0-9)\(+ '-]+$/g;
    // var phone_num = e.target.value.replace(/\D/g,'');
    if (e.target.value === '' || re1.test(e.target.value)) {
      // phone_num = this.phoneFormat(phone_num)
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  }

  phoneFormat(input) {
    // Strip all characters from the input except digits
    // Trim the remaining input to ten characters, to preserve phone number format
    input = input.substring(0, 10);
    // Based upon the length of the string, we add formatting as necessary
    var size = input.length;
    if (size == 0) {
      input = input;
    } else if (size < 4) {
      input = '(' + input;
    } else if (size < 7) {
      input = '(' + input.substring(0, 3) + ') ' + input.substring(3, 6);
    } else {
      input = '(' + input.substring(0, 3) + ') ' + input.substring(3, 6) + '-' + input.substring(6, 10);
    }
    return input;
  }

  handleFileChange(e) {
    if (filedata != 0) {
      filedata.length--;
    }
    var newdata = e.target.id.split('_');
    certification_newids.push(newdata[1]);

    //console.log(certification_newids);
    this.setState({ [e.target.name]: e.target.files[0] });
    let formData = new FormData();

    formData.append('vendorid', this.props.auth.user._id);
    formData.append('certification_documents', e.target.files[0]);
    formData.append('certificationid', JSON.stringify(certification_newids));

    axios.post(AppConstants.API + '/vendors/addvendorcertificate', formData).then((res) => {
      // console.log(res);
      if (res.data.Status == 'certifiedSuccess') {
        this.setState({
          vendorid: res.data.vendorid,
        });
      }
    });
  }

  // handleVendorModelChange(e) {
  //   this.setState({ [e.target.name]: e.target.value });
  // }

  handleBusinessModelChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  handleChamberModelChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleInputChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleInputFileChange(e) {
    this.setState({
      [e.target.name]: e.target.files[0],
    });
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      this.setState({
        file: file,
        imagePreviewUrl: reader.result,
      });
    };

    reader.readAsDataURL(file);
  }
  handleInputChangeFund(e) {
    const certifications = this.state.certifications;
    let index;
    // check if the check box is checked or unchecked
    if (e.target.checked) {
      // add the numerical value of the checkbox to options array
      certifications.push(e.target.value);
    } else {
      //console.log(e.target.value);
      var newids = e.target.value;
      uncheckedids.push(newids);
      // or remove the value from the unchecked checkbox from the array
      index = certifications.indexOf(e.target.value);
      certifications.splice(index, 1);
    }
    // update the state with the new array of options
    this.setState({ certifications: certifications });
  }
  handleInputVideoChange(e) {
    this.setState({
      [e.target.name]: e.target.files[0],
    });
  }
  onChangeNumberkey(e) {
    const zip = this.state;
    //const re = /^[0-9\b]+$/;
    const re = /^[0-9)\(+ '-]+$/g;
    if (e.target.value === '' || re.test(e.target.value)) {
      zip[e.target.name] = e.target.value;
      this.setState({ zip });
    }
  }
  handleDayChange(day) {
    //console.log('In handleDayChange')
    this.setState({ businessStartedDate: day });
  }

  handleBasic(e) {
    //alert('dfddfdf');
    if (this.state.trip_purposes == 'Other') {
      this.setState({ showSocialInfo: true });
    } else {
      this.setState({ showSocialInfo: false });
    }
    this.setState({
      showContactInfo: false,
      info2: false,
      info3: false,
      info4: false,
    });
    this.setState({ showBasic: true
     });
  }


  handleContactInfo(e) {
    e.preventDefault();
    if (this.handleValidation() && formIsValid1 == true) {
      this.setState({ showBasic: false });
      this.setState({
        showContactInfo: true,
        info2: true,
        showSocialInfo: false,
        info3: false,
        info4: false,
      });
    }
  }

  handleSocialInfo(e) {
    e.preventDefault();
    //alert('2');
    //if(this.handleValidation1()){
    this.setState({ showBasic: false });
    this.setState({ showContactInfo: false });
    this.setState({ showSocialInfo: true, info4: true });
    //}
  }

  /** accolades multiple links */
  handleAccoladesChange(i, event) {
    let accolades = [...this.state.accolades];
    accolades[i] = event.target.value;
    this.setState({ accolades });
  }
  addAccoladesClick() {
    this.setState((prevState) => ({ accolades: [...prevState.accolades, ''] }));
  }
  removeAccoladesClick(i) {
    let accolades = [...this.state.accolades];
    accolades.splice(i, 1);
    this.setState({ accolades });
  }
  createAccoladesUI() {
    return this.state.accolades.map((el, i) => (
      <div
        className="accolades"
        style={{
          display: i == 0 && this.state.countaccolades != 0 ? 'none' : 'block',
        }}
        key={i}
      >
        <MDBInput
          name="accolades"
          value={el || ''}
          onChange={this.handleAccoladesChange.bind(this, i)}
          class="form-control"
          label="Accolades"
        />
        <input type="button" className="remove-btn" value="remove" onClick={this.removeAccoladesClick.bind(this, i)} />
      </div>
    ));
  }

  createMediaUI() {
    return this.state.media.map((el, i) => (
      <div
        className="accolades"
        style={{
          display: i == 0 && this.state.countMedia != 0 ? 'none' : 'block',
        }}
        key={i}
      >
        <MDBInput
          name="media"
          value={el || ''}
          onChange={this.handleMediaChange.bind(this, i)}
          class="form-control"
          label="Media Links"
        />
        <input type="button" className="remove-btn" value="remove" onClick={this.removeMediaClick.bind(this, i)} />
        <span className="error" id={'errr_' + i} style={{ color: 'red' }}>
          {this.state.errors1[i]}
        </span>
      </div>
    ));
  }

  /** Media multiple links */
  handleMediaChange(j, event) {
    let media = [...this.state.media];
    media[j] = event.target.value;
    this.setState({ media });

    if (event.target.value && event.target.value != '' && !/^https?:\/\//i.test(event.target.value)) {
      formIsValid1 = false;
      errors1[j] = 'Please enter valid media link';
      this.setState({ errors1: errors1 });
      //console.log(errors1);
    } else {
      formIsValid1 = true;
    }
    return formIsValid1;
  }
  addMediaClick() {
    //console.log('clicked');
    this.setState((prevState) => ({ media: [...prevState.media, ''] }));
  }
  removeMediaClick(i) {
    let media = [...this.state.media];
    media.splice(i, 1);
    this.setState({ media });
  }

  /** Memberships*/
  createMemberUI() {
    return this.state.membership.map((el, i) => (
      <div
        className="accolades"
        style={{
          display: i == 0 && this.state.countMembership != 0 ? 'none' : 'block',
        }}
        key={i}
      >
        <MDBInput
          name="membership"
          value={el || ''}
          onChange={this.handleMemberChange.bind(this, i)}
          class="form-control"
          label="Membership"
        />
        <input type="button" className="remove-btn" value="remove" onClick={this.removeMemberClick.bind(this, i)} />
      </div>
    ));
  }
  handleMemberChange(i, event) {
    let membership = [...this.state.membership];
    membership[i] = event.target.value;
    this.setState({ membership });
  }
  addMemberClick() {
    this.setState((prevState) => ({
      membership: [...prevState.membership, ''],
    }));
  }
  removeMemberClick(i) {
    let membership = [...this.state.membership];
    membership.splice(i, 1);
    this.setState({ membership });
  }

  // handleValidation1(){
  //     let errors = {};
  //     let formIsValid = true;

  //     if(filedata.length>0){
  //         formIsValid=false;
  //         errors["certify"]="Please upload a file"
  //     }
  //     this.setState({errors: errors});
  //     return formIsValid;
  // }

  handleValidation2() {
    let errors = {};
     let urlError = {};
    let formIsValid = true;

    if (this.state.instagramLink && !/^https?:\/\//i.test(this.state.instagramLink)) {
      formIsValid = false;
      urlError['instagramLink'] = 'Please enter valid instagram link';
    }
    if (this.state.facebookLink && !/^https?:\/\//i.test(this.state.facebookLink)) {
      formIsValid = false;
      urlError['facebookLink'] = 'Please enter valid facebook link';
    }
    if (this.state.linkedInLink && !/^https?:\/\//i.test(this.state.linkedInLink)) {
      formIsValid = false;
      urlError['linkedInLink'] = 'Please enter valid linkedin link';
    }
    if (this.state.youTubeLik && !/^https?:\/\//i.test(this.state.youTubeLik)) {
      formIsValid = false;
      urlError['youTubeLik'] = 'Please enter valid youtube link';
    }
    if (this.state.twitterLink && !/^https?:\/\//i.test(this.state.twitterLink)) {
      formIsValid = false;
      urlError['twitterLink'] = 'Please enter valid twitter link';
    }
    this.setState({ urlError: urlError });
    return formIsValid;
  }

  /** Validations */
  handleValidation() {
    //console.log('working');
    let errors = {};
    let formIsValid = true;

    // if(filedata.length>0){
    //     formIsValid=false;
    //     errors["certify"]="Please upload a file"
    // }

    if (!this.state.vendor_company_name) {
      formIsValid = false;
      errors['vendor_company_name'] = 'Please enter company name';
    }
    if (!this.state.vendor_company_type) {
      formIsValid = false;
      errors['vendor_company_type'] = 'Please select type';
    }
    if (!this.state.vendor_company_industrey) {
      formIsValid = false;
      errors['vendor_company_industrey'] = 'Please select industry';
    }
    if (!this.state.vendor_company_address) {
      formIsValid = false;
      errors['vendor_company_address'] = 'Please enter address 1';
    }
    if (!this.state.vendor_company_address2) {
      formIsValid = false;
      errors['vendor_company_address2'] = 'Please enter head quarters address';
    }

    if (!this.state.years_in_business) {
      formIsValid = false;
      errors['years_in_business'] = 'Please enter years in business';
    }
    if (!this.state.no_of_jobs_currently_ongoing) {
      formIsValid = false;
      errors['no_of_jobs_currently_ongoing'] = 'Please enter no.of jobs currently ongoing';
    }
    if (!this.state.employees) {
      formIsValid = false;
      errors['employees'] = 'Please enter no.of employees';
    }
    if (!this.state.order_size_capable) {
      formIsValid = false;
      errors['order_size_capable'] = 'Please enter max job / order size capable';
    }
    if (!this.state.size_of_largest_order) {
      formIsValid = false;
      errors['size_of_largest_order'] = 'Please enter size of largest order';
    }
    if (!this.state.avg_fulfillment_time) {
      formIsValid = false;
      errors['avg_fulfillment_time'] = 'Please enter average fulfillment time';
    }

    if (!this.state.exp_in_category) {
      formIsValid = false;
      errors['exp_in_category'] = 'Please enter experience in category ';
    }
    if (!this.state.mwbe_certification_category) {
      formIsValid = false;
      errors['mwbe_certification_category'] = 'Please enter mwbe certification category';
    }
    if (!this.state.desc_for_wrk_exp) {
      formIsValid = false;
      errors['desc_for_wrk_exp'] = 'Please enter deficient experience in category or schedule completion  ';
    }
    if (!this.state.desc_for_wrk_exp_in_category) {
      formIsValid = false;
      errors['desc_for_wrk_exp_in_category'] = 'Please enter stayed experience in category';
    }
    if (!this.state.references) {
      formIsValid = false;
      errors['references'] = 'Please enter references ';
    }
  

    if (!this.state.city) {
      formIsValid = false;
      errors['city'] = 'Please enter city';
    }
    if (!this.state.state) {
      formIsValid = false;
      errors['state'] = 'Please enter state';
    }
    if (!this.state.zipcode) {
      formIsValid = false;
      errors['zipcode'] = 'Please enter zip code';
    } else if (this.state.zipcode.length > 15) {
      formIsValid = false;
      errors['zipcode'] = 'Please enter valid zipcode';
    }
    //  if(!this.state.vendor_company_phoneno !=''){
    //     formIsValid = false;
    //     errors["vendor_company_phoneno"] = "Please enter phone number";
    // }
    /*
        if(this.state.vendor_primary_contact_phoneno !='' && this.state.vendor_primary_contact_phoneno.length !=14){
            formIsValid = false;
            errors["vendor_primary_contact_phoneno"] = "Please enter 10 digits primary phone number";
        } */
    if (!this.state.naic_code.length) {
      formIsValid = false;
      errors['naic_code'] = 'Please select at-least one NAICS Code';
    } else if (this.state.naic_code.length > 7) {
      formIsValid = false;
      errors['naic_code'] = 'Please select max 7 NAICS Codes';
    }

    if (
      this.state.companyLogo &&
      this.state.companyLogo.name &&
      !this.state.companyLogo.name.match(/.(jpg|jpeg|png)$/i)
    ) {
      formIsValid = false;
      errors['companyLogo'] = 'Please select valid image (Ex: jpg | jpeg | png )';
    }
    // if (!this.state.vendor_business_model) {
    //   formIsValid = false;
    //   errors['vendor_business_model'] = 'Please select business model';
    // }
    if (this.state.vendor_company_website && !/^https?:\/\//i.test(this.state.vendor_company_website)) {
      formIsValid = false;
      errors['vendor_company_website'] = 'Please enter valid website';
    }
    if (this.state.multipleValues && !/^https?:\/\//i.test(this.state.multipleValues)) {
      formIsValid = false;
      errors['multipleValues'] = 'Please enter valid media link';
    }

    if (formIsValid1 == true) {
      this.state.media.map(
        function(item, j) {
          if (item && item != '' && !/^https?:\/\//i.test(item)) {
            formIsValid1 = false;
            errors1[j] = 'Please enter valid media link';
            this.setState({ errors1: errors1 });
            //console.log(errors1);
          } else {
            formIsValid1 = true;
          }
          //console.log(formIsValid1);
          return formIsValid1;
        }.bind(this)
      );
    }

    this.setState({ errors: errors });

    // console.log('All_erroer', this.state.errors);

    return formIsValid;
  }

  isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  }

  handleSubmit = (event) => {
    event.preventDefault(event);

    //if(this.handleValidation() && this.handleValidation1() && this.handleValidation2() && formIsValid1==true){
    if (this.handleValidation() && this.handleValidation2() && formIsValid1 == true) {
      //  if (this.handleValidation() && formIsValid1 == true) {
      this.setState({ loading: 1 });
      let formData = new FormData();
      formData.append('id', this.props.auth.user._id);
      formData.append('vendor_id', this.props.auth.user._id);
      formData.append('vendor_company_name', this.state.vendor_company_name);
      formData.append('vendor_company_email', this.state.vendor_company_email);
      formData.append('vendor_company_password', this.state.vendor_company_password);
      formData.append('vendor_company_type', this.state.vendor_company_type);
      formData.append('vendor_company_industrey', this.state.vendor_company_industrey);
      formData.append('certifications', JSON.stringify(this.state.certifications));
      formData.append('vendor_company_address', this.state.vendor_company_address);
      formData.append('vendor_company_address2', this.state.vendor_company_address2);
      formData.append('vendor_company_phoneno', this.state.vendor_company_phoneno);
      formData.append('vendor_company_website', this.state.vendor_company_website);
      formData.append('vendor_primary_contact_firstname', this.state.vendor_primary_contact_firstname);
      formData.append('vendor_primary_contact_lastname', this.state.vendor_primary_contact_lastname);

      formData.append('vendor_primary_contact_middlename', this.state.vendor_primary_contact_middlename);
      formData.append('vendor_primary_contact_title', this.state.vendor_primary_contact_title);

      formData.append('vendor_primary_contact_phoneno', this.state.vendor_primary_contact_phoneno);
      formData.append('vendor_primary_email', this.state.vendor_primary_email);
      formData.append('vendor_primary_cellno', this.state.vendor_primary_cellno);
      formData.append('vendor_primary_officeno', this.state.vendor_primary_officeno);

      formData.append('vendor_primary_department', this.state.vendor_primary_department);
      formData.append('certificationsandmembership', this.state.certificationsandmembership);

      formData.append('naic_code', JSON.stringify(this.state.naic_code));
      formData.append('descriptionofproductsandservices', this.state.descriptionofproductsandservices);

      formData.append('companyleadership', this.state.companyleadership);
      formData.append('department_min_revenue', this.state.department_min_revenue);
      formData.append('department_max_revenue', this.state.department_max_revenue);

      formData.append('employees', this.state.employees);
      formData.append('locationsOfOffices', this.state.locationsOfOffices);
      formData.append('latitude', this.state.latitude);
      formData.append('longitude', this.state.longitude);

      formData.append('country', this.state.country);
      formData.append('state', this.state.state);
      formData.append('city', this.state.city);

      formData.append('zipcode', this.state.zipcode);
      formData.append('companyLogo', this.state.companyLogo);
      formData.append('prev_companyLogo', this.state.prev_companyLogo);
      formData.append('uploadVideo', this.state.uploadVideo);
      formData.append('prev_uploadVideo', this.state.prev_uploadVideo);

      formData.append('instagramLink', this.state.instagramLink);
      formData.append('facebookLink', this.state.facebookLink);
      formData.append('linkedInLink', this.state.linkedInLink);

      formData.append('youTubeLik', this.state.youTubeLik);
      formData.append('twitterLink', this.state.twitterLink);
      formData.append('businessStartedDate', this.state.businessStartedDate);

      formData.append('accolades', JSON.stringify(this.state.accolades));
      formData.append('multipleaccolades', this.state.multipleaccolades);
      formData.append('status', this.state.status);
      formData.append('deleted', 0);
      formData.append('media', JSON.stringify(this.state.media));
      formData.append('multipleValues', this.state.multipleValues);
      formData.append('leadershipemail', this.state.leadershipemail);
      formData.append('leadershiptitle', this.state.leadershiptitle);

      formData.append('membership', JSON.stringify(this.state.membership));
      formData.append('multiplemembership', this.state.multiplemembership);
      // formData.append('vendor_business_model', this.state.vendor_business_model);
      formData.append('uncheckedids', JSON.stringify(uncheckedids));

      formData.append('muslim_owned_business', this.state.muslim_owned_business);
      formData.append('memphis_chamber_mmember', this.state.memphis_chamber_mmember);

      formData.append('subindustries', JSON.stringify(this.state.subindustries));
      formData.append('vendor_product_needs', this.state.vendor_product_needs);
      // formData.append('vendor_product_needs', 'testing');
// newly added fields
      formData.append('years_in_business',this.state.years_in_business);
      formData.append('no_of_jobs_currently_ongoing', this.state.no_of_jobs_currently_ongoing);
      formData.append('order_size_capable', this.state.order_size_capable);
      formData.append('size_of_largest_order', this.state.size_of_largest_order);
      formData.append('avg_fulfillment_time', this.state.avg_fulfillment_time);
      formData.append('exp_in_category', this.state.exp_in_category);
      formData.append('mwbe_certification_category', this.state.mwbe_certification_category);
      formData.append('desc_for_wrk_exp', this.state.desc_for_wrk_exp);
      formData.append('desc_for_wrk_exp_in_category', this.state.desc_for_wrk_exp_in_category);
      formData.append('references', this.state.references);


      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };
      this.props.updateVendorProfile(formData, config, this.props.history);
    }
  };
  componentDidMount() {
    this.props.handlevendordashboard('');
    if (this.props.auth.isAuthenticated) {
      //	console.log(this.props.auth.user._id);
      this.props.getVendorProfile(this.props.auth.user._id);
    } else {
      this.props.history.push(AppConstants.STAGRFP + '/');
    }

    //Get Industries data
    fetch(AppConstants.API + '/industries/getallindustries')
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        if (data.Status == 'Success') {
          this.setState({ industriesdata: data.industriesdata });
        } else {
          this.setState({ industriesdata: '' });
        }
      });
    //Get companies data
    fetch(AppConstants.API + '/companies/getallcompanies')
      .then((response) => response.json())
      .then((data) => {
        //console.log(" companies ", data);
        if (data.Status == 'Success') {
          this.setState({ companiesdata: data.companiesdata });
        } else {
          this.setState({ companiesdata: '' });
        }
      });
    //Get Certification data
    fetch(AppConstants.API + '/certifications/getallcertifications')
      .then((response) => response.json())
      .then((data) => {
        //console.log(data);
        if (data.Status == 'Success') {
          this.setState({ certificationsdata: data.certificationsdata });
        } else {
          this.setState({ certificationsdata: '' });
        }
      });
    //Get naic codes
    fetch(AppConstants.API + '/users/getallnaiccodes')
      .then((response) => response.json())
      .then((data) => {
        //console.log(" naic codes ", data);
        if (data.Status == 'Success') {
          this.setState({ naiccodes: data.naiccodes });
        } else {
          this.setState({ naiccodes: '' });
        }
      });

    //Get vendors details
    fetch(AppConstants.API + '/vendors/getvendordetails/' + this.props.auth.user._id)
      .then((response) => response.json())
      .then((data) => {
        if (data.Status == 'Success') {
          console.log(data);
          console.log("membership", data.getvendorsdata.membership);
          //console.log(data.getvendorsdata.businessStartedDate);
          let today;
          if (
            data.getvendorsdata.businessStartedDate != '' &&
            data.getvendorsdata.businessStartedDate != 'undefined' &&
            data.getvendorsdata.businessStartedDate != undefined
          ) {
            today = new Date(data.getvendorsdata.businessStartedDate);
          } else {
            today = new Date();
          }
          //const today = moment(data.getvendorsdata.businessStartedDate).format('MM/DD/YYYY');
          var multidocs = [];
          var multidocids = [];
          //console.log(data.getvendorsdata.certificationfiles.length);
          if (data.getvendorsdata.certificationfiles.length > 0) {
            var allcerificationdata = data.getvendorsdata.certificationfiles;
            allcerificationdata.map((alldata) => {
              var idsObj = {};
              multidocids.push(alldata.certificationid);
              multidocs.push(alldata.certificationurl);
            });
            //console.log(multidocids);
            this.setState({
              multidocids: multidocids,
              multidocs: multidocs,
            });
          }
          let instagramLink;
          if (data.getvendorsdata.instagramLink != 'undefined' && data.getvendorsdata.instagramLink != '') {
            instagramLink = data.getvendorsdata.instagramLink;
          } else {
            instagramLink = '';
          }

          let subcat;
          if (data.getvendorsdata.subindustries != undefined || data.getvendorsdata.subindustries != null) {
            subcat = data.getvendorsdata.subindustries;
          } else {
            subcat = [];
          }
          let membershipVar;
          if (data.getvendorsdata.membership != undefined || data.getvendorsdata.membership != null) {
            membershipVar = data.getvendorsdata.membership;
          } else {
            membershipVar = [];
          }

          let mcb;
          if (data.getvendorsdata.memphis_chamber_mmember == undefined) {
            mcb = this.state.memphis_chamber_mmember;
          } else {
            mcb = data.getvendorsdata.memphis_chamber_mmember;
          }
          let mob;
          if (data.getvendorsdata.muslim_owned_business == undefined) {
            mob = this.state.muslim_owned_business;
          } else {
            mob = data.getvendorsdata.muslim_owned_business;
          }

          this.setState({
            id: this.props.auth.user._id,
            vendor_id: data.getvendorsdata._id,
            vendor_company_name: data.getvendorsdata.vendor_company_name,
            vendor_company_email: data.getvendorsdata.vendor_company_email,
            vendor_company_type: data.getvendorsdata.vendor_company_type,
            vendor_company_industrey: data.getvendorsdata.vendor_company_industrey,
            vendor_company_address: data.getvendorsdata.vendor_company_address,
            vendor_company_address2: data.getvendorsdata.vendor_company_address2,
            vendor_company_phoneno: data.getvendorsdata.vendor_company_phoneno,
            vendor_company_website: data.getvendorsdata.vendor_company_website,
            vendor_primary_contact_firstname: data.getvendorsdata.vendor_primary_contact_firstname,
            vendor_primary_contact_lastname: data.getvendorsdata.vendor_primary_contact_lastname,
            vendor_primary_contact_middlename: data.getvendorsdata.vendor_primary_contact_middlename,
            vendor_primary_contact_title: data.getvendorsdata.vendor_primary_contact_title,
            vendor_primary_contact_phoneno: data.getvendorsdata.vendor_primary_contact_phoneno,
            vendor_primary_email: data.getvendorsdata.vendor_primary_email,
            vendor_primary_cellno: data.getvendorsdata.vendor_primary_cellno,
            vendor_primary_officeno: data.getvendorsdata.vendor_primary_officeno,
            vendor_primary_department: data.getvendorsdata.vendor_primary_department,
            vendor_product_needs: data.getvendorsdata.vendor_product_needs,
            certificationsandmembership: data.getvendorsdata.certificationsandmembership,
            //naic_code: data.getvendorsdata.naic_code,
            naic_code: data.getvendorsdata.selected_naic_codes,
            descriptionofproductsandservices: data.getvendorsdata.descriptionofproductsandservices,
            companyleadership: data.getvendorsdata.companyleadership,
            department_min_revenue: data.getvendorsdata.department_min_revenue,
            department_max_revenue: data.getvendorsdata.department_max_revenue,
            employees: data.getvendorsdata.employees,
            locationsOfOffices: data.getvendorsdata.locationsOfOffices,
            latitude: data.getvendorsdata.latitude,
            longitude: data.getvendorsdata.longitude,
            country: data.getvendorsdata.country,
            state: data.getvendorsdata.state,
            city: data.getvendorsdata.city,
            zipcode: data.getvendorsdata.zipcode,
            companyLogo: data.getvendorsdata.companyLogo,
            view_companyLogo: data.getvendorsdata.companyLogo || nologo,
            prev_companyLogo: data.getvendorsdata.companyLogo,
            uploadVideo: data.getvendorsdata.uploadVideo,
            view_uploadVideo: data.getvendorsdata.uploadVideo,
            prev_uploadVideo: data.getvendorsdata.uploadVideo,
            instagramLink: instagramLink,
            facebookLink: data.getvendorsdata.facebookLink,
            linkedInLink: data.getvendorsdata.linkedInLink,
            youTubeLik: data.getvendorsdata.youTubeLik,
            twitterLink: data.getvendorsdata.twitterLink,
            businessStartedDate: today,
            //businessStartedDate: new Date(data.getvendorsdata.businessStartedDate),
            status: data.getvendorsdata.status,
            deleted: 0,
            accolades: data.getvendorsdata.accolades,
            multipleaccolades: data.getvendorsdata.accolades[0],
            multipleValues: data.getvendorsdata.media[0],
            media: data.getvendorsdata.media,
            leadershiptitle: data.getvendorsdata.leadershiptitle,
            leadershipemail: data.getvendorsdata.leadershipemail,
            membership: membershipVar,
            multiplemembership: membershipVar[0],
            // data.getvendorsdata.membership == null ? "n/a" : data.getvendorsdata.membership[0],
            // vendor_business_model: data.getvendorsdata.vendor_business_model,
            certifications: data.getvendorsdata.certifications,
            allcerificationdata: data.getvendorsdata.certificationfiles,
            muslim_owned_business: mob,
            memphis_chamber_mmember: mcb,
            countMembership: membershipVar.length,
            countMedia: data.getvendorsdata.media.length,
            countaccolades: data.getvendorsdata.accolades.length,
            subindustries: subcat,
            //newly added fields
            years_in_business: data.getvendorsdata.years_in_business,
            no_of_jobs_currently_ongoing: data.getvendorsdata.no_of_jobs_currently_ongoing,
            order_size_capable: data.getvendorsdata.order_size_capable,
            size_of_largest_order: data.getvendorsdata.size_of_largest_order,
            avg_fulfillment_time: data.getvendorsdata.avg_fulfillment_time,
            exp_in_category: data.getvendorsdata.exp_in_category,
            mwbe_certification_category: data.getvendorsdata.mwbe_certification_category,
            desc_for_wrk_exp: data.getvendorsdata.desc_for_wrk_exp,
            desc_for_wrk_exp_in_category: data.getvendorsdata.desc_for_wrk_exp_in_category,
            references: data.getvendorsdata.references,
          });
          this.handleIndustryChange();
        } else {
          this.setState({ getcustomersdata: '' });
        }
      });
  }
  handleSubCatChange(e) {
    const subindustries = this.state.subindustries;
    let index;
    // check if the check box is checked or unchecked
    if (e.target.checked) {
      // add the numerical value of the checkbox to options array
      subindustries.push(e.target.value);
    } else {
      // or remove the value from the unchecked checkbox from the array
      index = subindustries.indexOf(e.target.value);
      subindustries.splice(index, 1);
    }
    //console.log(subindustries);
    // update the state with the new array of options
    this.setState({ subindustries: subindustries });
  }
  componentWillReceiveProps(nextProps) {
    //console.log(nextProps);
    if (nextProps.auth.isAuthenticated == true && nextProps.auth.user.type == 'vendor') {
      this.setState({
        id: nextProps.auth.user._id,
        //successerrMsg:nextProps.auth.user.msg
      });
      if (nextProps.auth.user.Status == 'Success') {
        this.setState({
          successerrMsg: nextProps.auth.user.msg,
        });
      }
    }
    if (nextProps.errors == 'Vendor updated failed') {
      this.setState({ errMsg: nextProps.errors });
      this.setState({ loading: '' });
      setTimeout((e) => {
        this.setState({
          errMsg: '',
        });
      }, 2000);
    }
  }
  render() {
    let { imagePreviewUrl } = this.state;
    let $imagePreview = null;
    if (imagePreviewUrl) {
      $imagePreview = (
        <div className="hhRbZu">
          <img src={imagePreviewUrl} />
        </div>
      );
    } else {
      $imagePreview = (
        <div className="hhRbZu">
          <img src={this.state.view_companyLogo} />
        </div>
      );
    }
    var k = 1;
    const checkboxItems = this.state.certificationsdata.map((planet, key) => {
      return (
        <Col md="12">
          <div class="form-group form-check">
            <input
              type="checkbox"
              name="certifications"
              onChange={this.handleInputChangeFund}
              id={planet._id}
              checked={this.state.certifications.includes(planet._id) || ''}
              value={planet._id}
              onClick={this.handleClick}
            />
            <label for={planet._id}>{planet.certification_name}</label>

            <div
              className="cert-upload profile-cert-upload"
              style={this.state.multidocids.includes(planet._id) ? { display: 'block' } : { display: 'none' }}
              id={`${'cert_'}${planet._id}`}
            >
              <Row>
                <div class="file-field input-field fileupload col-md-7">
                  <div class="filebtn">
                    <span>
                      <i class="fa fa-upload" aria-hidden="true" />
                      Select File
                    </span>
                    <input
                      type="file"
                      name="certification_documents"
                      onChange={this.handleFileChange}
                      id={`${'cert_'}${planet._id}`}
                    />
                  </div>

                  <div class="file-path-wrapper">
                    <input class="file-path validate" type="text" />
                  </div>
                  <em>Please upload any certification document.</em>
                </div>
                <div className="col-md-12">
                  {(this.state.allcerificationdata && this.state.allcerificationdata.length) > 0
                    ? this.state.allcerificationdata.map((value, index) => {
                        return (
                          <div key={index}>
                            {value.certificationid == planet._id ? (
                              <div className="filebtn">
                                <a href={value.certificationurl} target="_blank">
                                  <i class="fa fa-file-pdf-o" aria-hidden="true" /> Document {k++}
                                </a>
                              </div>
                            ) : (
                              ''
                            )}
                          </div>
                        );
                      })
                    : ''}
                </div>
              </Row>
            </div>
            {/* {(this.state.allcerificationdata && this.state.allcerificationdata.length) > 0 ? this.state.allcerificationdata.map((value, index) => {
                    return <div key={index}>
                    {value.certificationid ==  planet._id? <span><a href={value.certificationurl} target="_blank">{value.certificationurl}</a></span> : ''}                       
                    </div>
              }) : ''}*/}
          </div>
        </Col>
      );
    });

    const subcatcheckboxItems =
      this.state.vendor_company_industrey != ''
        ? this.state.subindustriesdata.map((planet) => {
            // console.log('planet', planet);
            return (
              <div className="form-group form-check inline cst-c-block cst-c-block-SM">
                <input
                  type="checkbox"
                  name="subindustries"
                  value={planet.subcatid}
                  onChange={this.handleSubCatChange}
                  checked={this.state.subindustries.includes(planet.subcatid) || ''}
                  class="form-control"
                  id={planet.subcatid}
                />
                <label for={planet.subcatid}>{planet.subindustryname}</label>
              </div>
            );
          })
        : null;

    if (localStorage.jwtToken != undefined) {
      return <div>
          <ReactTitle title="Vendor Profile" />
          <div className="inner-banner" />
          <div className="inner-content  rfpviewdetail">
            <Container>
              <form name="vendorprofile" onSubmit={this.handleSubmit}>
                <div className="white-bg pad50">
                  <Row className="justify-content-center">
                    <Col md="10">
                      <h1 className="color-yellow">
                        {this.state.vendor_company_name}
                      </h1>
                      <Row className="vendor-tab-list">
                        <span className="col-md-3">
                          <Button className="basic-info" onClick={this.handleBasic} disabled={!this.state.info1}>
                            Basic Information
                          </Button>
                        </span>
                        <span className="col-md-3">
                          <Button className="contact-info" onClick={this.handleContactInfo} disabled={!this.state.info2}>
                            Contact Information
                          </Button>
                        </span>
                        {/* <span className="col-md-3">
                          <Button
                            className="certificate-info "
                            onClick={this.handleCertInfo}
                            disabled={!this.state.info3}
                          >
                            Certifications & Memberships
                          </Button>
                        </span> */}
                        <span className="col-md-3">
                          <Button className="social-info" onClick={this.handleSocialInfo} disabled={!this.state.info4}>
                            Social Links
                          </Button>
                        </span>
                      </Row>
                      <div className="company-edit-form">
                        {this.state.showBasic ? <div className="vendot-info-detail">
                            <div className="form-field form-block form-block ">
                              <div className="avatar-block">
                                <input type="hidden" name="prev_companyLogo" value={this.state.prev_companyLogo} />

                                <input type="file" name="companyLogo" onChange={this.handleInputFileChange} />
                                {$imagePreview}
                                <span>Logo</span>

                                {/*  <input type="file" name="companyLogo"  onChange={ this.handleInputFileChange } />
                                                <span color="muted">
                                                Please upload logo.
                                                </span>
  <img src={this.state.view_companyLogo} /> <AvatarUploader
                                                    size={150}
                                                    uploadURL="../images/"
                                                    fileType={"image/png"}/>
                                                    <span>Upload Logo</span> */}
                              </div>
                              <span className="error avatar-error" style={{ color: "red" }}>
                                {this.state.errors["companyLogo"]}
                              </span>
                            </div>
                            <div className="form-block">
                              <Row>
                                <div className="form-field col-md-6">
                                  <MDBInput label="Name *" type="text" name="vendor_company_name" onChange={this.handleInputChange} value={this.state.vendor_company_name} />
                                  <span className="error" style={{ color: "red" }}>
                                    {this.state.errors["vendor_company_name"]}
                                  </span>
                                </div>
                                <div className="form-field col-md-6">
                                  <MDBInput label="Email *" readonly type="text" name="vendor_company_email" value={this.state.vendor_company_email} onChange={this.handleInputChange} readOnly />
                                  <span className="error" style={{ color: "red" }}>
                                    {this.state.errors["vendor_company_email"]}
                                  </span>
                                </div>
                              </Row>
                              <Row>
                                <div className="form-field col-md-6">
                                  {/* <MDBInput label="Type" /> */}
                                  <div className="md-form">
                                    <select class="cs-select cs-skin-elastic form-control" name="vendor_company_type" value={this.state.vendor_company_type} onChange={this.handleInputChange}>
                                      <option value="">
                                        Company Type *
                                      </option>
                                      {this.state.companiesdata.map(
                                        (companies, index) => {
                                          if (
                                            companies.status == "Active"
                                          ) {
                                            return (
                                              <option
                                                key={index}
                                                value={companies._id}
                                              >
                                                {companies.companyname}
                                              </option>
                                            );
                                          }
                                        }
                                      )}
                                    </select>
                                    {this.state.vendor_company_type ? <label className="active">
                                        Company Type *
                                      </label> : <label />}
                                  </div>
                                  <span className="error" style={{ color: "red" }}>
                                    {this.state.errors["vendor_company_type"]}
                                  </span>
                                </div>
                                <div className="form-field col-md-6">
                                  {/* <MDBInput label="Industry " /> */}
                                  <div className="md-form">
                                    <select class="cs-select cs-skin-elastic form-control " name="vendor_company_industrey" value={this.state.vendor_company_industrey} onChange={this.handleIndustryChange}>
                                      <option value="">
                                        Industry Type *
                                      </option>
                                      {this.state.industriesdata.map(
                                        (industries, index) => {
                                          if (
                                            industries.status == "Active"
                                          ) {
                                            return (
                                              <option
                                                key={index}
                                                value={industries._id}
                                              >
                                                {industries.industryname}
                                              </option>
                                            );
                                          }
                                        }
                                      )}
                                    </select>
                                    {this.state.vendor_company_industrey ? <label className="active">
                                        Industry Type *
                                      </label> : <label />}
                                  </div>
                                  <span className="error" style={{ color: "red" }}>
                                    {this.state.errors["vendor_company_industrey"]}
                                  </span>
                                </div>
                              </Row>
                              {/* <Row>
                                <div className="form-field col-md-12">
                                  <div>{subcatcheckboxItems}</div>
                                </div>
                              </Row> */}
                              <Row>
                                <div className="form-field col-md-6">
                                  <MDBInput label="Head quarters Address *" type="text" name="vendor_company_address2" value={this.state.vendor_company_address2} onChange={this.handleInputChange} />
                                  <span className="error" style={{ color: "red" }}>
                                    {this.state.errors["vendor_company_address2"]}
                                  </span>
                                </div>
                                <div className="form-field col-md-6">
                                  <MDBInput label="Address 1 *" type="text" name="vendor_company_address" value={this.state.vendor_company_address} onChange={this.handleInputChange} />
                                  <span className="error" style={{ color: "red" }}>
                                    {this.state.errors["vendor_company_address"]}
                                  </span>
                                </div>
                              </Row>
                              <Row>
                                <div className="form-field col-md-6">
                                  <MDBInput label="City *" type="text" name="city" value={this.state.city} onChange={this.handleInputChange} />
                                  <span className="error" style={{ color: "red" }}>
                                    {this.state.errors["city"]}
                                  </span>
                                </div>
                                <div className="form-field col-md-6">
                                  <div className="md-form">
                                    <select class="cs-select cs-skin-elastic form-control" name="state" value={this.state.state} onChange={this.handleInputChange}>
                                      <option value="">State *</option>
                                      <option value="Alabama">
                                        Alabama
                                      </option>
                                      <option value="Alaska">
                                        Alaska
                                      </option>
                                      <option value="Arizona">
                                        Arizona
                                      </option>
                                      <option value="Arkansas">
                                        Arkansas
                                      </option>
                                      <option value="California">
                                        California
                                      </option>
                                      <option value="Colorado">
                                        Colorado
                                      </option>
                                      <option value="Connecticut">
                                        Connecticut
                                      </option>
                                      <option value="Delaware">
                                        Delaware
                                      </option>
                                      <option value="District Of Columbia">
                                        District Of Columbia
                                      </option>
                                      <option value="Florida">
                                        Florida
                                      </option>
                                      <option value="Georgia">
                                        Georgia
                                      </option>
                                      <option value="Hawaii">
                                        Hawaii
                                      </option>
                                      <option value="Idaho">Idaho</option>
                                      <option value="Illinois">
                                        Illinois
                                      </option>
                                      <option value="Indiana">
                                        Indiana
                                      </option>
                                      <option value="Iowa">Iowa</option>
                                      <option value="Kansas">
                                        Kansas
                                      </option>
                                      <option value="Kentucky">
                                        Kentucky
                                      </option>
                                      <option value="Louisiana">
                                        Louisiana
                                      </option>
                                      <option value="Maine">Maine</option>
                                      <option value="Maryland">
                                        Maryland
                                      </option>
                                      <option value="Massachusetts">
                                        Massachusetts
                                      </option>
                                      <option value="Michigan">
                                        Michigan
                                      </option>
                                      <option value="Minnesota">
                                        Minnesota
                                      </option>
                                      <option value="Mississippi">
                                        Mississippi
                                      </option>
                                      <option value="Missouri">
                                        Missouri
                                      </option>
                                      <option value="Montana">
                                        Montana
                                      </option>
                                      <option value="Nebraska">
                                        Nebraska
                                      </option>
                                      <option value="Nevada">
                                        Nevada
                                      </option>
                                      <option value="New Hampshire">
                                        New Hampshire
                                      </option>
                                      <option value="New Jersey">
                                        New Jersey
                                      </option>
                                      <option value="New Mexico">
                                        New Mexico
                                      </option>
                                      <option value="New York">
                                        New York
                                      </option>
                                      <option value="North Carolina">
                                        North Carolina
                                      </option>
                                      <option value="North Dakota">
                                        North Dakota
                                      </option>
                                      <option value="Ohio">Ohio</option>
                                      <option value="Oklahoma">
                                        Oklahoma
                                      </option>
                                      <option value="Oregon">
                                        Oregon
                                      </option>
                                      <option value="Pennsylvania">
                                        Pennsylvania
                                      </option>
                                      <option value="Rhode Island">
                                        Rhode Island
                                      </option>
                                      <option value="South Carolina">
                                        South Carolina
                                      </option>
                                      <option value="South Dakota">
                                        South Dakota
                                      </option>
                                      <option value="Tennessee">
                                        Tennessee
                                      </option>
                                      <option value="Texas">Texas</option>
                                      <option value="Utah">Utah</option>
                                      <option value="Vermont">
                                        Vermont
                                      </option>
                                      <option value="Virginia">
                                        Virginia
                                      </option>
                                      <option value="Washington">
                                        Washington
                                      </option>
                                      <option value="West Virginia">
                                        West Virginia
                                      </option>
                                      <option value="Wisconsin">
                                        Wisconsin
                                      </option>
                                      <option value="Wyoming">
                                        Wyoming
                                      </option>
                                    </select>
                                    {this.state.state ? <label className="active">
                                        State *
                                      </label> : <label />}
                                  </div>
                                  <span className="error" style={{ color: "red" }}>
                                    {this.state.errors["state"]}
                                  </span>
                                  {/* <MDBInput
                                    label="State *"
                                    type="text"
                                    name="state"
                                    value={this.state.state}
                                    onChange={this.handleInputChange}
                                  />
                                  <span
                                    className="error"
                                    style={{ color: "red" }}
                                  >
                                    {this.state.errors["state"]}
                                  </span> */}
                                </div>
                                {/* <div className="form-field col-md-3">
                                                            <MDBInput label="State" type="text" name="city" value={this.state.state} onChange={ this.handleInputChange } />
                                                        <span className="error" style={{color: 'red'}}>{this.state.errors["state"]}</span>
                                                        </div> */}
                                <div className="form-field col-md-6">
                                  <MDBInput label="Zip code *" type="text" name="zipcode" value={this.state.zipcode} onChange={this.onChangeNumberkey} />
                                  <span className="error" style={{ color: "red" }}>
                                    {this.state.errors["zipcode"]}
                                  </span>
                                </div>
                                <div className="form-field col-md-6">
                                  <MDBInput label="Product Needs *" type="text" name="vendor_product_needs" value={this.state.vendor_product_needs} onChange={this.handleInputChange} />
                                  <span className="error" style={{ color: "red" }}>
                                    {this.state.errors["vendor_product_needs"]}
                                  </span>
                                </div>
                              </Row>

                              {/* <Row className="list-view">
                                <div className="form-field col-md-6">
                                  <h6>What is your Company Business Model? *</h6>
                                </div>
                                <div className="form-field col-md-6">
                                  <div>
                                    <div class="form-group radio-block form-check inline">
                                      <input
                                        type="radio"
                                        name="vendor_business_model"
                                        value="B2B"
                                        id="B2B"
                                        checked={this.state.vendor_business_model == 'B2B'}
                                        onChange={this.handleVendorModelChange}
                                      />
                                      <label for="B2B"> B2B &nbsp; &nbsp;</label>
                                    </div>
                                    <div class="form-group radio-block form-check inline">
                                      <input
                                        type="radio"
                                        name="vendor_business_model"
                                        checked={this.state.vendor_business_model == 'B2C'}
                                        value="B2C"
                                        id="B2C"
                                        onChange={this.handleVendorModelChange}
                                      />
                                      <label for="B2C"> B2C &nbsp; &nbsp;</label>
                                    </div>
                                    <div class="form-group radio-block form-check inline">
                                      <input
                                        type="radio"
                                        name="vendor_business_model"
                                        checked={this.state.vendor_business_model == 'Both'}
                                        value="Both"
                                        id="Both"
                                        onChange={this.handleVendorModelChange}
                                      />
                                      <label for="Both"> Both </label>
                                    </div>
                                  </div>
                                  <span className="error" style={{ color: 'red' }}>
                                    {this.state.errors['vendor_business_model']}
                                  </span>
                                </div>
                              </Row> */}

                              <Row>
                                <div className="form-field col-md-6">
                                  <MDBInput label="Phone No" type="text" name="vendor_company_phoneno" value={this.state.vendor_company_phoneno} onChange={this.handlePhoneChange} maxlength="15" />
                                  {/* <span className="error" style={{color: 'red'}}>{this.state.errors["vendor_company_phoneno"]}</span>  */}
                                  {/* <MDBInput label="Phone Number" type="text" name="vendor_company_phoneno" maxlength="15" value={this.state.vendor_company_phoneno} onChange={ this.handleInputChange } /> 
                                                        
                                                        <span className="error" style={{color: 'red'}}>{this.state.errors["vendor_company_phoneno"]}</span>  */}
                                </div>
                                <div className="form-field col-md-6">
                                  <div className="md-form graph-field">
                                    <div class="input-field phoneblock">
                                      {/* <DayPickerInput
                                        formatDate={formatDate}
                                        value={this.state.businessStartedDate}
                                        onDayChange={this.handleDayChange}
                                        placeholder="MM/DD/YYYY"
                                        name="businessStartedDate"
                                        class="form-control"
                                      /> */}
                                      {/* <DatePicker
                                        selected={this.state.businessStartedDate}
                                        onChange={this.handleDayChange}
                                        placeholder="MM/DD/YYYY"
                                        name="businessStartedDate"
                                        className="form-control"
                                        // onChangeRaw={
                                        //   (e) => e.preventDefault() // value={this.state.businessStartedDate}
                                        // }
                                      /> */}
                                      {this.state.businessStartedDate ? <label className="active">
                                          Business Started Date
                                        </label> : <label>
                                          Business Started Date
                                        </label>}
                                      <DatePicker onChange={this.handleDayChange} value={this.state.businessStartedDate} name="businessStartedDate" className="form-control date-picker-react" />

                                      {/* <i class="fa fa-calendar" aria-hidden="true" /> */}
                                      {/* {this.state.businessStartedDate ? (
                                        <label className="active">Business Started Date</label>
                                      ) : (
                                        <label>Business Started Date</label>
                                      )} */}
                                    </div>
                                  </div>
                                </div>
                              </Row>
                              <Row>
                                <div className="form-field col-md-6">
                                  {/* <MDBInput label="Employees" /> */}
                                  <div className="md-form">
                                    {/* <select class="cs-select cs-skin-elastic form-control" name="employees" value={this.state.employees} onChange={ this.handleInputChange }>
                                                            <option value="">No. of Employees</option>
                                                            <option value="0 - 100">0 - 100</option>
                                                            <option value="100 - 200">100 - 200</option>     
                                                            <option value="200 - 300">200 - 300</option>      
                                                            <option value="300 - 400">300 - 400</option>      
                                                            <option value="400 - 500">400 - 500</option>         
                                                            <option value="Above 500">Above 500</option>  
                                                        </select> */}
                                    <MDBInput label="No. of Employees *" type="text" name="employees" value={this.state.employees} onChange={this.handleInputChange} />
                                   
                                   
                                    {/* {this.state.employees ? <label className="active">No. of Employees</label> : <label></label>} */}
                                  </div>
                                  <span className="error" style={{ color: "red" }}>
                                    {this.state.errors["employees"]}
                                  </span>
                                </div>
                                <div className="form-field col-md-6">
                                  {/* <MDBInput label="Revenue Range" /> */}
                                  <div className="md-form">
                                    <select class="cs-select cs-skin-elastic form-control" name="department_max_revenue" value={this.state.department_max_revenue} onChange={this.handleInputChange}>
                                      <option value="">
                                        Revenue Range
                                      </option>
                                      {/* <option value="$0 - $20,000">$0 - $20,000</option>
                                                            <option value="$20,000 - $50,000">$20,000 - $50,000</option>
                                                            <option value="Above $50,000">Above $50,000</option> */}
                                      <option value="$1 - $1,000,000">
                                        $1 - $1,000,000
                                      </option>
                                      <option value="$1,000,001 - $5,000,000">
                                        $1,000,001 - $5,000,000
                                      </option>
                                      <option value="$5,000,001 - $20,000,000">
                                        $5,000,001 - $20,000,000
                                      </option>
                                      <option value="$20,000,001 - $50,000,000">
                                        $20,000,001 - $50,000,000
                                      </option>
                                      <option value="$50,000,001 - $100,000,000">
                                        $50,000,001 - $100,000,000
                                      </option>
                                      <option value="$100,000,001 - $500,000,000">
                                        $100,000,001 - $500,000,000
                                      </option>
                                      <option value="$500,000,001 or Greater">
                                        $500,000,001 or Greater
                                      </option>
                                    </select>
                                    {this.state.department_max_revenue ? <label className="active">
                                        Revenue Range
                                      </label> : <label />}
                                  </div>
                                </div>
                              </Row>
                              <Row>
                                <div className="form-field col-md-6">
                                  <MDBInput label="years In Business *" type="text" name="years_in_business" value={this.state.years_in_business} onChange={this.handleInputChange}
                                   />
                                    <span className="error" style={{ color: "red" }}>
                                    {this.state.errors["years_in_business"]}
                                  </span>
                                </div>
                                <div className="form-field col-md-6">
                                  <MDBInput label="No of jobs currently ongoing *" type="text" name="no_of_jobs_currently_ongoing" onChange={this.handleInputChange} value={this.state.no_of_jobs_currently_ongoing} />
                                  <span className="error" style={{ color: "red" }}>
                                    {this.state.errors["no_of_jobs_currently_ongoing"]}
                                  </span>
                                </div>
                              </Row>
                              <Row>
                                <div className="form-field col-md-6">
                                  <MDBInput label="Max job/ order size capable *" type="text" name="order_size_capable" value={this.state.order_size_capable} onChange={this.handleInputChange} />
                                  <span className="error" style={{ color: "red" }}>
                                    {this.state.errors["order_size_capable"]}
                                  </span>
                                </div>
                                <div className="form-field col-md-6">
                                  <MDBInput label="Size of largest order *" type="text" name="size_of_largest_order" onChange={this.handleInputChange} value={this.state.size_of_largest_order} />
                                  <span className="error" style={{ color: "red" }}>
                                    {this.state.errors["size_of_largest_order"]}
                                  </span>
                                </div>
                              </Row>
                              <Row>
                                <div className="form-field col-md-6">
                                  <MDBInput label="Experience in category *" type="text" name="exp_in_category" onChange={this.handleInputChange} value={this.state.exp_in_category} />
                                  <span className="error" style={{ color: "red" }}>
                                    {this.state.errors["size_of_largest_order"]}
                                  </span>
                                </div>
                                <div className="form-field col-md-6">
                                  <MDBInput label="Average fullfillment time *" type="text" name="avg_fulfillment_time" value={this.state.avg_fulfillment_time} onChange={this.handleInputChange} />
                                  <span className="error" style={{ color: "red" }}>
                                    {this.state.errors["avg_fulfillment_time"]}
                                  </span>
                                </div>
                              </Row>
                              <Row>
                                <div className="form-field col-md-6">
                                  <MDBInput label="MWBE certification category *" type="text" name="mwbe_certification_category" value={this.state.mwbe_certification_category} onChange={this.handleInputChange} />
                                  <span className="error" style={{ color: "red" }}>
                                    {this.state.errors["mwbe_certification_category"]}
                                  </span>
                                </div>
                                <div className="form-field col-md-6">
                                  <MDBInput label="References *" type="text" name="references" onChange={this.handleInputChange} value={this.state.references} />
                                  <span className="error" style={{ color: "red" }}>
                                    {this.state.errors["references"]}
                                  </span>
                                </div>
                              </Row>
                              <Row>
                                <div className="form-field col-md-12">
                                  <MDBInput type="textarea" label="Have you ever been found deficient in work experience or schedule completion *" rows="2" name="desc_for_wrk_exp" onChange={this.handleInputChange} value={this.state.desc_for_wrk_exp} />
                                  <span className="error" style={{ color: "red" }}>
                                    {this.state.errors["desc_for_wrk_exp"]}
                                  </span>
                                </div>
                              </Row>
                              <Row>
                                <div className="form-field col-md-12">
                                  <MDBInput type="textarea" label="Do you have stayed experience in category, would expand for number of jobs completed in each category *" rows="2" name="desc_for_wrk_exp_in_category" onChange={this.handleInputChange} value={this.state.desc_for_wrk_exp_in_category} />
                                  <span className="error" style={{ color: "red" }}>
                                    {this.state.errors["desc_for_wrk_exp_in_category"]}
                                  </span>
                                </div>
                              </Row>
                              <Row>
                                <div className="form-field col-md-12 website-field">
                                  <MDBInput label="Website (Add URL as https://**)" name="vendor_company_website" value={this.state.vendor_company_website} onChange={this.handleInputChange} />
                                  <span className="error" style={{ color: "red" }}>
                                    {this.state.errors["vendor_company_website"]}
                                  </span>
                                </div>
                              </Row>
                              <Row>
                                <div className="form-field col-md-12 form-tags">
                                  <div className="md-form">
                                    <p>NAICS Codes *</p>
                                    <Typeahead clearButton label={"NAICS Codes *" //placeholder="Add NAICS/NIGP Codes"
                                      } labelKey={(option) => `${option.NAICS_Description}` + ` (${option.NAICS})`} multiple options={this.state.naiccodes} name="naic_code" value={this.state.naiccodes} onChange={(selected) => {
                                        this.setState({
                                          naic_code: selected,
                                        });
                                      }} class="form-control" selected={this.state.naic_code} />
                                    {/* {this.state.naic_code.length >0 ? <label className="active">NAICS Codes *</label> : <label>NAICS Codes *</label>} */}
                                  </div>
                                  <span className="error" style={{ color: "red" }}>
                                    {this.state.errors["naic_code"]}
                                  </span>
                                </div>
                              </Row>
                              <Row>
                                <h6 className="col-md-12 vi-title btm-title">
                                  Company Leadership
                                </h6>
                                <div className="form-field col-md-6">
                                  <MDBInput label="Name" name="companyleadership" value={this.state.companyleadership} onChange={this.handleInputChange} />
                                </div>
                                <div className="form-field col-md-6">
                                  <MDBInput label="Title" name="leadershiptitle" value={this.state.leadershiptitle} onChange={this.handleInputChange} />
                                </div>
                              </Row>
                              <Row>
                                <div className="form-field col-md-6">
                                  <MDBInput label="Email" name="leadershipemail" value={this.state.leadershipemail} onChange={this.handleInputChange} />
                                </div>
                                <div className="form-field col-md-6">
                                  {/* <div className="md-form">
                                                    <div className="input-field phoneblock">

                                                    <NumberFormat
                                                    format="(###) ###-####" mask=""
                                                    name="vendor_primary_contact_phoneno"
                                                    onChange={this.handleInputChange}
                                                    value={this.state.vendor_primary_contact_phoneno} class="form-control"/>
                                                    {this.state.vendor_primary_contact_phoneno ? <label className="active">Phone Number</label> : <label>Phone Number</label>}
                                                       <span className="error" style={{color: 'red'}}>{this.state.errors["vendor_primary_contact_phoneno"]}</span>
                                                    </div>
                                                    </div> */}
                                  <MDBInput label="Phone Number" type="text" name="vendor_primary_contact_phoneno" value={this.state.vendor_primary_contact_phoneno} onChange={this.handlePhoneChange} />
                                  {/* <MDBInput label="Phone Number" maxlength="15" type="text" name="vendor_primary_contact_phoneno" value={this.state.vendor_primary_contact_phoneno} onChange={ this.handleInputChange } />
                                                        <span className="error" style={{color: 'red'}}>{this.state.errors["vendor_primary_contact_phoneno"]}</span> */}
                                </div>
                              </Row>
                              <Row>
                                <div className="form-field col-md-6 extra-accol">
                                  {/* <MDBInput label="Accolades" /> */}
                                  <div className="md-form">
                                    <MDBInput label="Accolades" name="multipleaccolades" value={this.state.multipleaccolades} onChange={this.handleInputChange} />
                                    {this.createAccoladesUI()}
                                    <input type="button" className="view-btn" value="ADD" onClick={this.addAccoladesClick.bind(this)} />
                                  </div>
                                </div>
                                <div className="form-field col-md-6 extra-accol">
                                  <div className="form-field">
                                    <MDBInput label="Media Links" name="multipleValues" value={this.state.multipleValues} onChange={this.handleInputChange} />
                                    <span className="error" style={{ color: "red", left: "0" }}>
                                      {this.state.errors["multipleValues"]}
                                    </span>
                                  </div>
                                  {this.createMediaUI()}
                                  <input type="button" className="view-btn" value="ADD" onClick={this.addMediaClick.bind(this)} />
                                </div>
                              </Row>
                            </div>
                          </div> : null}
                        {this.state.showContactInfo ? <div className="vendot-info-detail contact-info-field">
                            <Row>
                              <div className="form-field form-block col-md-4">
                                <MDBInput label="First Name" name="vendor_primary_contact_firstname" value={this.state.vendor_primary_contact_firstname} onChange={this.handleInputChange} />
                              </div>
                              <div className="form-field form-block col-md-4">
                                <MDBInput label="Middle Name" name="vendor_primary_contact_middlename" value={this.state.vendor_primary_contact_middlename} onChange={this.handleInputChange} />
                              </div>
                              <div className="form-field form-block col-md-4">
                                <MDBInput label="Last Name" name="vendor_primary_contact_lastname" value={this.state.vendor_primary_contact_lastname} onChange={this.handleInputChange} />
                              </div>
                            </Row>
                            <Row>
                              <div className="form-field form-block  col-md-6">
                                <MDBInput label="Email" name="vendor_primary_email" value={this.state.vendor_primary_email} onChange={this.handleInputChange} />
                              </div>
                              <div className="form-field form-block  col-md-6">
                                <MDBInput label="Department" name="vendor_primary_department" value={this.state.vendor_primary_department} onChange={this.handleInputChange} />
                              </div>
                            </Row>
                          </div> : null}

                        {this.state.showSocialInfo ? <div className="vendot-info-detail contact-info-field">
                            <Row>
                              <div className="form-field form-block col-md-6">
                                <MDBInput label="Facebook" name="facebookLink" value={this.state.facebookLink && this.state.facebookLink != "undefined" ? this.state.facebookLink : ""} onChange={this.handleInputChange} />
                                <span className="error" style={{ color: "red" }}>
                                  {this.state.urlError["facebookLink"]}
                                </span>
                              </div>
                              <div className="form-field form-block col-md-6">
                                <MDBInput label="Linkedin" name="linkedInLink" value={this.state.linkedInLink && this.state.linkedInLink != "undefined" ? this.state.linkedInLink : ""} onChange={this.handleInputChange} />
                                <span className="error" style={{ color: "red" }}>
                                  {this.state.urlError["linkedInLink"]}
                                </span>
                              </div>
                            </Row>
                            <Row>
                              <div className="form-field form-block col-md-6">
                                <MDBInput label="YouTube" name="youTubeLik" value={this.state.youTubeLik && this.state.youTubeLik != "undefined" ? this.state.youTubeLik : ""} onChange={this.handleInputChange} />
                                <span className="error" style={{ color: "red" }}>
                                  {this.state.urlError["youTubeLik"]}
                                </span>
                              </div>
                              <div className="form-field form-block col-md-6">
                                <MDBInput label="Twitter" name="twitterLink" value={this.state.twitterLink && this.state.twitterLink != "undefined" ? this.state.twitterLink : ""} onChange={this.handleInputChange} />
                                <span className="error" style={{ color: "red" }}>
                                  {this.state.urlError["twitterLink"]}
                                </span>
                              </div>
                            </Row>
                            <Row>
                              <div className="form-field form-block col-md-12 inst-block">
                                <MDBInput label="Instagram" name="instagramLink" value={this.state.instagramLink && this.state.instagramLink != "undefined" ? this.state.instagramLink : ""} onChange={this.handleInputChange} />
                                <span className="error" style={{ color: "red" }}>
                                  {this.state.urlError["instagramLink"]}
                                </span>
                              </div>
                            </Row>
                          </div> : null}
                      </div>
                    </Col>
                  </Row>

                  <span style={!this.isEmpty(this.state.errors) ? {} : { display: "none" }}>
                    <div class="alert alert-warning" role="alert">
                      <strong>
                        Please check all the mandatory fields in the form.
                      </strong>
                    </div>
                  </span>

                  {this.state.showContactInfo ? <Row className="justify-content-center">
                      <Col md="10">
                        <span class="btn btn-sm btn-white-bg fleft" onClick={this.handleBasic} title="Previous">
                          <i class="fa fa-angle-left" aria-hidden="true" />
                          Previous
                        </span>
                        <span class="btn btn-sm fright" onClick={this.handleSocialInfo} title="Next">
                          Next
                          <i class="fa fa-angle-right" aria-hidden="true" />
                        </span>
                      </Col>
                    </Row> : null}
                  {this.state.showSocialInfo ? <Row className="justify-content-center">
                      <Col md="10 below-error">
                        <span className="success-green" style={{ color: "green" }}>
                          {this.state.successerrMsg}
                        </span>
                        <span className="error-red" style={{ color: "red" }}>
                          {this.state.errMsg}
                        </span>
                        <span class="btn btn-sm btn-white-bg fleft" onClick={this.handleContactInfo} title="Previous">
                          <i class="fa fa-angle-left" aria-hidden="true" />
                          Previous
                        </span>
                        <span class="btn btn-sm fright" title="Update" onClick={this.handleSubmit}>
                          Update
                          <i class="fa fa-angle-right" aria-hidden="true" />
                          <div style={this.state.loading ? {} : { display: "none" }} class="image-fill w-25 loader-login-div ">
                            <img src={Loading} alt="No Image" className="can-click " />
                          </div>
                        </span>
                      </Col>
                    </Row> : null}
                  {this.state.showBasic ? <Row className="justify-content-center">
                      <Col md="10">
                        <span class="btn btn-sm fright" onClick={this.handleContactInfo}>
                          Next
                          <i class="fa fa-angle-right" aria-hidden="true" />
                        </span>
                      </Col>
                    </Row> : null}
                </div>
              </form>
            </Container>
          </div>
        </div>;
    } else {
      window.location.href = AppConstants.STAGRFP + '/';
    }
  }
}
vendorinfo.propTypes = {
  updateVendorProfile: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  handlevendordashboard: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

//export default ChangepasswordPage;
export default connect(
  mapStateToProps,
  {
    getVendorProfile,
    updateVendorProfile,
    handlevendordashboard,
  }
)(vendorinfo);
