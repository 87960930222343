import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import { Container, Row, Col } from 'reactstrap';
import AppConstants from '../AppConstants';
import { connect } from 'react-redux';
import { withRouter,BrowserHistory } from 'react-router-dom';
import {logoutUser,getProfile,getVendorProfile,dashboard,handlevendordashboard} from '../actions/authentication';
import nologo from './logo.jpg';
import {ReactTitle} from 'react-meta-tags';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import PropTypes from 'prop-types';
import Loading from '../pages/loader.gif';
// const BrowserHistory = require('react-router/lib/BrowserHistory').default
//export default class  dashboard extends Component{
class companies extends React.Component {
    constructor(props) {
        console.log(props);
        super(props);
        this.state = {      
          companydashboarddata: [],
          errors: {},
          errMsg:false,
          subscribe_companies:false,
          checked:false,
          allsub:0,
          "loading": '',
      };
    }

    componentDidMount() {
      if(this.props.auth.user.type=="company"){
      this.props.dashboard("companies");
      }
      else{
      this.props.handlevendordashboard("vendorcompanies");
      }
      window.scrollTo(0, 0);
        fetch(AppConstants.API+'/cutomers/getallcompaniesdata/'+this.props.auth.user._id).then(response => response.json())
         .then(data => {
          console.log(data);
          if(data.Status == "Success")
          {
            this.setState({ companydashboarddata: data.companydashboarddata });
          }
          else
          {
            this.setState({ companydashboarddata: []});
          }    
        });
    }

    handleCheckClickCertification = (e) => {
        if(this.state.checked == false) {
          this.setState({loading:1}); 
        this.setState({
            checked: !this.state.checked,
            subscribe_companies:!this.state.checked
        });
        }else{
          this.setState({loading:1}); 
            //console.log(this.state.favorite_vendors);
        this.setState({
            checked: !this.state.checked,
            subscribe_companies:!this.state.checked
        });
        
        }
        //console.log(this.state.favorite_vendors);
        if(this.state.checked == true)
        {
          this.setState({loading:1}); 
            this.state.allsub = 0;
            this.getSubscribes(this.state.allsub);
        }
        else
        {
            this.setState({loading:1}); 
            this.state.allsub = 1;
            this.getSubscribes(this.state.allsub);
        }
    }
    getSubscribes(fav){
        //console.log(fav);
        fetch(AppConstants.API+'/vendorsubscribe/getallsubscribecompanies/'+this.props.auth.user._id+'/'+fav).then(response => response.json())
         .then(data => {
          //console.log(data);
          this.setState({loading:''});
          if(data.Status == "Success")
          {
            this.setState({ companydashboarddata: data.companydashboarddata });
          }
          else
          {
            this.setState({ companydashboarddata: []});
          }    
        });
    }

    SubscribeCompany = params => e => {
      NotificationManager.success('Subscribed', '', 500);
        e.preventDefault();
        const subscribecompanydata = {
            vendor_id:this.props.auth.user._id,
            company_id:params
        }
        axios.post(AppConstants.API+'/vendorsubscribe/vendorsubscribe',subscribecompanydata)
          .then(res => {
           if(res.data.Status == "Success"){                                          
            
            fetch(AppConstants.API+'/cutomers/getallcompaniesdata/'+this.props.auth.user._id).then(response => response.json())
            .then(data => {
            //console.log(data);
            if(data.Status == "Success")
                {
                    this.setState({ companydashboarddata: data.companydashboarddata });
                }
                else
                {
                    this.setState({ companydashboarddata: []});
                }    
            });
          }
          else{
            console.log('no data');
          }
        });
    };
    UnSubscribeCompany = params => e => {
        e.preventDefault();
        const unsubscribecompanydata = {
            vendor_id:this.props.auth.user._id,
            company_id:params
        }
        axios.post(AppConstants.API+'/vendorsubscribe/vendorunsubscribe',unsubscribecompanydata)
          .then(res => {
           if(res.data.Status == "Success"){
            fetch(AppConstants.API+'/cutomers/getallcompaniesdata/'+this.props.auth.user._id).then(response => response.json())
            .then(data => {
            console.log(data);
            if(data.Status == "Success")
                {
                    this.setState({ companydashboarddata: data.companydashboarddata,checked:false });
                }
                else
                {
                    this.setState({ companydashboarddata: []});
                }    
            });
          }
          else{
            console.log('no data');
          }
        });
    };

    renderShowsTotal(start, to, total) {
        return (
          <p className="page-show">
          Showing rows { start } to { to } of { total }
          </p>
        );
      }
     
      actionBtn(cell,row) {
          if(this.props.auth.user.type == 'vendor'){
              if(row.subtatus == 1){
                return  <div className="action-block">        
                <Link to={`${AppConstants.STAGRFP}${"/viewcompanydetails/"}${row._id}`} className="view-icon" title="View Company Details"><i class="fa fa-eye" aria-hidden="true"></i></Link><a href="javascript:void(0);" onClick={ this.UnSubscribeCompany(row._id) } className="view-icon unfav-icon" title="Subscribe Company"><i class="fa fa-heart" aria-hidden="true"></i></a>
                </div>;
              }
              else
              {
                return  <div className="action-block">        
                <Link to={`${AppConstants.STAGRFP}${"/viewcompanydetails/"}${row._id}`} className="view-icon" title="View Company Details"><i class="fa fa-eye" aria-hidden="true"></i></Link><a href="javascript:void(0);" onClick={ this.SubscribeCompany(row._id) } className="view-icon fav-icon" title="Subscribe Company"><i class="fa fa-heart" aria-hidden="true"></i></a>
                <NotificationContainer/>
                </div>;
              }
              
        }
        else
        {
            return  <div className="action-block">        
            <Link to={`${AppConstants.STAGRFP}${"/viewcompanydetails/"}${row._id}`} className="view-icon" title="View Company Details"><i class="fa fa-eye" aria-hidden="true"></i></Link>
            </div>;  
        }
              
    }
    logoInfo(cell,row) {
        return  <div>{row.company_name}
        {/* <div className="vendor-logo"><img  src={row.company_logo || nologo} alt={row.company_logo || nologo} className="img-responsive"/></div> */}
        </div>;      
    }
    // <b>Name:</b>{row.company_primary_contact_firstname || 'N/A'} <br/> 
    companyInfo(cell,row) {
        return  <div className="contact-info">
        <span className="location">{row.company_address}, <br />
        {row.city}, {row.state} {row.zipcode} </span>
        {(row.company_primary_contact_phoneno && row.company_primary_contact_phoneno !='') ? (<span className="phone-no"> {row.company_primary_contact_phoneno}</span>) : ''}
        {(row.company_website && row.company_website !='') ? ( <span className="website"><a href={row.company_website} target="_blank" >{row.company_website}</a></span>) : ''}
      </div> ;        
    }
    
    naicInfo(cell,row) {
        return  <div>{row.industry} <br/>
                <b>NAICS Codes:</b><br/>
                    <span className="naiccodes">
                        <ul style={{listStyle:'none', padding:'0'}}>
                            {row.naiccodesdes.length > 0 && row.naiccodesdes.map(recipe => {
                                    return (
                                        
                                        <li>{recipe}<span>,</span></li>
                                    
                                        )
                                    })
                                }
                        </ul>
                        {row.naiccodesdes == '' && <span>No Naics codes</span>} 
                    </span>
                </div> ;
    } 
    
    
  render() {    
    
const options = {
  page: 1,  // which page you want to show as default
  sizePerPageList: [ {
    text: '10', value: 10
  }, {
    text: '25', value: 25
  }, {
    text: 'All', value: this.state.companydashboarddata.length
  } ], // you can change the dropdown list for size per page
  sizePerPage: 10,  // which size per page you want to locate as default
  pageStartIndex: 1, // where to start counting the pages
  paginationSize: 5,  // the pagination bar size.
  prePage: '<', // Previous page button text
  nextPage: '>', // Next page button text
  firstPage: 'First', // First page button text
  lastPage: 'Last', // Last page button text
  paginationShowsTotal: this.renderShowsTotal,  // Accept bool or function
  paginationPosition: 'bottom'  // default is bottom, top and both is all available
 
};
if(localStorage.jwtToken != undefined){
    console.log(this.props.auth.user.type);
    return (
        <div>
            <ReactTitle title="Companies" />
            <div className="inner-banner">
            </div>
            <div className="inner-content">
                <Container>
                    {}
                    
                                      
                    
                    <div className="dashboard-rfp-list">  
                    <div className="dashboard-rfp-head dashboard-rfp-icon">
                    <h6>Companies List</h6> 
                    {this.props.auth.user.type == 'vendor' && <h6><em>Filter: </em>
                    <div class="form-group form-check inline fav-block">
                    <input type="checkbox" id="checkboxinline1" name="subscribe_companies"
                            value={this.state.subscribe_companies}                      
                            checked={this.state.checked} onChange={this.handleCheckClickCertification} />
                            <label for="checkboxinline1"> Subscribe Companies <div style={this.state.loading ? {} : { display: 'none' }} class="image-fill w-25 loader-login-div vendor-loading"><img src={Loading} alt="No Image" className="can-click " /></div></label></div>
                        
                         </h6>  }
                         </div>                     
                        <BootstrapTable data={this.state.companydashboarddata} pagination={ true } options={ options } search={true} searchPlaceholder={'Search by Name or Industry or NAICS Codes'}
                        >
                            
                            {/* <TableHeaderColumn dataField='_id' isKey={ true } dataSort={ true } hidden={true}>ID</TableHeaderColumn> */}

                            <TableHeaderColumn dataField='company_name' dataFormat={ this.logoInfo.bind(this) } isKey={ true } dataSort={ true } width={'20%'}>Name</TableHeaderColumn>
                            <TableHeaderColumn dataField='company_address' dataSort={ true } width={'25%'} dataFormat={ this.companyInfo.bind(this) } >Company Address </TableHeaderColumn>

                            <TableHeaderColumn dataField='city' dataSort={ true } hidden={true}>Industry</TableHeaderColumn>
                            <TableHeaderColumn dataField='state' dataSort={ true } hidden={true}>Industry</TableHeaderColumn>
                            <TableHeaderColumn dataField='zipcode' dataSort={ true } hidden={true}>Industry</TableHeaderColumn>

                            <TableHeaderColumn dataField='naiccodesdes' dataSort={ true } hidden={true}>Industry</TableHeaderColumn>
                            <TableHeaderColumn dataField='industry' dataFormat={ this.naicInfo.bind(this) } dataSort={ true }>Industry Type / NAICS Codes</TableHeaderColumn>
                            
                            <TableHeaderColumn dataField='Action' width={'10%'} dataFormat={ this.actionBtn.bind(this) }>Actions</TableHeaderColumn>
                        </BootstrapTable>
                    </div>
                </Container>
            </div>
        </div>
        
        );
    }
    else
    {
        window.location.href = AppConstants.STAGRFP+'/';
    }
  }
}
companies.propTypes = {
  
  dashboard: PropTypes.func.isRequired,
  handlevendordashboard:PropTypes.func.isRequired
};
const mapStateToProps = (state) => ({
    auth: state.auth
  })
  //export default Header;
  export default connect(mapStateToProps, {dashboard,handlevendordashboard})(withRouter(companies));