import React, { Component } from 'react';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import AppConstants from '../AppConstants';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import {logoutUser,getProfile} from '../actions/authentication';
import nologo from './nologo.png';
import {ReactTitle} from 'react-meta-tags';

//export default class  vendordetail extends Component{
class rfpdetails extends React.Component {   
    constructor(props,context){
        super(props,context);
        this.state = {
            "id":this.props.match.params.id,
            "name_ofrfp":'',
            "minority_business":false,
            "women_owned_business":false,
            "rfprfiprofilesdata":[],
            "certifications":[],
            //"vendordashboarddata":[]
            "naic_code":[]
        }        
       }
    
       componentDidMount()
       {
           //Get Rfp Rfi profile data
            fetch(AppConstants.API+'/rfprfiprofiles/viewrfprfiprofile/'+this.props.match.params.id).then(response => response.json())
            .then(data => {
                //console.log(data);
                if(data.Status == "Success")
                {

                    //posted date
                var posteddate = data.rfprfiprofilesdata.rfp_post_date;
                var posteddate= new Date(posteddate);             
                var postdate =  ("0" + (posteddate.getMonth() + 1)).slice(-2) + "-" + (posteddate.getDate() < 10 ? '0' : '')+ posteddate.getDate() + "-" +  posteddate.getFullYear();

                //Due date
                var duedate = data.rfprfiprofilesdata.due_dateandtime;
                var duedate= new Date(duedate);
                var hours = duedate.getHours();
                var minutes = duedate.getMinutes();
                var ampm = hours >= 12 ? 'PM' : 'AM';
                hours = hours % 12;
                hours = hours ? hours : 12; // the hour '0' should be '12'
                minutes = minutes < 10 ? '0'+minutes : minutes;
                var strTime = hours + ':' + minutes + ' ' + ampm;
                var duedt=  ("0" + (duedate.getMonth() + 1)).slice(-2) + "-" + (duedate.getDate() < 10 ? '0' : '')+ duedate.getDate() + "-" + duedate.getFullYear() + " " + strTime;

                //Questions date
                var deadlinedate = data.rfprfiprofilesdata.deadline_for_questions;
                var deadlinedate= new Date(deadlinedate);             
                var deaddate =  ("0" + (deadlinedate.getMonth() + 1)).slice(-2) + "-" + (deadlinedate.getDate() < 10 ? '0' : '')+ deadlinedate.getDate() + "-" +  deadlinedate.getFullYear();

                    this.setState({
                        name_ofrfp: data.rfprfiprofilesdata.name_ofrfp,
                        contact_first_name: data.rfprfiprofilesdata.contact_first_name,
                        contact_last_name: data.rfprfiprofilesdata.contact_last_name,
                        contact_phonenumber: data.rfprfiprofilesdata.contact_phonenumber,
                        contact_email: data.rfprfiprofilesdata.contact_email,
                        rfp_post_date: postdate,
                        due_dateandtime: duedt,
                        description: data.rfprfiprofilesdata.description,
                        department: data.rfprfiprofilesdata.department,
                        bid_link: data.rfprfiprofilesdata.bid_link,
                        deadline_for_questions: deaddate,
                        minority_business: data.rfprfiprofilesdata.minority_business,
                        women_owned_business: data.rfprfiprofilesdata.women_owned_business,
                        need_certifications: data.rfprfiprofilesdata.need_certifications,
                        bid_rfp_reference_number: data.rfprfiprofilesdata.bid_rfp_reference_number,
                        status: data.rfprfiprofilesdata.status,
                        documents:data.rfprfiprofilesdata.documents,
                        prev_document: data.rfprfiprofilesdata.documents,
                        view_document:data.rfprfiprofilesdata.documents,
                        naic_code:data.rfprfiprofilesdata.naiccodesdes,
                        certifications:data.rfprfiprofilesdata.certifications
                      });
                }
                else
                {
                    this.setState({ getfprfiprofiledata: "" });
                }            
            });
            
       }

  render() {
      let minority ='';
      let business='';
      /* console.log(this.state.certifications);
      var test = this.state.certifications;
      var myVar2 = test.join(', '); */
   if(this.state.minority_business == true){
        minority = 'Yes'
   }
   else{
    minority = 'No'
   }
   if(this.state.women_owned_business == true){
    business = 'Yes'
    }
    else{
    business = 'No'
    }
        return (
            <div>
                <ReactTitle title="Opportunity Details" />
                <div className="inner-banner">
                </div>
                <div className="inner-content rfpviewdetail">
                    <Container>
                       <div className="white-bg pad50">
                       <Row className="justify-content-center">
                                    <Col md="10">
                                        {/* <h1 className="color-yellow">RFP Details</h1> */}
                                        <div>                                           
                                            {/* <h3>RFP Basic Information</h3>
                                            <Link to="/allrfprfis" className="btn btn-sm fright" title="Back">Back</Link> */}
                                            <Row className="justify-content-center">
                                                <Col md="8">
                                                <h3>Opportunity Basic Information</h3>
                                                </Col>
                                                {/* <Col md="4">
                                            
                                                {this.props.auth.user.type =='company' ? <button onClick={BrowserHistory.goBack} className="btn btn-sm fright back-btn" >Back</button> :''} 
                                                
                                                
                                                </Col>   */}  
                                                
                                            </Row>       
                                              
                                                <div className="vendot-info-detail pb-0">
                                                    <div className="">
                                                        <Row className="marg">
                                                            <div className="form-field col-md-4">
                                                                <span>Name Of Opportunity</span>
                                                            </div>
                                                            <div className="form-field col-md-8">
                                                                <h6>{this.state.name_ofrfp}</h6>
                                                            </div>
                                                        </Row>
                                                        <Row className="marg">
                                                            <div className="form-field col-md-4">
                                                                <span>Department</span>
                                                            </div>
                                                            <div className="form-field col-md-8">
                                                                <h6>{this.state.department || 'N/A'}</h6>
                                                            </div>
                                                        </Row>
                                                        <Row className="marg">
                                                            <div className="form-field col-md-4">
                                                                <span>Biz Link</span>
                                                            </div>
                                                            <div className="form-field col-md-8">
                                                                <h6>{(this.state.bid_link) ? (<a href={this.state.bid_link} target="_blank">{this.state.bid_link}</a>) : (<div>N/A</div>)}</h6>
                                                            </div>
                                                        </Row>
                                                        <Row className="marg">
                                                            <div className="form-field col-md-4">
                                                                <span>NAICS Codes</span>
                                                            </div>
                                                            {/* <div className="form-field col-md-8">
                                                                <h6>{this.state.naic_code}</h6>
                                                            </div> */}
                                                            <div className="form-field  col-md-8">
                                                            <ul className="recipe-list" style={{listStyle:'none', padding:'0'}}>
                                                                    {this.state.naic_code.map(recipe => {
                                                                            return (
                                                                                
                                                                                <li>{recipe}</li>
                                                                            
                                                                                )
                                                                            })
                                                                        } 
                                                                {this.state.naic_code.length == 0 && <li>No NAICS Codes</li>} 
                                                                    </ul>
                                                            </div>
                                                        </Row>
                                                        <Row className="marg">
                                                            <div className="form-field col-md-4">
                                                                <span>Posted Date</span>
                                                            </div>
                                                            <div className="form-field col-md-8">
                                                                <h6>{this.state.rfp_post_date}</h6>
                                                            </div>
                                                        </Row>
                                                        <Row className="marg">
                                                            <div className="form-field col-md-4">
                                                                <span>Due Date & Time</span>
                                                            </div>
                                                            <div className="form-field col-md-8">
                                                            <h6>{this.state.due_dateandtime || 'N/A'}</h6>
                                                            </div>
                                                        </Row>
                                                        <Row className="marg">
                                                            <div className="form-field col-md-4">
                                                                <span>Deadline for questions</span>
                                                            </div>
                                                            <div className="form-field col-md-8">
                                                                <h6>{this.state.deadline_for_questions || 'N/A'}</h6>
                                                            </div>
                                                        </Row>
                                                        <Row className="marg">
                                                            <div className="form-field col-md-4">
                                                                <span>Reference Number</span>
                                                            </div>
                                                            <div className="form-field col-md-8">
                                                                <h6>{this.state.bid_rfp_reference_number || 'N/A'}</h6>
                                                            </div>
                                                        </Row>
                                                        <Row className="marg">
                                                            <div className="form-field col-md-4">
                                                                <span>Description</span>
                                                            </div>
                                                            <div className="form-field col-md-8">
                                                                <h6>{this.state.description}</h6>
                                                            </div>
                                                        </Row>
                                                        
                                                        <Row>
                                                <div className="form-field col-md-12"><h6>Would you like to give special consideration to either group below?</h6></div></Row>
                                                        <Row className="marg">
                                                            <div className="form-field col-md-4">
                                                                <span>Minority Business</span>
                                                            </div>
                                                            <div className="form-field col-md-8">
                                                                <h6>{minority}</h6>
                                                            </div>
                                                        </Row>
                                                        <Row className="marg">
                                                            <div className="form-field col-md-4">
                                                                <span>Women Owned Business</span>
                                                            </div>
                                                            <div className="form-field col-md-8">
                                                                <h6>{business}</h6>
                                                            </div>
                                                        </Row>
                                                        <Row className="marg">
                                                            <div className="form-field col-md-4">
                                                                <span>Supporting Document</span>
                                                            </div>
                                                            <div className="form-field col-md-8">
                                                                <div class = "file-field input-field fileupload">
                                                                    <div class = "filebtn">

                                                                    {(this.state.documents) ? (<a href={this.state.documents} target="_blank"><i class="fa fa-download" aria-hidden="true"></i> Download Document </a>) : (<h6>N/A</h6>)}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Row>
                                                        <Row className="marg">
                                                            <div className="form-field col-md-4">
                                                                <span>Would you like the applying company to be certified?</span>
                                                            </div>
                                                            <div className="form-field col-md-8">
                                                                <ul className="recipe-list" style={{listStyle:'none', padding:'0'}}>
                                                                {this.state.certifications != undefined && this.state.certifications.map(recipe => {
                                                                    if(recipe != null)
                                                                    {
                                                                        return (
                                                                            <li>{recipe}</li>
                                                                        )
                                                                    }else{
                                                                        return (
                                                                            <li>No Certifications</li>
                                                                        )
                                                                    }
                                                                    
                                                                    })
                                                                }{this.state.certifications.length == 0 && <li>No Certifications</li>} 
                                                                    </ul>
                                                            </div>
                                                        </Row>
                                                        
                                                        
                                                        

                                                    </div>
                                                    <div className="form-block">
                                                    <h3 className="vi-title">Contact Information</h3>
                                                    <Row className="marg">
                                                        <div className="form-field col-md-4">
                                                            <span>First Name </span>
                                                        </div>
                                                        <div className="form-field col-md-8">
                                                            <h6>{this.state.contact_first_name || 'N/A'}</h6>
                                                        </div>
                                                    </Row>
                                                    <Row className="marg">
                                                        <div className="form-field col-md-4">
                                                            <span>Last Name </span>
                                                        </div>
                                                        <div className="form-field col-md-8">
                                                            <h6>{this.state.contact_last_name || 'N/A'}</h6>
                                                        </div>
                                                    </Row>
                                                    <Row className="marg">
                                                        <div className="form-field col-md-4">
                                                            <span>Email</span>
                                                        </div>
                                                        <div className="form-field col-md-8">
                                                            <h6>{this.state.contact_email ? this.state.contact_email : 'N/A'}</h6>
                                                        </div>
                                                    </Row>
                                                    <Row className="marg">
                                                        <div className="form-field col-md-4">
                                                            <span>Phone Number</span>
                                                        </div>
                                                        <div className="form-field col-md-8">
                                                            <h6>{this.state.contact_phonenumber || 'N/A'}</h6>
                                                        </div>
                                                    </Row>
                                                    
                                                    </div>
                                                </div>                                                                              
                                        </div>
                                    </Col>
                            </Row>                   
                       </div>

                       

                    </Container>
                </div>
            </div>            
      );
  }
}
rfpdetails.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
  };
const mapStateToProps = (state) => ({
    auth: state.auth
  })
export default connect(mapStateToProps, {logoutUser,getProfile})(withRouter(rfpdetails));