import React, { Component } from "react";
import { Container, Row, Col, Button } from "reactstrap";
import axios from "axios";
import { MDBInput, MDBBtn, MDBTextArea } from "mdbreact";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getVendorProfile, dashboard } from "../actions/authentication";
import AppConstants from "../AppConstants";
import NumberFormat from "react-number-format";
import MomentLocaleUtils, { formatDate } from "react-day-picker/moment";
import { Typeahead } from "react-bootstrap-typeahead";
import moment from "moment";
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";

import Loading from "../pages/loader.gif";
import { ReactTitle } from "react-meta-tags";
import BrowserHistory from "react-router/lib/browserHistory";
import { withRouter } from "react-router-dom";

var duedate = new Date();
duedate.setDate(duedate.getDate() + 7);

var deadlinedate = new Date();
deadlinedate.setDate(deadlinedate.getDate() + 3);

var certified_Docs = [];
var certification_newids = [];
var isFile = "";
class postrfprfi extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      name_ofrfp: "",
      customer_id: this.props.auth.user._id,
      due_dateandtime: duedate,
      description: "",
      department: "",
      bid_link: "",
      naic_code: [],
      contact_first_name: "",
      contact_last_name: "",
      contact_phonenumber: "",
      contact_email: "",
      //"minority_or_women_owned_business": false,
      minority_business: false,
      women_owned_business: false,
      checked2: false,
      checked: false,
      need_certifications: "No",
      checked1: false,
      certifications: [],
      certificationsdata: [],
      viewedrfprfp_vendorids: [],
      likedrfprfp_vendorids: [],
      interestedrfprfp_vendorids: [],
      documents: "",
      rfp_post_date: new Date(),
      deadline_for_questions: deadlinedate,
      bid_rfp_reference_number: "",
      department_min_revenue: "",
      department_max_revenue: "",
      status: "Active",
      errors: {},
      errMsg: "",
      successerrMsg: "",
      naiccodes: [],
      customersdata: [],
      loading: "",
      certified_docs: [],
      rfprfiid: null,
      certificationid: [],
      certify: "",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleDayChange = this.handleDayChange.bind(this);
    this.handleDayChange1 = this.handleDayChange1.bind(this);
    this.handleDayChange2 = this.handleDayChange2.bind(this);
    this.handleInputFileChange = this.handleInputFileChange.bind(this);
    this.handleInputChangeFund = this.handleInputChangeFund.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChange1 = this.handleChange1.bind(this);
    this.handleChange2 = this.handleChange2.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handlePhoneChange = this.handlePhoneChange.bind(this);
  }
  handleChange(date) {
    this.setState({
      rfp_post_date: date,
    });
  }
  handleChange1(date) {
    this.setState({
      due_dateandtime: date,
    });
  }
  handleChange2(date) {
    this.setState({
      deadline_for_questions: date,
    });
  }

  handleInputFileChange(e) {
    isFile = 1;
    this.setState({
      [e.target.name]: e.target.files[0],
    });
  }

  handleClick(e) {
    //console.log(e.target.value);
    if (e.target.checked) {
      certification_newids.push(e.target.value);
      document.getElementById("cert_" + e.target.value).style.display = "block";
    } else {
      document.getElementById("cert_" + e.target.value).style.display = "none";
    }
  }

  handleFileChange(e) {
    //console.log(e.target.checked);
    this.setState({ [e.target.name]: e.target.files[0] });

    let formData = new FormData();

    formData.append("rfprfiid", this.state.rfprfiid);
    formData.append("certification_documents", e.target.files[0]);
    formData.append("certificationid", JSON.stringify(certification_newids));

    axios.post(AppConstants.API + "/rfprfiprofiles/addcertificate", formData).then((res) => {
      //console.log(res);
      if (res.data.Status == "certifiedSuccess") {
        this.setState({
          rfprfiid: res.data.rfprfiid,
        });
      }
    });
  }

  handleInputChangeFund(e) {
    //console.log(this.state.certifications);
    const certifications = this.state.certifications;
    let index;
    // check if the check box is checked or unchecked
    if (e.target.checked) {
      // add the numerical value of the checkbox to options array
      certifications.push(e.target.value);
    } else {
      // or remove the value from the unchecked checkbox from the array
      index = certifications.indexOf(e.target.value);
      certifications.splice(index, 1);
    }
    //console.log(certifications);
    // update the state with the new array of options
    this.setState({ certifications: certifications });
  }
  //checkbox
  handleCheckClickMinority = (e) => {
    if (this.state.checked == false) {
      this.setState({
        checked: !this.state.checked,
        minority_business: !this.state.checked,
      });
    } else {
      this.setState({
        checked: !this.state.checked,
        minority_business: !this.state.checked,
      });
    }
  };
  //checkbox handleCheckClickWomen
  handleCheckClickWomen = (e) => {
    if (this.state.checked2 == false) {
      this.setState({
        checked2: !this.state.checked2,
        women_owned_business: !this.state.checked2,
      });
    } else {
      this.setState({
        checked2: !this.state.checked2,
        women_owned_business: !this.state.checked2,
      });
    }
  };
  handleCheckClickCertification = (e) => {
    if (e.target.value == "Yes") {
      this.setState({
        need_certifications: e.target.value,
      });
    } else {
      this.setState({
        need_certifications: e.target.value,
        certifications: [],
      });
    }
    //console.log(this.state.checked1)
    // if(this.state.checked1 == false) {
    //   this.setState({
    //     checked1: !this.state.checked1,
    //     need_certifications:!this.state.checked1
    //   });
    // }else{
    //   this.setState({
    //     checked1: !this.state.checked1,
    //     need_certifications:!this.state.checked1,
    //     certifications:[]
    //   });
    // }
  };

  handleDayChange(day) {
    //console.log('In handleDayChange')
    this.setState({ rfp_post_date: day });
  }
  handleDayChange1(day) {
    //console.log('In handleDayChange')
    this.setState({ deadline_for_questions: day });
  }
  handleDayChange2(day) {
    //console.log('In handleDayChange')
    this.setState({ due_dateandtime: day });
  }

  handleInputChange(e) {
    //console.log(this.state.revanuerange);
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handlePhoneChange(e) {
    //const re1 =/^[0-9)\(+ -]+$/g;
    const re1 = /^[0-9)\(+ '-]+$/g;
    // var phone_num = e.target.value.replace(/\D/g,'');
    if (e.target.value === "" || re1.test(e.target.value)) {
      // phone_num = this.phoneFormat(phone_num)
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  }

  phoneFormat(input) {
    // Strip all characters from the input except digits
    // Trim the remaining input to ten characters, to preserve phone number format
    input = input.substring(0, 10);
    // Based upon the length of the string, we add formatting as necessary
    var size = input.length;
    if (size == 0) {
      input = input;
    } else if (size < 4) {
      input = "(" + input;
    } else if (size < 7) {
      input = "(" + input.substring(0, 3) + ") " + input.substring(3, 6);
    } else {
      input = "(" + input.substring(0, 3) + ") " + input.substring(3, 6) + "-" + input.substring(6, 10);
    }
    return input;
  }

  /** Validations */
  handleValidation() {
    let errors = {};
    let formIsValid = true;

    var date = new Date(this.state.rfp_post_date);
    date.setDate(date.getDate() + 6);
    //console.log(date);
    if (this.state.need_certifications == "Yes") {
      if (this.state.certifications.length == 0) {
        formIsValid = false;
        errors["certify"] = "Please select atleast one certification";
      }
    }
    if (!this.state.name_ofrfp) {
      formIsValid = false;
      errors["name_ofrfp"] = "Please enter name of opportunity";
    }
    //Email
    // if(!this.state.contact_email){
    //   formIsValid = false;
    //   errors["contact_email"] = "Please enter email";
    // }
    // if(typeof this.state.contact_email !== "undefined"){
    //   let lastAtPos = this.state.contact_email.lastIndexOf('@');
    //   let lastDotPos = this.state.contact_email.lastIndexOf('.');

    //   if (!(lastAtPos < lastDotPos && lastAtPos > 0 && this.state.contact_email.indexOf('@@') == -1 && lastDotPos > 2 && (this.state.contact_email.length - lastDotPos) > 2)) {
    //     formIsValid = false;
    //     errors["contact_email"] = "Please enter valid email";
    //   }
    // }
    if (!this.state.contact_first_name) {
      formIsValid = false;
      errors["contact_first_name"] = "Please enter first name";
    }
    if (!this.state.contact_last_name) {
      formIsValid = false;
      errors["contact_last_name"] = "Please enter last name";
    }
    //  if(!this.state.contact_phonenumber){
    //    formIsValid = false;
    //    errors["contact_phonenumber"] = "Please enter phone number";
    // }
    //else if(this.state.contact_phonenumber.length !=14){
    //   formIsValid = false;
    //   errors["contact_phonenumber"] = "Please enter 10 digits phone number";
    // }
    // if(!this.state.rfp_post_date&&!this.state.due_dateandtime){
    //   formIsValid = false;
    //   errors["deadline_for_questions"] = "Please select posted date and due date";
    // }
    // if(this.state.rfp_post_date>this.state.deadline_for_questions){
    //   formIsValid = false;
    //   errors["deadline_for_questions"] = "Deadline for questions in between posted date and due date";
    // }
    // if(this.state.deadline_for_questions >this.state.due_dateandtime){
    //   formIsValid = false;
    //   errors["deadline_for_questions"] = "Deadline for questions  in between posted date and due date";
    // }
    // if(this.state.rfp_post_date&&!this.state.due_dateandtime){
    //   formIsValid = false;
    //   errors["deadline_for_questions"] = "Please select due date";
    // }
    // if(!this.state.rfp_post_date&&this.state.due_dateandtime){
    //   formIsValid = false;
    //   errors["deadline_for_questions"] = "Please select posted date";
    // }
    // if(this.state.due_dateandtime<date){
    //   formIsValid = false;
    //   errors["due_dateandtime"] = "Due date must be 7 days greater than post date";
    // }
    if (!this.state.due_dateandtime) {
      formIsValid = false;
      errors["due_dateandtime"] = "Please select due date";
    }
    if (!this.state.rfp_post_date) {
      formIsValid = false;
      errors["rfp_post_date"] = "Please select posted date";
    }
    // if(!this.state.deadline_for_questions){
    //   formIsValid = false;
    //   errors["deadline_for_questions"] = "Please select deadline for questions";
    // }

    if (!this.state.description) {
      formIsValid = false;
      errors["description"] = "Please enter description";
    }
    if (!this.state.department) {
      formIsValid = false;
      errors["department"] = "Please enter department";
    }
    // if(!this.state.bid_link){
    //   formIsValid = false;
    //   errors["bid_link"] = "Please enter biz link";
    // }
    if (this.state.bid_link != "" && !/^https?:\/\//i.test(this.state.bid_link)) {
      formIsValid = false;
      errors["bid_link"] = "Please enter valid biz link";
    }
    if (!this.state.naic_code.length) {
      formIsValid = false;
      errors["naic_code"] = "Please select NAICS code";
    }

    if (
      this.state.documents != "" &&
      this.state.documents != undefined &&
      (this.state.documents.type != "application/pdf" &&
        this.state.documents.type != "application/vnd.openxmlformats-officedocument.wordprocessingml.document")
    ) {
      formIsValid = false;
      errors["documents"] = "Please upload pdf or doc file";
    }
    this.setState({ errors: errors });
    return formIsValid;
  }

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.handleValidation()) {
      this.setState({ loading: 1 });
      let formData = new FormData();

      formData.append("rfprfiid", this.state.rfprfiid);
      formData.append("name_ofrfp", this.state.name_ofrfp);
      formData.append("customer_id", this.props.auth.user._id);
      formData.append("due_dateandtime", this.state.due_dateandtime);
      formData.append("description", this.state.description);
      formData.append("department", this.state.department);
      formData.append("bid_link", this.state.bid_link);
      formData.append("naic_code", JSON.stringify(this.state.naic_code));
      formData.append("contact_first_name", this.state.contact_first_name);
      formData.append("contact_last_name", this.state.contact_last_name);
      formData.append("contact_phonenumber", this.state.contact_phonenumber);
      formData.append("contact_email", this.state.contact_email);
      //formData.append('minority_or_women_owned_business', this.state.minority_or_women_owned_business);
      formData.append("minority_business", this.state.minority_business);
      formData.append("women_owned_business", this.state.women_owned_business);
      formData.append("certifications", JSON.stringify(this.state.certifications));
      formData.append("need_certifications", this.state.need_certifications);
      formData.append("documents", this.state.documents);
      formData.append("rfp_post_date", this.state.rfp_post_date);
      formData.append("deadline_for_questions", this.state.deadline_for_questions);
      formData.append("bid_rfp_reference_number", this.state.bid_rfp_reference_number);
      formData.append("department_min_revenue", this.state.department_min_revenue);
      formData.append("department_max_revenue", this.state.department_max_revenue);
      formData.append("status", this.state.status);
      formData.append("certification_documents", this.state.certification_documents);
      // console.log(rfprfidata);
      axios.post(AppConstants.API + "/rfprfiprofiles/addrfprfiprofile", formData).then((res) => {
        //console.log(res);
        if (res.data.Status == "Success") {
          isFile = "";
          this.setState({ loading: "" });
          setTimeout((e) => {
            this.props.history.push(AppConstants.STAGRFP + "/opportunityinfo/" + res.data.rfprfiid);
          }, 5000);
          this.setState({
            successerrMsg: res.data.msg,
          });
        } else {
          setTimeout(() => {
            this.setState({
              errMsg: false,
            });
          }, 2000);
          isFile = "";
          this.setState({ loading: "" });
          this.setState({
            errMsg: res.data.msg,
          });
        }
      });
    }
  };
  componentDidMount() {
    this.props.dashboard("rfp");
    window.scrollTo(0, 0);
    //Get Certification data
    fetch(AppConstants.API + "/certifications/getallcertifications")
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.Status == "Success") {
          this.setState({ certificationsdata: data.certificationsdata });
        } else {
          this.setState({ certificationsdata: "" });
        }
      });
    //Get all customers
    fetch(AppConstants.API + "/cutomers/getallcutomers")
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        if (data.Status == "Success") {
          this.setState({ customersdata: data.customersdata });
        } else {
          this.setState({ customersdata: "" });
        }
      });
    //Get naic codes
    fetch(AppConstants.API + "/users/getallnaiccodes")
      .then((response) => response.json())
      .then((data) => {
        //console.log(" naic codes ", data);
        if (data.Status == "Success") {
          this.setState({ naiccodes: data.naiccodes });
        } else {
          this.setState({ naiccodes: "" });
        }
      });
  }

  render() {
    // var certifiedvalues = this.state.certifications ;

    //    var certifiedvalues = this.state.certifications.map(planet1 => {
    //         return (<span><input type="file" name="certificationfiles"></input></span>);
    //         })

    //<span  id={`${'cert_'}${planet._id}`}><input type="file" name="certificationfiles"></input></span>

    // const checkboxItems = this.state.checked1
    // ? this.state.certificationsdata.map(planet => {
    //   return (<div class="form-group form-check"><input type="checkbox"  name="checkboxItems" onChange={this.handleInputChangeFund} value={planet._id} id={planet._id} onClick={this.handleClick}  /><label for={planet._id}>{planet.certification_name}</label>
    //   <span className="cert-doc">
    //     <div class = "file-field input-field fileupload">
    //         <div class = "filebtn">
    //           <span><i class="fa fa-upload" aria-hidden="true"></i></span>
    //           <input type = "file"  id={`${'cert_'}${planet._id}`} onChange={ this.handleFileChange } />
    //         </div>

    //           <div class = "file-path-wrapper">
    //             <input class = "file-path validate" type = "text"
    //                 placeholder = "Please upload any certification document." />
    //           </div>

    //       </div>
    //     </span>
    // </div>);
    //   })
    // : null;

    const checkboxItems =
      this.state.need_certifications == "Yes"
        ? this.state.certificationsdata.map((planet) => {
            return (
              <div class="form-group form-check">
                <input
                  type="checkbox"
                  name="checkboxItems"
                  onChange={this.handleInputChangeFund}
                  value={planet._id}
                  id={planet._id}
                />
                <label for={planet._id}>{planet.certification_name}</label>
              </div>
            );
          })
        : null;

    if (localStorage.jwtToken != undefined) {
      return (
        <div>
          <ReactTitle title="Post Opportunity" />
          <div className="inner-banner" />
          <div className="inner-content rfpviewdetail">
            <Container>
              <div className="white-bg  pad50">
                <Row className="justify-content-center">
                  <Col md="10">
                    <Row className="justify-content-center text-btn-div">
                      <Col md="8" className="main-title">
                        <h1 className="color-yellow sub-ex-title">Post Opportunity</h1>
                      </Col>
                      <Col md="4">
                        <button onClick={BrowserHistory.goBack} className="btn btn-sm fright back-btn">
                          Back
                        </button>
                      </Col>
                    </Row>
                  </Col>

                  <Col md="10" className="vendot-info-detail" id="company-edit-profile">
                    <form name="vendorprofile" onSubmit={this.handleSubmit}>
                      <div>
                        <div className="form-block company-edit-form">
                          <h3>Opportunity Basic Information</h3>

                          <Row>
                            <div className="form-field col-md-6">
                              <MDBInput
                                label="Name Of Opportunity *"
                                name="name_ofrfp"
                                onChange={this.handleInputChange}
                              />
                              <span className="error" style={{ color: "red" }}>
                                {this.state.errors["name_ofrfp"]}
                              </span>
                            </div>
                            <div className="form-field col-md-6">
                              <MDBInput label="Department *" name="department" onChange={this.handleInputChange} />
                              <span className="error" style={{ color: "red" }}>
                                {this.state.errors["department"]}
                              </span>
                            </div>
                          </Row>
                          <Row>
                            <div className="form-field col-md-6">
                              <MDBInput label="Biz link" name="bid_link" onChange={this.handleInputChange} />
                              <span className="error" style={{ color: "red" }}>
                                {this.state.errors["bid_link"]}
                              </span>
                            </div>
                            <div className="form-field col-md-6">
                              <MDBInput
                                label="Reference Number"
                                name="bid_rfp_reference_number"
                                onChange={this.handleInputChange}
                              />
                            </div>
                          </Row>

                          <Row>
                            <div className="form-field col-md-6">
                              <div className="md-form graph-field postdate-z-index" style={{ "z-index": "9" }}>
                                <div class="input-field phoneblock">
                                  {" "}
                                  {/*<DatePicker
                                                        selected={this.state.rfp_post_date}
                                                        onChange={this.handleChange}
                                                        showTimeSelect
                                                        timeFormat="HH:mm"
                                                        timeIntervals={15}
                                                        //dateFormat="MMMM d, yyyy h:mm aa"
                                                        dateFormat="MM/dd/YYYY h:mm aa"
                                                        timeCaption="time"
                                                        name="rfp_post_date"
                                                        className="form-control"
                                                        /> */}
                                  {/* <DatePicker selected={this.state.rfp_post_date} onChange={this.handleChange} dateFormat="MM/dd/yyyy" timeCaption="time" name="rfp_post_date" className="form-control" onChangeRaw={(e) => e.preventDefault()} /> */}
                                  {/* <i class="fa fa-calendar" aria-hidden="true" /> */}
                                  {this.state.rfp_post_date ? (
                                    <label className="active">Posted Date</label>
                                  ) : (
                                    <label>Posted Date</label>
                                  )}
                                  <DatePicker
                                    onChange={this.handleChange}
                                    value={this.state.rfp_post_date}
                                    name="rfp_post_date"
                                    className="form-control date-picker-react"
                                  />
                                </div>
                              </div>{" "}
                              {/* <span className="error" style={{color: 'red'}}>{this.state.errors["rfp_post_date"]}</span>  */}
                            </div>
                            <div className="form-field col-md-6">
                              <div className="md-form graph-field postdate-z-index">
                                <div class="input-field phoneblock">
                                  {/* <DatePicker
                                                            selected={this.state.due_dateandtime}
                                                            onChange={this.handleChange1}
                                                            dateFormat="MM/dd/YYYY"
                                                            timeCaption="time"
                                                            name="rfp_post_date"
                                                            className="form-control"
                                                        /> */}
                                  {/* <DatePicker selected={this.state.due_dateandtime} onChange={this.handleChange1} showTimeSelect timeIntervals={15} dateFormat="MM/dd/yyyy h:mm aa" timeCaption="time" name="due_dateandtime" className="form-control" onChangeRaw={(e) => e.preventDefault()} />
                                  <i class="fa fa-calendar" aria-hidden="true" /> */}
                                  {this.state.due_dateandtime ? (
                                    <label className="active">Due Date & Time</label>
                                  ) : (
                                    <label>Due Date & Time</label>
                                  )}
                                  <DatePicker
                                    onChange={this.handleChange1}
                                    value={this.state.due_dateandtime}
                                    name="due_dateandtime"
                                    className="form-control date-picker-react"
                                  />
                                </div>
                              </div>{" "}
                              {/* <span className="error" style={{color: 'red'}}>{this.state.errors["due_dateandtime"]}</span>  */}
                            </div>
                          </Row>
                          <Row>
                            <div className="form-field col-md-6">
                              <div className="md-form graph-field postdate-z-index">
                                <div class="input-field phoneblock">
                                  {/* <DatePicker selected={this.state.deadline_for_questions} onChange={this.handleChange2} dateFormat="MM/dd/yyyy" timeCaption="time" name="deadline_for_questions" className="form-control" onChangeRaw={(e) => e.preventDefault()} />
                                  <i class="fa fa-calendar" aria-hidden="true" /> */}
                                  {this.state.deadline_for_questions ? (
                                    <label className="active">Deadline For Questions</label>
                                  ) : (
                                    <label>Deadline For Questions</label>
                                  )}
                                  <DatePicker
                                    onChange={this.handleChange2}
                                    value={this.state.deadline_for_questions}
                                    name="deadline_for_questions"
                                    className="form-control date-picker-react"
                                  />
                                </div>
                              </div>{" "}
                              {/*<span className="error" style={{color: 'red'}}>{this.state.errors["deadline_for_questions"]}</span>  */}
                            </div>

                            <div className="form-field col-md-6">
                              <div class="file-field input-field fileupload fileupload-grid">
                                <div class="filebtn">
                                  <span>
                                    <i class="fa fa-upload" aria-hidden="true" />
                                    Select File
                                  </span>
                                  <input type="file" name="documents" onChange={this.handleInputFileChange} />
                                </div>

                                <div class="file-path-wrapper">
                                  <input class="file-path validate" type="text" />
                                </div>
                                <em>Please upload any reference document. (e.g. pdf or doc)</em>
                              </div>
                              <span className="error" style={{ color: "red" }}>
                                {this.state.errors["documents"]}
                              </span>
                            </div>
                          </Row>
                          <Row>
                            <div className="form-field col-md-12">
                              <MDBInput
                                type="textarea"
                                label="Description *"
                                rows="2"
                                name="description"
                                onChange={this.handleInputChange}
                              />
                              <span className="error" style={{ color: "red" }}>
                                {this.state.errors["description"]}
                              </span>
                            </div>
                          </Row>
                          <Row>
                            <div className="form-field col-md-12">
                              <h6>Would you like to give special consideration to either group below?</h6>
                            </div>
                          </Row>
                          <Row>
                            <div className="form-field col-md-3">
                              <div class="form-group form-check">
                                <input
                                  type="checkbox"
                                  id="radio1"
                                  name="minority_business"
                                  value={this.state.minority_business}
                                  checked={this.state.checked}
                                  onChange={this.handleCheckClickMinority}
                                />
                                <label for="radio1">Minority business</label>
                              </div>
                            </div>
                            <div className="form-field col-md-3">
                              <div class="form-group form-check">
                                <input
                                  type="checkbox"
                                  id="radio2"
                                  name="women_owned_business"
                                  value={this.state.women_owned_business}
                                  checked={this.state.checked2}
                                  onChange={this.handleCheckClickWomen}
                                />
                                <label for="radio2">Women owned business</label>
                              </div>
                            </div>
                          </Row>
                        </div>
                        <Row>
                          <div className="form-field col-md-12">
                            <div className="form-field col-md-12 p-0 cst-fcs form-tags tags-grid">
                              <div className="md-form">
                                <p>NAICS Codes *</p>
                                <Typeahead
                                  clearButton
                                  labelKey={(option) => `${option.NAICS_Description}` + ` (${option.NAICS})`}
                                  options={this.state.naiccodes}
                                  name="naic_code"
                                  value={this.state.naiccodes}
                                  onChange={(selected) => {
                                    this.setState({ naic_code: selected });
                                  }}
                                  class="form-control"
                                  multiple
                                />
                                {/* {this.state.naic_code.length > 0 ? <label className="active">NAICS Codes *</label> : <label>NAICS Codes *</label>} */}
                                <span className="error" style={{ color: "red" }}>
                                  {this.state.errors["naic_code"]}
                                </span>
                              </div>
                            </div>
                          </div>
                        </Row>

                        <Row>
                          <div className="form-field col-md-12 form-field-grid">
                            <h6 className="col-md-6 float-left p-0">
                              Would you like the applying company to be certified?
                            </h6>
                            <div className="form-field col-md-6">
                              <div className="radio-block">
                                <div class="form-group radio-block form-check inline">
                                  <input
                                    type="radio"
                                    name="need_certifications"
                                    id="need_certifications_yes"
                                    value="Yes"
                                    checked={this.state.need_certifications == "Yes"}
                                    onChange={this.handleCheckClickCertification}
                                  />
                                  <label for="need_certifications_yes"> Yes &nbsp; &nbsp;</label>
                                </div>
                                <div class="form-group radio-block form-check inline">
                                  <input
                                    type="radio"
                                    name="need_certifications"
                                    id="need_certifications_no"
                                    value="No"
                                    checked={this.state.need_certifications == "No"}
                                    onChange={this.handleCheckClickCertification}
                                  />
                                  <label for="need_certifications_no"> No &nbsp; &nbsp;</label>
                                </div>
                              </div>
                            </div>
                            {checkboxItems}
                            <span className="error" style={{ color: "red" }}>
                              {this.state.errors["certify"]}
                            </span>
                          </div>
                        </Row>
                        <div className="form-block company-edit-form">
                          <h3>Contact Information</h3>
                          <Row>
                            <div className="form-field col-md-6">
                              <MDBInput
                                label="First Name *"
                                name="contact_first_name"
                                onChange={this.handleInputChange}
                              />
                              <span className="error" style={{ color: "red" }}>
                                {this.state.errors["contact_first_name"]}
                              </span>
                            </div>
                            <div className="form-field col-md-6">
                              <MDBInput
                                label="Last Name *"
                                name="contact_last_name"
                                onChange={this.handleInputChange}
                              />
                              <span className="error" style={{ color: "red" }}>
                                {this.state.errors["contact_last_name"]}
                              </span>
                            </div>
                          </Row>
                          <Row>
                            <div className="form-field col-md-6">
                              {/* <div className="md-form">   
                                                    <div className="input-field phoneblock">                                  <NumberFormat
                                                        format="(###) ###-####" mask=""
                                                        name="contact_phonenumber"
                                                        onChange={this.handleInputChange}
                                                        value={this.state.contact_phonenumber} class="form-control"/>
                                                        <label for="icon_telephone">Phone Number *</label>
                                                        </div> 
                                                        </div>  
                                                        <span className="error" style={{color: 'red'}}>{this.state.errors["contact_phonenumber"]}</span> */}

                              <MDBInput
                                label="Phone Number"
                                type="text"
                                name="contact_phonenumber"
                                value={this.state.contact_phonenumber}
                                onChange={this.handlePhoneChange}
                                maxlength="15"
                              />
                              {/* <span className="error" style={{color: 'red'}}>{this.state.errors["contact_phonenumber"]}</span>  */}
                            </div>
                            <div className="form-field col-md-6">
                              <MDBInput label="Email" name="contact_email" onChange={this.handleInputChange} />
                              {/* <span className="error" style={{color: 'red'}}>{this.state.errors["contact_email"]}</span> */}
                            </div>
                          </Row>
                        </div>
                      </div>

                      <span style={this.state.loading && isFile ? {} : { display: "none" }}>
                        <div class="alert alert-warning" role="alert">
                          <strong>
                            Posting opportunity will take a while depending on size of reference document.
                          </strong>
                        </div>
                      </span>

                      <span className="fleft" style={{ color: "green" }}>
                        {this.state.successerrMsg}
                      </span>
                      <span className="error fleft" style={{ color: "red" }}>
                        {this.state.errMsg}
                      </span>
                      <span class="btn btn-sm fright" onClick={this.handleSubmit} title="Post Opportunity">
                        Post Opportunity
                        <div
                          style={this.state.loading ? {} : { display: "none" }}
                          class="image-fill w-25 loader-login-div postrfp_load"
                        >
                          <img src={Loading} alt="No Image" className="can-click " />
                        </div>
                      </span>
                    </form>
                  </Col>
                </Row>
                {/* <Row className="justify-content-center">
                        
                            <Col md="10"><span className="fleft" style={{color: 'green'}}>{this.state.successerrMsg}</span>
                                <span className="error fleft" style={{color: 'red'}}>{this.state.errMsg}</span><span class="btn btn-sm fright" 
                                onClick={ this.handleSubmit }
                                 title="Post Opportunity">Post Opportunity<div style={this.state.loading ? {} : { display: 'none' }} class="image-fill w-25 loader-login-div "><img src={Loading} alt="No Image" className="can-click " /></div></span></Col>
                        </Row> */}
              </div>
            </Container>
          </div>
        </div>
      );
    } else {
      window.location.href = AppConstants.STAGRFP + "/";
    }
  }
}
postrfprfi.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  dashboard: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

//export default ChangepasswordPage;
export default connect(
  mapStateToProps,
  { getVendorProfile, dashboard }
)(withRouter(postrfprfi));
// (postrfprfi);
