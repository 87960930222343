import React, { Component } from "react";
import { Container, Row, Col, Button } from "reactstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import AppConstants from "../AppConstants";
import "react-day-picker/lib/style.css";
import moment from "moment";
import nologo from "./logo.jpg";
import { ReactTitle } from "react-meta-tags";
import BrowserHistory from "react-router/lib/browserHistory";
import { dashboard, handlevendordashboard } from "../actions/authentication";
class viewcompanydetails extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      info1: true,
      info2: false,
      info3: false,
      info4: false,
      showCertInfo: false,
      showSocialInfo: false,
      showContactInfo: false,
      showBasic: true,
      companydashboarddata: [],
      naiccodesdes: [],
      subindustries: [],
      subindustriesdata: [],
      company_industrey: "",
    };
    this.handleIndustryChange = this.handleIndustryChange.bind(this);
  }

  handleIndustryChange(company_industrey) {
    //console.log(event.target.value);
    if (company_industrey != "") {
      fetch(
        AppConstants.API + "/industries/getsubindustries/" + company_industrey
      )
        .then((response) => response.json())
        .then((data) => {
          console.log(" sub industries ", data.subindustriesdata);
          if (data.Status == "Success") {
            this.setState({
              subindustriesdata: data.subindustriesdata,
              company_industrey: company_industrey,
            });
          } else {
            this.setState({
              company_industrey: company_industrey,
            });
          }
        });
    } else {
      this.setState({
        company_industrey: company_industrey,
      });
    }
  }

  componentDidMount() {
    if (this.props.auth.user.type == "company") {
      this.props.dashboard("companies");
    } else {
      this.props.handlevendordashboard("vendorcompanies");
    }
    window.scrollTo(0, 0);
    //Get vendors details
    fetch(
      AppConstants.API +
        "/cutomers/viewcompanydetails/" +
        this.props.match.params.id
    )
      .then((response) => response.json())
      .then((data) => {
        // console.log(data.companydashboarddata.subindustries);
        if (data.Status == "Success") {
          var subcat = [];
          if (
            data.companydashboarddata.subindustries &&
            data.companydashboarddata.subindustries.length > 0
          ) {
            subcat = data.companydashboarddata.subindustries;
          }

          this.setState({
            id: this.props.match.params.id,
            company_name: data.companydashboarddata.company_name,
            company_email: data.companydashboarddata.company_email,
            company_logo: data.companydashboarddata.company_logo || nologo,
            company_address: data.companydashboarddata.company_address || "N/A",
            hiring_needs: data.companydashboarddata.hiring_needs || "N/A",
            company_product_needs: data.companydashboarddata.company_product_needs || "N/A",
            company_phoneno: data.companydashboarddata.company_phoneno || "N/A",
            company_primary_contact_firstname:
              data.companydashboarddata.company_primary_contact_firstname ||
              "N/A",

            company_primary_email:
              data.companydashboarddata.company_primary_email || "N/A",
            city: data.companydashboarddata.city || "N/A",
            state: data.companydashboarddata.state || "N/A",
            zipcode: data.companydashboarddata.zipcode || "N/A",
            company_primary_contact_phoneno:
              data.companydashboarddata.company_primary_contact_phoneno ||
              "N/A",
            industry: data.companydashboarddata.industry || "N/A",
            company: data.companydashboarddata.company || "N/A",
            naiccodesdes: data.companydashboarddata.naiccodesdes,
            company_website: data.companydashboarddata.company_website,

            company_primary_contact_lastname:
              data.companydashboarddata.company_primary_contact_lastname ||
              "N/A",
            company_primary_contact_middlename:
              data.companydashboarddata.company_primary_contact_middlename ||
              "N/A",
            company_primary_contact_title:
              data.companydashboarddata.company_primary_contact_title || "N/A",
            company_primary_cellno:
              data.companydashboarddata.company_primary_cellno || "N/A",
            company_primary_officeno:
              data.companydashboarddata.company_primary_officeno || "N/A",
            company_primary_department:
              data.companydashboarddata.company_primary_department || "N/A",
            company_secondary_contactno:
              data.companydashboarddata.company_secondary_contactno || "N/A",
            company_business_model:
              data.companydashboarddata.company_business_model || "N/A",
            subindustries: subcat,
            company_industrey: data.companydashboarddata.company_industrey,
          });
          this.handleIndustryChange(this.state.company_industrey);
        } else {
          this.setState({ companydashboarddata: "" });
        }
      });
  }
  render() {
    //var test = this.state.naiccodesdes;
    //var myVar2 = test.join(', ');
    let businessmodel;
    if (this.state.company_business_model == "Both") {
      businessmodel = "B2B & B2C";
    } else if (this.state.company_business_model == "B2B") {
      businessmodel = "B2B";
    } else if (this.state.company_business_model == "B2C") {
      businessmodel = "B2C";
    } else {
      businessmodel = "N/A";
    }

    if (localStorage.jwtToken != undefined) {
      return (
        <div>
          <ReactTitle title="Company Profile" />
          <div className="inner-banner"></div>
          <div className="inner-content rfpviewdetail">
            <Container>
              <div className="white-bg pad50">
                <Row className="justify-content-center">
                  <Col md="10">
                    {/* <h1 className="color-yellow">Company Profile</h1> */}
                    <div className="text-btn-div">
                      <h3 className="ex-title">Basic Information </h3>
                      <button
                        onClick={BrowserHistory.goBack}
                        className="btn btn-sm fright back-btn"
                      >
                        Back
                      </button>

                      <div>
                        <div className="col-md-12 avatar-main">
                          <div className="avatar-block">
                            <img src={this.state.company_logo} />
                          </div>
                        </div>

                        <div className="vendor-detail">
                          <Row className="marg">
                            <div className="form-field col-md-4">
                              <span>Company Name</span>
                            </div>
                            <div className="form-field  col-md-8">
                              <h6>{this.state.company_name}</h6>
                            </div>
                          </Row>
                          <Row className="marg">
                            <div className="form-field col-md-4">
                              <span>Email</span>
                            </div>
                            <div className="form-field  col-md-8">
                              <h6>{this.state.company_email}</h6>
                            </div>
                          </Row>
                          <Row className="marg">
                            <div className="form-field col-md-4">
                              <span>Company Type</span>
                            </div>
                            <div className="form-field  col-md-8">
                              <h6>{this.state.company}</h6>
                            </div>
                          </Row>
                          <Row className="marg">
                            <div className="form-field col-md-4">
                              <span>Industry</span>
                            </div>
                            <div className="form-field  col-md-8">
                              <h6>{this.state.industry}</h6>
                            </div>
                          </Row>
                          <Row className="marg">
                            <div className="form-field col-md-4">
                              <span>Sub Industry</span>
                            </div>
                            <div className="form-field  col-md-8">
                              <ul
                                className="recipe-list recipe-list-view-list"
                                style={{ listStyle: "none", padding: "0" }}
                              >
                                {this.state.subindustries != undefined &&
                                  this.state.subindustries.length > 0 &&
                                  this.state.subindustries.map((recipe) => {
                                    if (this.state.subindustries.length > 0) {
                                      if (
                                        this.state.subindustriesdata &&
                                        this.state.subindustriesdata.length > 0
                                      ) {
                                        return (
                                          <React.Fragment>
                                            {this.state.subindustriesdata.map(
                                              (value, index) => {
                                                return (
                                                  <React.Fragment>
                                                    {value.subcatid ==
                                                    recipe ? (
                                                      <li>
                                                        {value.subindustryname}
                                                      </li>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </React.Fragment>
                                                );
                                              }
                                            )}
                                          </React.Fragment>
                                        );
                                      }
                                    } else {
                                      return (
                                        <h6 style={{ color: "#ced0d4" }}>
                                          No Sub Industries
                                        </h6>
                                      );
                                    }
                                  })}
                                {this.state.subindustries &&
                                  this.state.subindustries.length == 0 && (
                                    <h6 style={{ color: "#ced0d4" }}>
                                      No Sub Industries
                                    </h6>
                                  )}
                              </ul>
                            </div>
                          </Row>
                          <Row className="marg">
                            <div className="form-field col-md-4">
                              <span>Address</span>
                            </div>
                            <div className="form-field  col-md-8">
                              <h6>{this.state.company_address}</h6>
                            </div>
                          </Row>
                          <Row className="marg">
                            <div className="form-field col-md-4">
                              <span>City</span>
                            </div>
                            <div className="form-field  col-md-8">
                              <h6>{this.state.city}</h6>
                            </div>
                          </Row>
                          <Row className="marg">
                            <div className="form-field col-md-4">
                              <span>State</span>
                            </div>
                            <div className="form-field  col-md-8">
                              <h6>{this.state.state}</h6>
                            </div>
                          </Row>
                          <Row className="marg">
                            <div className="form-field col-md-4">
                              <span>Zip Code</span>
                            </div>
                            <div className="form-field  col-md-8">
                              <h6>{this.state.zipcode}</h6>
                            </div>
                          </Row>
                          <Row className="marg">
                            <div className="form-field col-md-4">
                              <span>Hiring Needs</span>
                            </div>
                            <div className="form-field  col-md-8">
                              <h6>{this.state.hiring_needs}</h6>
                            </div>
                          </Row>
                          <Row className="marg">
                            <div className="form-field col-md-4">
                              <span>Product Needs</span>
                            </div>
                            <div className="form-field  col-md-8">
                              <h6>{this.state.company_product_needs}</h6>
                            </div>
                          </Row>
                          <Row className="marg">
                            <div className="form-field col-md-4">
                              <span>Department</span>
                            </div>
                            <div className="form-field  col-md-8">
                              <h6>{this.state.company_primary_department}</h6>
                            </div>
                          </Row>
                          <Row className="marg">
                            <div className="form-field col-md-4">
                              <span>Website</span>
                            </div>
                            <div className="form-field  col-md-8">
                              <h6>
                                {this.state.company_website ? (
                                  <div>
                                    <a
                                      href={this.state.company_website}
                                      target="_blank"
                                    >
                                      {this.state.company_website}
                                    </a>
                                  </div>
                                ) : (
                                  <h6>N/A</h6>
                                )}
                              </h6>
                            </div>
                          </Row>
                          <Row>
                            <div className="form-field col-md-4">
                              <span>Company Business Model</span>
                            </div>
                            <div className="form-field  col-md-8">
                              <h6>{businessmodel}</h6>
                            </div>
                          </Row>
                          <Row className="marg">
                            <div className="form-field col-md-4">
                              <span>NAICS Codes</span>
                            </div>
                            <div className="form-field  col-md-8">
                              <ul
                                className="recipe-list recipe-list-view-list"
                                style={{ listStyle: "none", padding: "0" }}
                              >
                                {this.state.naiccodesdes != "" &&
                                  this.state.naiccodesdes.map((recipe) => {
                                    return (
                                      <li>{recipe}</li>
                                      //<li>{recipe}<span>,</span></li>
                                    );
                                  })}
                                {/* {this.state.naiccodesdes.length == 0 && <li>No Naics codes</li>}  */}
                                {(this.state.naiccodesdes.length == 0 ||
                                  this.state.naiccodesdes == "") && (
                                  <span>No Naics codes</span>
                                )}
                              </ul>
                            </div>
                          </Row>
                        </div>
                        <div className="vendor-detail">
                          <h3 className="vi-title">Contact Information</h3>
                          <Row className="marg">
                            <div className="form-field col-md-4">
                              <span>First Name</span>
                            </div>
                            <div className="form-field  col-md-8">
                              <h6>
                                {this.state.company_primary_contact_firstname}
                              </h6>
                            </div>
                          </Row>
                          <Row className="marg">
                            <div className="form-field col-md-4">
                              <span>Middle Name</span>
                            </div>
                            <div className="form-field  col-md-8">
                              <h6>
                                {this.state.company_primary_contact_middlename}
                              </h6>
                            </div>
                          </Row>
                          <Row className="marg">
                            <div className="form-field  col-md-4">
                              <span>Last Name</span>
                            </div>
                            <div className="form-field  col-md-8">
                              <h6>
                                {this.state.company_primary_contact_lastname}
                              </h6>
                            </div>
                          </Row>
                          <Row className="marg">
                            <div className="form-field col-md-4">
                              <span>Email</span>
                            </div>
                            <div className="form-field   col-md-8">
                              <h6>{this.state.company_primary_email}</h6>
                            </div>
                          </Row>
                          <Row className="marg">
                            <div className="form-field  col-md-4">
                              <span>Title</span>
                            </div>
                            <div className="form-field  col-md-8">
                              <h6>
                                {this.state.company_primary_contact_title}
                              </h6>
                            </div>
                          </Row>
                          <Row className="marg">
                            <div className="form-field col-md-4">
                              <span>Phone Number</span>
                            </div>
                            <div className="form-field  col-md-8">
                              <h6>
                                {this.state.company_primary_contact_phoneno}
                              </h6>
                            </div>
                          </Row>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Container>
          </div>
        </div>
      );
    } else {
      window.location.href = AppConstants.STAGRFP + "/";
    }
  }
}
viewcompanydetails.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  dashboard: PropTypes.func.isRequired,
  handlevendordashboard: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { dashboard, handlevendordashboard })(
  viewcompanydetails
);
