import React, {Component} from "react";
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  Container,
  Row,
  Col,
} from "reactstrap";
import axios from "axios";
import AppConstants from "../AppConstants";

const items = [
  {
    src: require("../images/banner-img1.jpg"),
    heading: "Contrary to popular belief Lorem",
    content:
      "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.",
    // url:"http://localhost:3000/home"
  },
  {
    src: require("../images/banner-img2.jpg"),
    heading: "Contrary to popular belief Lorem",
    content:
      "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.",
    url: "http://localhost:3000/home",
  },
];

export default class Slider extends Component {
  constructor(props) {
    super(props);
    this.state = {activeIndex: 0, getBannerImgData: []};
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);
  }

  componentDidMount() {
    let config1 = {
      method: "get",
      maxBodyLength: Infinity,
      url: AppConstants.API + "/certifications/getAllBanners",
      headers: {},
    };
    axios
      .request(config1)
      .then((response) => {
        if (response.data.Status === "Success") {
          console.log(response.data)
          this.setState({getBannerImgData: response.data.certificationsdata});
        } else {
          this.setState({getBannerImgData: []});
        }
      })
      .catch((error) => {
        this.setState({getBannerImgData: []});
      });
  }

  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  next() {
    if (this.animating) return;
    const nextIndex =
      this.state.activeIndex === this.state.getBannerImgData.length - 1
        ? 0
        : this.state.activeIndex + 1;
    this.setState({activeIndex: nextIndex});
  }

  previous() {
    if (this.animating) return;
    const nextIndex =
      this.state.activeIndex === 0
        ? this.state.getBannerImgData.length - 1
        : this.state.activeIndex - 1;
    this.setState({activeIndex: nextIndex});
  }

  goToIndex(newIndex) {
    if (this.animating) return;
    this.setState({activeIndex: newIndex});
  }

  render() {
    const {activeIndex} = this.state;

    const slides = this.state.getBannerImgData.map((item) => {
      return (
        <CarouselItem
          onExiting={this.onExiting}
          onExited={this.onExited}
          key={item.bannerImage}
          className="main-banner-bg"
        >
          {/* <div className="banner-bg"></div> */}
          <img src={item.bannerImage} alt={item.title} />

          <div className="banner-content">
            <Container>
              <Row>
                <Col md="12" className="main-banner-cont">
                  <h2>{item.title}</h2>
                  <p dangerouslySetInnerHTML={{__html: item.description}} />
                </Col>
              </Row>
            </Container>
          </div>
        </CarouselItem>
      );
    });

    return (
      <Carousel
        activeIndex={activeIndex}
        next={this.next}
        previous={this.previous}
      >
        <CarouselIndicators
          items={this.state.getBannerImgData}
          activeIndex={activeIndex}
          onClickHandler={this.goToIndex}
        />
        {slides}
        <CarouselControl
          direction="prev"
          directionText="Previous"
          onClickHandler={this.previous}
        />
        <CarouselControl
          direction="next"
          directionText="Next"
          onClickHandler={this.next}
        />
      </Carousel>
    );
  }
}
