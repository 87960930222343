import React, { Component } from "react";
import Slider from "../components/Slider";
import { Container, Row, Col } from "reactstrap";
import AppConstants from "../AppConstants";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { logoutUser, getProfile, getVendorProfile } from "../actions/authentication";
import AboutImg from "../images/about-sec-img.png";
import DotsImg from "../images/dots.png";
import PartnerLogo from "../images/partner-logo.png";
import Login from "./login";
import Forgotpassword from "./forgotpassword";
import Register from "./register";
import changevendorpassword from "./changevendorpassword";
import ReactPlayer from "react-player";
import LogoSlider from "react-slick";
//import Cookies from 'universal-cookie';
import axios from "axios";
import { Carousel, CarouselItem, CarouselControl, CarouselIndicators, CarouselCaption } from "reactstrap";
//const cookies = new Cookies();

//export default class  home extends Component{
class home extends React.Component {
  constructor(props) {
    super(props);
    //console.log(props);

    this.state = {
      images: [
        // 'https://bidlink-buyingdiverse.s3.amazonaws.com/badges/1692625192763.jpg',
        // 'https://bidlink-buyingdiverse.s3.amazonaws.com/badges/1692625228909.jpg',
        // 'https://bidlink-buyingdiverse.s3.amazonaws.com/badges/1692625228909.jpg',
        // 'https://bidlink-buyingdiverse.s3.amazonaws.com/badges/1692625192763.jpg'
      ],
      vendors_count: 0,
      customers_count: 0,
      companies_count: 0,
      data: 0,
      title: "",
      description: "",
      howitworkstitle: "",
      howitworksdescription: "",
      companytitle: "",
      companydescription: "",
      vendortitle: "",
      vendordescription: "",
      partnertitle: "",
      partnerdescription: "",
      companyvideo: "",
      getWorksData: [],
      aboutImg: "",
      getAllPartnerImages: [],
      activeIndex: 0,
    };
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);
  }
  goToPreviousImage = () => {
    this.setState((prevState) => ({
      currentImageIndex: Math.max(0, prevState.currentImageIndex - 1),
    }));
  };

  goToNextImage = () => {
    this.setState((prevState) => ({
      currentImageIndex: Math.min(prevState.images.length - 1, prevState.currentImageIndex + 1),
    }));
  };
  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  next() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === this.state.getWorksData.length - 1 ? 0 : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  }

  previous() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === 0 ? this.state.getWorksData.length - 1 : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }

  goToIndex(newIndex) {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  }
  componentDidMount() {
    if (this.props.auth.isAuthenticated == false) {
      this.props.history.push(AppConstants.STAGRFP + "/");
    }

    // this is needed, because InfiniteCalendar forces window scroll
    window.scrollTo(0, 0);
    fetch(AppConstants.API + "/reports/getalldashboardreports")
      .then((response) => response.json())
      .then((data) => {
        // console.log("vendors data ", data.vendors_count);
        if (data.Status == "Success") {
          this.setState({
            vendors_count: data.vendors_count,
            customers_count: data.customers_count,
            companies_count: data.companies_count,
            rfprfi_count: data.rfprfi_count,
            //data: data
          });
        }
      });

    //Comapny
    fetch(AppConstants.API + "/cms/aboutus/5d52ca27d906933546828c7b")
      .then((response) => response.json())
      .then((data) => {
        //console.log("About us ", data.aboutus.text());
        //console.log(data);
        if (data.Status == "Success") {
          this.setState({
            companytitle: data.aboutus.title,
            companydescription: data.aboutus.description,
          });
        }
      });

    //Vendor
    fetch(AppConstants.API + "/cms/aboutus/5d52ca56d906933546828c7c")
      .then((response) => response.json())
      .then((data) => {
        //console.log("About us ", data.aboutus.text());
        //console.log(data);
        if (data.Status == "Success") {
          this.setState({
            vendortitle: data.aboutus.title,
            vendordescription: data.aboutus.description,
          });
        }
      });

    //Partners
    fetch(AppConstants.API + "/cms/aboutus/64ddc37d817edee859be71cf")
      .then((response) => response.json())
      .then((data) => {
        if (data.Status == "Success") {
          this.setState({
            partnertitle: data.aboutus.title,
            partnerdescription: data.aboutus.description,
          });
        }
      });

    //How it works
    fetch(AppConstants.API + "/cms/howitworks/5cab606f44dd93553fa5f0ce")
      .then((response) => response.json())
      .then((data) => {
        //console.log("About us ", data.aboutus.text());
        if (data.Status == "Success") {
          this.setState({
            howitworkstitle: data.howitworks.title,
            howitworksdescription: data.howitworks.description,
          });
        }
      });

    //About
    fetch(AppConstants.API + "/cms/aboutus/64d9f7d1817edee859be71c9")
      .then((response) => response.json())
      .then((data) => {
        if (data.Status == "Success") {
          this.setState({
            title: data.aboutus.title,
            description: data.aboutus.description,
            aboutImg: data.aboutus.image || AboutImg,
          });
        }
      });

    if (
      localStorage.jwtToken != undefined &&
      this.props.auth.isAuthenticated == true &&
      this.props.auth.user.type == "vendor"
    ) {
      this.props.getVendorProfile(this.props.auth.user._id);
    }
    if (
      localStorage.jwtToken != undefined &&
      this.props.auth.isAuthenticated == true &&
      this.props.auth.user.type == "company"
    ) {
      // console.log(this.props.auth);
      this.props.getProfile(this.props.auth.user._id);
    }

    let config = { method: "get", maxBodyLength: Infinity, // url: AppConstants.API + "/certifications/getAllHowItWorks",
      url: AppConstants.API + "/howitworks/getAllHowItWorksApi", headers: {} };
    axios
      .request(config)
      .then((response) => {
        if (response.data.Status === "Success") {
          // this.setState({ getWorksData: response.data.certificationsdata });
          this.setState({ getWorksData: response.data.latestHowItWorks });
        } else {
          this.setState({ getWorksData: [] });
        }
      })
      .catch((error) => {
        this.setState({ getWorksData: [] });
      });

    let config1 = {
      method: "get",
      maxBodyLength: Infinity,
      url: AppConstants.API + "/partnerImages/getAllPartnerImages",
      headers: {},
    };
    axios
      .request(config1)
      .then((response) => {
        console.log(response.data);
        if (response.data.Status === "Success") {
          this.setState({ getAllPartnerImages: response.data.partnersImgs });
        } else {
          this.setState({ getAllPartnerImages: [] });
        }
      })
      .catch((error) => {
        this.setState({ getAllPartnerImages: [] });
      });
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.auth.isAuthenticated == true &&
      nextProps.auth.user._id != "" &&
      nextProps.auth.user.type == "vendor"
    ) {
      //console.log(nextProps.auth);
      //this.props.history.push('/dashboard');
    }
    if (
      nextProps.auth.isAuthenticated == true &&
      nextProps.auth.user._id != "" &&
      nextProps.auth.user.type == "company"
    ) {
      //console.log(nextProps.auth);
      //this.props.history.push('/companydashboard');
    }
  }
  render() {
    const articleContent = this.state.description;
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
      ],
    };
    const { activeIndex } = this.state;

    const slides = this.state.getWorksData.map((item) => {
      return <CarouselItem onExiting={this.onExiting} onExited={this.onExited} key={item._id} className="main-banner-bg pt-3">
          <div className="video-block col-md-12">
            <div className="video-block-in">
              <aside>
                <div className="embed-responsive embed-responsive-16by9">
                  <ReactPlayer url={item.socialLink} light={true} playing controls={true} allowfullscreen />
                </div>
              </aside>
              <div className="video-cont">
                <h2>{item.title}</h2>
                <p>{item.description}</p>
              </div>
            </div>
          </div>
        </CarouselItem>;
    });

    // console.log("how it works length",this.state.getWorksData.length);
    return <React.Fragment>
        <div id="home">{<Slider />}</div>
        <section id="about">
          <div className="about-block m-120" id="about-sec">
            <Container>
              <Row style={{ alignItems: "center" }}>
                <Col lg="6" className="about-block-left">
                  <div className="about-images">
                    <div className="about-img">
                      <figure>
                        <img src={this.state.aboutImg} alt="" />
                      </figure>
                      <figure className="dots-img">
                        <img src={DotsImg} alt="" />
                      </figure>
                    </div>
                  </div>
                </Col>
                <Col lg="6" className="about-block-right">
                  <h2>About buyingdiverse</h2>
                  <div dangerouslySetInnerHTML={{ __html: this.state.description }} />
                </Col>
              </Row>
            </Container>
          </div>
        </section>

        {/* HOW-IT-WORKS */}

        <section id="works">
          <section className="how-it-works">
            <div className="container">
              <h2 className="main-title">How It Works</h2>
              <Row className="d-flex justify-content-center">
                {/* {this.state.getWorksData && this.state.getWorksData.length > 0 ? this.state.getWorksData.map(
                      (item, index) => (
                        <div className="video-block col-md-6">
                          <div className="video-block-in">
                            <aside>
                              <div className="embed-responsive embed-responsive-16by9">
                                <ReactPlayer
                                  url={item.socialLink}
                                  light={true}
                                  playing
                                  controls={true}
                                  allowfullscreen
                                />
                              </div>
                            </aside>
                            <div className="video-cont">
                              <h2>{item.title}</h2>
                              <p>{item.description}</p>
                            </div>
                          </div>
                        </div>
                      )
                    ) : ""} */}
                <Carousel interval={0} activeIndex={activeIndex} next={this.next} previous={this.previous}>
                  <CarouselIndicators items={this.state.getWorksData} activeIndex={activeIndex} onClickHandler={this.goToIndex} />
                  {slides}
                  <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
                  <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
                </Carousel>
              </Row>
            </div>
          </section>
        </section>

        <section id="partners">
          <section className="partners-sec">
            <h2 className="main-title">supporting partners</h2>
            <Container>
              <p dangerouslySetInnerHTML={{ __html: this.state.partnerdescription }} />
              <ul />
            </Container>
          </section>
        </section>
      </React.Fragment>;
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth,
});
//export default Header;
export default connect(
  mapStateToProps,
  { getProfile, getVendorProfile, logoutUser }
)(withRouter(home));
