import React, { Component } from 'react';
import axios from 'axios';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import AppConstants from '../AppConstants';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { logoutUser, getProfile } from '../actions/authentication';
import nologo from './nologo.png';

//export default class  vendordetail extends Component{
class viewcustomerrfprfidetails extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      vendor_id: this.props.match.params.vendor_id,
      rfprfi_id: this.props.match.params.rfprfi_id,
      name_ofrfp: '',
      minority_business: false,
      women_owned_business: false,
      rfprfiprofilesdata: [],
      certifications: [],
      vendordashboarddata: [],
      customer_id: '',
      apply: '',
      errors: {},
      errMsg: '',
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(event) {
    //console.log(event.target.value);
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  /** Validations */
  handleValidation() {
    console.log(this.state.apply);
    let errors = {};
    let formIsValid = true;
    if (!this.state.apply) {
      formIsValid = false;
      errors['apply'] = 'Please select yes or no';
    }
    this.setState({ errors: errors });
    return formIsValid;
  }

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.handleValidation()) {
      if (this.state.apply == 'Yes') {
        const appliedrfp = {
          vendor_id: this.props.match.params.vendor_id,
          rfprfi_id: this.props.match.params.rfprfi_id,
          company_id: this.state.customer_id,
          //status:1
        };
        axios.post(AppConstants.API + '/rfprfiinterestes/interest', appliedrfp).then((res) => {
          if (res.data.Status == 'Success') {
            if (localStorage.jwtToken == undefined) {
              setTimeout((e) => {
                /* this.setState({                           
                                    errMsg:'',
                                }); */
                //this.props.history.push('/');
                window.location.href = AppConstants.STAGRFP + '/';
              }, 2000);
              this.setState({
                //errMsg: "you are won this Rfp/Rfi"
                errMsg: res.data.msg,
              });
            } else {
              setTimeout((e) => {
                this.setState({
                  errMsg: '',
                });
              }, 2000);
              window.location.href = AppConstants.STAGRFP + '/dashboard';
              this.setState({
                //errMsg: "you are won this Rfp/Rfi"
                errMsg: res.data.msg,
              });
            }
          } else {
            if (localStorage.jwtToken == undefined) {
              setTimeout((e) => {
                window.location.href = AppConstants.STAGRFP + '/';
              }, 2000);
              this.setState({
                errMsg: res.data.msg,
              });
            } else {
              this.setState({
                errMsg: res.data.msg,
              });
              window.location.href = AppConstants.STAGRFP + '/dashboard';
            }
          }
        });
      } else {
        if (localStorage.jwtToken == undefined) {
          setTimeout((e) => {
            window.location.href = AppConstants.STAGRFP + '/';
          }, 2000);
          this.setState({
            errMsg: 'Thank you',
          });
        } else {
          setTimeout((e) => {
            window.location.href = AppConstants.STAGRFP + '/dashboard';
          }, 2000);
          this.setState({
            errMsg: 'Thank you',
          });
        }
      }
    }
  };

  //    applyRfp = params => e => {
  //     e.preventDefault();
  //     {
  //         if(window.confirm("Are you sure ?"))
  //         {
  //             const appliedrfp = {
  //                 vendor_id:this.props.match.params.vendor_id,
  //                 rfprfi_id:this.props.match.params.rfprfi_id,
  //                 company_id:this.state.customer_id
  //                 //status:1
  //               }
  //              // console.log(appliedrfp);
  //             axios.post(AppConstants.API+'/rfprfiinterestes/interest',appliedrfp)
  //                 .then(res => {

  //                     if(res.data.Status == "Success"){

  //                     if(localStorage.jwtToken == undefined){
  //                         setTimeout((e) => {
  //                             /* this.setState({
  //                                 errMsg:'',
  //                             }); */
  //                             //this.props.history.push('/');
  //                             window.location.href='/'
  //                             }, 2000);
  //                             this.setState({
  //                                 //errMsg: "you are won this Rfp/Rfi"
  //                                 errMsg: res.data.msg
  //                             });
  //                         }
  //                         else
  //                         {
  //                             setTimeout((e) => {
  //                             this.setState({
  //                                 errMsg:'',
  //                             });
  //                             }, 2000);
  //                             window.location.href='/dashboard'
  //                             this.setState({
  //                                 //errMsg: "you are won this Rfp/Rfi"
  //                                 errMsg: res.data.msg
  //                             });
  //                         }
  //                     }
  //                     else
  //                     {
  //                         if(localStorage.jwtToken == undefined){
  //                         setTimeout((e) => {
  //                             /* this.setState({
  //                                 errMsg:'',
  //                             }); */
  //                             //this.props.history.push('/');
  //                             window.location.href='/'
  //                             }, 2000);
  //                             this.setState({
  //                                 //errMsg: "you are won this Rfp/Rfi"
  //                                 errMsg: res.data.msg
  //                             });
  //                         }else
  //                         {
  //                             this.setState({
  //                                 errMsg: res.data.msg
  //                             });
  //                             window.location.href='/dashboard'
  //                         }

  //                     }
  //                 });
  //         }
  //     }
  // }

  componentDidMount() {
    //Get Rfp Rfi profile data
    fetch(AppConstants.API + '/rfprfiprofiles/viewrfprfiprofile/' + this.props.match.params.rfprfi_id)
      .then((response) => response.json())
      .then((data) => {
        if (data.Status == 'Success') {
          this.setState({
            customer_id: data.rfprfiprofilesdata.customer_id,
            name_ofrfp: data.rfprfiprofilesdata.name_ofrfp,
            contact_first_name: data.rfprfiprofilesdata.contact_first_name,
            contact_last_name: data.rfprfiprofilesdata.contact_last_name,
            contact_phonenumber: data.rfprfiprofilesdata.contact_phonenumber,
            contact_email: data.rfprfiprofilesdata.contact_email,
            rfp_post_date: data.rfprfiprofilesdata.rfp_post_date,
            due_dateandtime: data.rfprfiprofilesdata.due_dateandtime,
            description: data.rfprfiprofilesdata.description,
            department: data.rfprfiprofilesdata.department,
            bid_link: data.rfprfiprofilesdata.bid_link,
            deadline_for_questions: data.rfprfiprofilesdata.deadline_for_questions,
            minority_business: data.rfprfiprofilesdata.minority_business,
            women_owned_business: data.rfprfiprofilesdata.women_owned_business,
            need_certifications: data.rfprfiprofilesdata.need_certifications,
            bid_rfp_reference_number: data.rfprfiprofilesdata.bid_rfp_reference_number,
            status: data.rfprfiprofilesdata.status,
            documents: data.rfprfiprofilesdata.documents,
            prev_document: data.rfprfiprofilesdata.documents,
            view_document: data.rfprfiprofilesdata.documents,
            naic_code: data.rfprfiprofilesdata.naiccodesdes,
            certifications: data.rfprfiprofilesdata.certifications,
          });
        } else {
          this.setState({ getfprfiprofiledata: '' });
        }
      });
    fetch(AppConstants.API + '/vendors/companyrfprfinaicrelatedvendors/' + this.props.match.params.id)
      .then((response) => response.json())
      .then((data) => {
        if (data.Status == 'Success') {
          this.setState({ vendordashboarddata: data.vendordashboarddata });
        } else {
          this.setState({ vendordashboarddata: [] });
        }
      });
  }

  render() {
    let minority = '';
    let business = '';
    //console.log(this.state.certifications);
    var test = this.state.certifications;
    var myVar2 = test.join(', ');
    if (this.state.minority_business == true) {
      minority = 'Yes';
    } else {
      minority = 'No';
    }
    if (this.state.women_owned_business == true) {
      business = 'Yes';
    } else {
      business = 'No';
    }

    //if(localStorage.jwtToken != undefined){
    return (
      <div>
        <div className="inner-banner" />
        <div className="inner-content">
          <Container>
            <div className="white-bg pad50">
              <Row className="justify-content-center">
                {/* <span className="error" style={{color: 'red'}}>{this.state.errMsg}</span>
                                   <Col md="3">
                                        <div className="dash-stats-inner applied-stat">
                                            <h6>Applied</h6>
                                            <div className="stat-icon" onClick={ this.applyRfp(this.props.match.params.vendor_id,this.props.match.params.rfprfi_id,this.state.customer_id) }  title="Apply RFPs/RFIs"></div>
                                        </div>
                                    </Col> */}
                <Col md="10">
                  <h1 className="color-yellow">RFP DETAILS</h1>
                  <div>
                    <div className="vendot-info-detail">
                      <div className="vendor-detail">
                        <Row>
                          <div className="form-field col-md-6">
                            <span>Name Of RFP/RFI</span>
                            <h6>{this.state.name_ofrfp}</h6>
                          </div>
                          <div className="form-field col-md-6">
                            <span>Email</span>
                            <h6>{this.state.contact_email}</h6>
                          </div>
                        </Row>
                        <Row>
                          <div className="form-field col-md-6">
                            <span>First Name </span>
                            <h6>{this.state.contact_first_name}</h6>
                          </div>
                          <div className="form-field col-md-6">
                            <span>Last Name </span>
                            <h6>{this.state.contact_last_name}</h6>
                          </div>
                        </Row>
                        <Row>
                          <div className="form-field col-md-6">
                            <span>Phone Number</span>
                            <h6>{this.state.contact_phonenumber}</h6>
                          </div>
                          <div className="form-field col-md-6">
                            <span>Bid/RFP reference number </span>
                            <h6>{this.state.bid_rfp_reference_number || 'N/A'}</h6>
                          </div>
                        </Row>
                        <Row>
                          <div className="form-field col-md-6">
                            <span>Posted Date</span>
                            <h6>{this.state.rfp_post_date}</h6>
                          </div>
                          <div className="form-field col-md-6">
                            <span>RFP/RFI Due Date</span>
                            <h6>{this.state.due_dateandtime || 'N/A'}</h6>
                          </div>
                        </Row>
                        <Row>
                          <div className="form-field col-md-6">
                            <span>Deadline for questions</span>
                            <h6>{this.state.deadline_for_questions || 'N/A'}</h6>
                          </div>
                        </Row>
                        <Row>
                          <div className="form-field col-md-6">
                            <span>Description </span>
                            <h6>{this.state.description}</h6>
                          </div>
                          <div className="form-field col-md-6">
                            <span>Department </span>
                            <h6>{this.state.department}</h6>
                          </div>
                        </Row>
                        <Row>
                          <div className="form-field col-md-6">
                            <span>Minority business </span>
                            <h6>{minority}</h6>
                          </div>
                          <div className="form-field col-md-6">
                            <span>Women owned business </span>
                            <h6>{business}</h6>
                          </div>
                        </Row>
                        <Row>
                          <div className="form-field col-md-12">
                            <span>Biz Link</span>
                            <h6>{this.state.bid_link}</h6>
                          </div>
                        </Row>
                        <Row>
                          <div className="form-field col-md-6">
                            <span>Document</span>
                            <h6>{this.state.documents || 'N/A'}</h6>
                          </div>
                        </Row>
                        <Row>
                          <div className="form-field col-md-12">
                            <span>Naic Code</span>
                            <h6>{this.state.naic_code}</h6>
                          </div>
                        </Row>

                        <Row>
                          <div className="form-field col-md-12">
                            <span>Certifications</span>
                            <h6>{myVar2}</h6>
                          </div>
                        </Row>

                        <Row>
                          <form name="vendorprofile" onSubmit={this.handleSubmit}>
                            <div className="form-field col-md-12">
                              <span>Did you applied for this RFP/RFI?</span> &nbsp; &nbsp;
                              <div class="form-group form-check inline">
                                <input
                                  type="radio"
                                  name="apply"
                                  id="apply_yes"
                                  value="Yes"
                                  onChange={this.handleInputChange}
                                />
                                <label for="apply_yes">Yes</label>
                              </div>{' '}
                              &nbsp; &nbsp;
                              <div class="form-group form-check inline">
                                <input
                                  type="radio"
                                  name="apply"
                                  id="apply_no"
                                  value="No"
                                  onChange={this.handleInputChange}
                                />
                                <label for="apply_no">No</label>
                              </div>
                            </div>
                            <span className="error" style={{ color: 'red' }}>
                              {this.state.errors['apply']}
                            </span>
                            <Col md="10">
                              <span class="btn btn-sm fright" onClick={this.handleSubmit}>
                                Submit
                              </span>
                            </Col>

                            <span className="error" style={{ color: 'red' }}>
                              {this.state.errMsg}
                            </span>
                          </form>
                        </Row>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </div>
    );
    /* } else
    {
        window.location.href = '/home'
    } */
  }
}
viewcustomerrfprfidetails.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(
  mapStateToProps,
  { logoutUser, getProfile }
)(withRouter(viewcustomerrfprfidetails));
