import React, { Component } from 'react';
import {Row} from 'reactstrap';
import {withRouter} from "react-router-dom";
import { MDBInput,MDBBtn } from 'mdbreact';
import axios from 'axios';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import AppConstants from '../AppConstants';
import { hashHistory } from 'react-router';

class companypassword extends React.Component  {
    constructor(props) {
      console.log(props);
        super(props);
        this.state = {            
            fields: {},
            errors: {},
            invalidUser:'',
            errMsg:'',
            successMsg:'',
        };
    }
    handleChange(field, e){    		
        let fields = this.state.fields;
        fields[field] = e.target.value;        
        this.setState({fields});
      }
    /** Validations */
  handleValidation(){
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    //Email
    if(!fields["password"]){
      formIsValid = false;
      errors["password"] = "Please enter password";
    }
    if(!fields["cnfpassword"]){
      formIsValid = false;
      errors["cnfpassword"] = "Please enter confirm password";
    }
    else if(fields["password"] != fields["cnfpassword"]){
      formIsValid = false;
      errors["cnfpassword"] = "Confirm password does not match";
    }
    this.setState({errors: errors});
    return formIsValid;
  }
  handleSubmit = event => {
    //console.log(process.env.REACT_APP_API_URL+'/users/login');
    event.preventDefault();
    if(this.handleValidation()){
      const setcompanypwd = {
        id:this.props.match.params.company_id,
        company_password: this.state.fields['password']
      }      
    axios.post(AppConstants.API+'/cutomers/customersetpassword',setcompanypwd)
        .then(res => {   
          //console.log(res);   
         if(res.data.Status == "Success"){
          setTimeout((e) => {           
            //this.props.history.push("/");
           // window.location.href='/';
           window.location.href = AppConstants.STAGRFP+'/';
          }, 1000);
          this.setState({
            successMsg: res.data.msg
          });
        }
        else{
          this.setState({
            errMsg: res.data.msg
          });
          setTimeout((e) => {
            this.setState({
              errMsg: ''
            });
           },5000);  
        } 
      }); 
    }
  };
    componentDidMount() {
      //console.log("set password page : "+this.props.company_id);
      //console.log("test ", this.props);
    }
    

    render() {
      return (        
        <div className="login-block" id='login-grid'>
        <div className='container'>           
         <div className="login-grid-in">
            <h3 className="color-yellow">Set Company Password</h3>
            <div className="form-block">
              <form name="setpasswordforcompany" onSubmit= {this.handleSubmit.bind(this)}>
              
                  <span className="error-inva" style={{color: 'green',position: 'relative',    top: '-10px'}}>{this.state.successMsg}</span>
                  <span className="error-inva" style={{color: 'red',position: 'relative',top: '-10px'}}>{this.state.errMsg}</span>
                  <Row>
                    <div className="form-field col-md-12">
                        <MDBInput type="password" name="password" onChange={this.handleChange.bind(this, "password")} value={this.state.fields["password"]}  placeHolder="Password *" />
                        <span className="error" style={{color: 'red'}}>{this.state.errors["password"]}</span>
                      </div>
                      <div className="form-field col-md-12">
                          <MDBInput type="password" name="cnfpassword" onChange={this.handleChange.bind(this, "cnfpassword")}   placeHolder="Confirm Password *"
                        value={this.state.fields["cnfpassword"]} />
                        <span className="error" style={{color: 'red'}}>{this.state.errors["cnfpassword"]}</span>
                      </div>
                      <div className="form-field col-md-12">
                          <MDBBtn type="submit" title="Set Company Password">Submit</MDBBtn>
                      </div>
                  </Row>
                    
              </form>                
              </div>    

              </div>  
          </div>
          </div>   
      );
    }
  }
  companypassword.propTypes = {
    //auth: PropTypes.object.isRequired,
   // errors: PropTypes.object.isRequired
  };
  const mapStateToProps = (state) => ({
   // auth: state.auth,
   // errors: state.errors
  });
  export  default connect(mapStateToProps, {})(companypassword);
  //export default withRouter(companypassword);
  