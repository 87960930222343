import React, { Component } from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import axios from 'axios';
import { MDBInput, MDBBtn } from 'mdbreact';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getVendorProfile, dashboard } from '../actions/authentication';
import AppConstants from '../AppConstants';
import NumberFormat from 'react-number-format';
import MomentLocaleUtils, { formatDate } from 'react-day-picker/moment';
import { Typeahead } from 'react-bootstrap-typeahead';
import moment from 'moment';
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import Loading from '../pages/loader.gif';
import { ReactTitle } from 'react-meta-tags';
import BrowserHistory from 'react-router/lib/browserHistory';

var duedate = new Date();
duedate.setDate(duedate.getDate() + 7);

var deadlinedate = new Date();
deadlinedate.setDate(deadlinedate.getDate() + 3);

var uncheckedids = [];
var certification_newids = [];
var isFile = '';
class postrfprfi extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      id: this.props.match.params.id,
      name_ofrfp: '',
      customer_id: this.props.auth.user._id,
      due_dateandtime: duedate,
      description: '',
      department: '',
      bid_link: '',
      naic_code: [],
      contact_first_name: '',
      contact_last_name: '',
      contact_phonenumber: '',
      contact_email: '',
      //"minority_or_women_owned_business": false,
      minority_business: false,
      women_owned_business: false,
      checked: false,
      checked2: false,
      need_certifications: 'No',
      checked1: false,
      certifications: [],
      certificationsdata: [],
      viewedrfprfp_vendorids: [],
      likedrfprfp_vendorids: [],
      interestedrfprfp_vendorids: [],
      documents: '',
      rfp_post_date: new Date(),
      deadline_for_questions: deadlinedate,
      bid_rfp_reference_number: '',
      department_min_revenue: '',
      department_max_revenue: '',
      status: 'Active',
      errors: {},
      errMsg: '',
      successerrMsg: '',
      naiccodes: [],
      customersdata: [],
      getfprfiprofiledata: [],
      sendmail: '',
      loading: '',
      certification_documents: [],
      multidocs: [],
      multidocids: [],
      certificationid: [],
      rfprfiid: this.props.match.params.id,
      certify: '',
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleCheckChange = this.handleCheckChange.bind(this);
    this.handleDayChange = this.handleDayChange.bind(this);
    this.handleDayChange1 = this.handleDayChange1.bind(this);
    this.handleDayChange2 = this.handleDayChange2.bind(this);
    this.handleInputFileChange = this.handleInputFileChange.bind(this);
    this.handleInputChangeFund = this.handleInputChangeFund.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChange1 = this.handleChange1.bind(this);
    this.handleChange2 = this.handleChange2.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handlePhoneChange = this.handlePhoneChange.bind(this);
  }

  handleClick(e) {
    if (e.target.checked) {
      certification_newids.push(e.target.value);
      document.getElementById('cert_' + e.target.value).style.display = 'block';
    } else {
      document.getElementById('cert_' + e.target.value).style.display = 'none';
    }
  }

  handleFileChange(e) {
    var newdata = e.target.id.split('_');
    certification_newids.push(newdata[1]);

    console.log(e.target.files[0]);
    this.setState({ [e.target.name]: e.target.files[0] });
    let formData = new FormData();

    formData.append('rfprfiid', this.state.rfprfiid);
    formData.append('certification_documents', e.target.files[0]);
    formData.append('certificationid', JSON.stringify(certification_newids));

    axios.post(AppConstants.API + '/rfprfiprofiles/addcertificate', formData).then((res) => {
      console.log(res);
      if (res.data.Status == 'certifiedSuccess') {
        this.setState({
          rfprfiid: res.data.rfprfiid,
        });
      }
    });
  }

  handleInputFileChange(e) {
    //console.log(e);
    isFile = 1;
    this.setState({
      [e.target.name]: e.target.files[0],
    });
  }

  handleChange(date) {
    this.setState({
      rfp_post_date: date,
    });
  }
  handleChange1(date) {
    this.setState({
      due_dateandtime: date,
    });
  }
  handleChange2(date) {
    this.setState({
      deadline_for_questions: date,
    });
  }
  //checkbox
  handleCheckClickMinority = (e) => {
    if (this.state.checked == false) {
      this.setState({
        checked: !this.state.checked,
        minority_business: !this.state.checked,
      });
    } else {
      this.setState({
        checked: !this.state.checked,
        minority_business: !this.state.checked,
      });
    }
  };
  //checkbox handleCheckClickWomen
  handleCheckClickWomen = (e) => {
    if (this.state.checked2 == false) {
      this.setState({
        checked2: !this.state.checked2,
        women_owned_business: !this.state.checked2,
      });
    } else {
      this.setState({
        checked2: !this.state.checked2,
        women_owned_business: !this.state.checked2,
      });
    }
  };
  handleInputChangeFund(e) {
    const certifications = this.state.certifications;
    let index;
    // check if the check box is checked or unchecked
    if (e.target.checked) {
      // add the numerical value of the checkbox to options array
      certifications.push(e.target.value);
      //console.log("certifications",certifications);
    } else {
      console.log(e.target.value);
      var newids = e.target.value;
      uncheckedids.push(newids);
      // or remove the value from the unchecked checkbox from the array
      index = certifications.indexOf(e.target.value);
      certifications.splice(index, 1);
    }
    // update the state with the new array of options
    this.setState({ certifications: certifications });
  }
  handleCheckClickCertification = (e) => {
    // console.log(this.state.checked1);
    // if(this.state.checked1 == false) {
    //     this.setState({
    //     checked1: !this.state.checked1,
    //     need_certifications:!this.state.checked1,
    //     //certifications:''
    //     });
    // }else{
    //     this.setState({
    //     checked1: !this.state.checked1,
    //     need_certifications:!this.state.checked1,
    //     certifications:[]
    //     });
    // }
    if (e.target.value == 'Yes') {
      this.setState({
        need_certifications: e.target.value,
      });
    } else {
      this.setState({
        need_certifications: e.target.value,
        certifications: [],
      });
    }
  };

  handleCheckChange(event) {
    //console.log(event.target);
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    //console.log("welcome ", name);
    this.setState({
      [name]: value,
    });
  }

  handleDayChange(day) {
    this.setState({ rfp_post_date: day });
  }
  handleDayChange1(day) {
    this.setState({ deadline_for_questions: day });
  }
  handleDayChange2(day) {
    this.setState({ due_dateandtime: day });
  }

  handleInputChange(e) {
    //console.log(this.state.revanuerange);
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handlePhoneChange(e) {
    //const re1 =/^[0-9)\(+ -]+$/g;
    const re1 = /^[0-9)\(+ '-]+$/g;
    // var phone_num = e.target.value.replace(/\D/g,'');
    if (e.target.value === '' || re1.test(e.target.value)) {
      // phone_num = this.phoneFormat(phone_num)
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  }

  phoneFormat(input) {
    // Strip all characters from the input except digits
    // Trim the remaining input to ten characters, to preserve phone number format
    input = input.substring(0, 10);
    // Based upon the length of the string, we add formatting as necessary
    var size = input.length;
    if (size == 0) {
      input = input;
    } else if (size < 4) {
      input = '(' + input;
    } else if (size < 7) {
      input = '(' + input.substring(0, 3) + ') ' + input.substring(3, 6);
    } else {
      input = '(' + input.substring(0, 3) + ') ' + input.substring(3, 6) + '-' + input.substring(6, 10);
    }
    return input;
  }

  /** Validations */
  handleValidation() {
    //console.log(this.state.due_dateandtime);
    let errors = {};
    let formIsValid = true;
    var date = new Date(this.state.rfp_post_date);
    date.setDate(date.getDate() + 6);
    if (this.state.need_certifications == 'Yes') {
      if (this.state.certifications.length == 0) {
        formIsValid = false;
        errors['certify'] = 'Please select atleast one certification';
      }
    }
    if (!this.state.name_ofrfp) {
      formIsValid = false;
      errors['name_ofrfp'] = 'Please enter name of opportunity';
    }
    //Email
    // if(!this.state.contact_email){
    //   formIsValid = false;
    //   errors["contact_email"] = "Please enter email";
    // }
    // if(typeof this.state.contact_email !== "undefined"){
    //   let lastAtPos = this.state.contact_email.lastIndexOf('@');
    //   let lastDotPos = this.state.contact_email.lastIndexOf('.');

    //   if (!(lastAtPos < lastDotPos && lastAtPos > 0 && this.state.contact_email.indexOf('@@') == -1 && lastDotPos > 2 && (this.state.contact_email.length - lastDotPos) > 2)) {
    //     formIsValid = false;
    //     errors["contact_email"] = "Please enter valid email";
    //   }
    // }
    if (!this.state.contact_first_name) {
      formIsValid = false;
      errors['contact_first_name'] = 'Please enter first name';
    }
    if (!this.state.contact_last_name) {
      formIsValid = false;
      errors['contact_last_name'] = 'Please enter last name';
    }
    //  if(!this.state.contact_phonenumber){
    //    formIsValid = false;
    //   errors["contact_phonenumber"] = "Please enter phone number";
    //  }
    //else if(this.state.contact_phonenumber.length !=14){
    //   formIsValid = false;
    //   errors["contact_phonenumber"] = "Please enter 10 digits phone number";
    // }
    if (!this.state.customer_id) {
      formIsValid = false;
      errors['customer_id'] = 'Please select company';
    }

    // if(new Date(this.state.rfp_post_date)>new Date(this.state.deadline_for_questions)){
    //   formIsValid = false;
    //   errors["deadline_for_questions"] = "Deadline for questions in between posted date and due date";
    // }
    // if(new Date(this.state.deadline_for_questions) >new Date(this.state.due_dateandtime)){
    //   formIsValid = false;
    //   errors["deadline_for_questions"] = "Deadline for questions  in between posted date and due date";
    // }
    // if(!this.state.rfp_post_date&&!this.state.due_dateandtime){
    //   formIsValid = false;
    //   errors["deadline_for_questions"] = "Please select posted date and due date";
    // }
    // if(this.state.rfp_post_date&&!this.state.due_dateandtime){
    //   formIsValid = false;
    //   errors["deadline_for_questions"] = "Please select due date";
    // }
    // if(!this.state.rfp_post_date&&this.state.due_dateandtime){
    //   formIsValid = false;
    //   errors["deadline_for_questions"] = "Please select posted date";
    // }
    // if(this.state.due_dateandtime<date){
    //   formIsValid = false;
    //   errors["due_dateandtime"] = "Due date must be 7 days greater than post date";
    // }
    if (!this.state.due_dateandtime) {
      formIsValid = false;
      errors['due_dateandtime'] = 'Please select due date';
    }
    if (!this.state.rfp_post_date) {
      formIsValid = false;
      errors['rfp_post_date'] = 'Please select posted date';
    }
    // if(!this.state.deadline_for_questions){
    //   formIsValid = false;
    //   errors["deadline_for_questions"] = "Please select deadline for questions";
    // }

    if (!this.state.description) {
      formIsValid = false;
      errors['description'] = 'Please enter description';
    }
    if (!this.state.department) {
      formIsValid = false;
      errors['department'] = 'Please enter department';
    }
    // if(!this.state.bid_link){
    //   formIsValid = false;
    //   errors["bid_link"] = "Please enter biz link";
    // }
    if (this.state.bid_link != '' && !/^https?:\/\//i.test(this.state.bid_link)) {
      formIsValid = false;
      errors['bid_link'] = 'Please enter valid biz link';
    }
    if (!this.state.naic_code.length) {
      formIsValid = false;
      errors['naic_code'] = 'Please select NAICS code';
    }

    if (this.state.documents && this.state.documents.name && !this.state.documents.name.match(/.(pdf|doc|docx)$/i)) {
      formIsValid = false;
      errors['documents'] = 'Please upload pdf or document';
    }
    this.setState({ errors: errors });
    return formIsValid;
  }

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.handleValidation()) {
      this.setState({ loading: 1 });
      let formData = new FormData();
      formData.append('id', this.props.match.params.id);
      formData.append('name_ofrfp', this.state.name_ofrfp);
      formData.append('customer_id', this.props.auth.user._id);
      formData.append('due_dateandtime', this.state.due_dateandtime);
      formData.append('description', this.state.description);
      formData.append('department', this.state.department);
      formData.append('bid_link', this.state.bid_link);
      //formData.append('naic_code', this.state.naic_code);
      formData.append('naic_code', JSON.stringify(this.state.naic_code));
      formData.append('contact_first_name', this.state.contact_first_name);
      formData.append('contact_last_name', this.state.contact_last_name);
      formData.append('contact_phonenumber', this.state.contact_phonenumber);
      formData.append('contact_email', this.state.contact_email);
      //formData.append('minority_or_women_owned_business', this.state.minority_or_women_owned_business);
      formData.append('minority_business', this.state.minority_business);
      formData.append('women_owned_business', this.state.women_owned_business);
      formData.append('certifications', JSON.stringify(this.state.certifications));
      formData.append('need_certifications', this.state.need_certifications);
      formData.append('prev_document', this.state.prev_document);
      formData.append('documents', this.state.documents);
      formData.append('rfp_post_date', this.state.rfp_post_date);
      formData.append('deadline_for_questions', this.state.deadline_for_questions);
      formData.append('bid_rfp_reference_number', this.state.bid_rfp_reference_number);
      formData.append('department_min_revenue', this.state.department_min_revenue);
      formData.append('department_max_revenue', this.state.department_max_revenue);
      formData.append('status', this.state.status);
      //formData.append('created_by_rfprfi_admin', this.state.created_by_rfprfi_admin);
      //formData.append('updated_by_rfprfi_admin', this.state.updated_by_rfprfi_admin);
      formData.append('sendmail', this.state.sendmail);
      formData.append('uncheckedids', JSON.stringify(uncheckedids));
      //console.log(updaterfprfidata);
      axios.post(AppConstants.API + '/rfprfiprofiles/editrfprfiprofile', formData).then((res) => {
        //console.log(res);
        if (res.data.Status == 'Success') {
          isFile = '';
          this.setState({ loading: '' });
          setTimeout((e) => {
            this.props.history.push(AppConstants.STAGRFP + "/opportunityinfo/" + this.props.match.params.id);
          }, 5000);
          this.setState({
            successerrMsg: res.data.msg,
          });
        } else {
          isFile = '';
          this.setState({ loading: '' });
          setTimeout(() => {
            this.setState({
              errMsg: false,
            });
          }, 2000);
          this.setState({
            errMsg: res.data.msg,
          });
        }
      });
    }
  };

  componentDidMount() {
    this.props.dashboard('dashboard');
    window.scrollTo(0, 0);
    //Get Rfp Rfi profile data
    fetch(AppConstants.API + '/rfprfiprofiles/getrfprfiprofiledetails/' + this.props.match.params.id)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.Status == 'Success') {
          var multidocs = [];
          var multidocids = [];
          if (data.getfprfiprofiledata.certification_documents.length > 0) {
            var allcerificationdata = data.getfprfiprofiledata.certification_documents;
            allcerificationdata.map((alldata) => {
              var idsObj = {};
              multidocids.push(alldata.certificationid);
              multidocs.push(alldata.certificationurl);
            });
            this.setState({
              multidocids: multidocids,
              multidocs: multidocs,
            });
          }

          this.setState({
            name_ofrfp: data.getfprfiprofiledata.name_ofrfp,
            contact_first_name: data.getfprfiprofiledata.contact_first_name,
            contact_last_name: data.getfprfiprofiledata.contact_last_name,
            contact_phonenumber: data.getfprfiprofiledata.contact_phonenumber,
            contact_email: data.getfprfiprofiledata.contact_email,
            rfp_post_date: new Date(data.getfprfiprofiledata.rfp_post_date),
            due_dateandtime: new Date(data.getfprfiprofiledata.due_dateandtime),
            description: data.getfprfiprofiledata.description,
            //customer_id: data.getfprfiprofiledata.customer_id,
            department: data.getfprfiprofiledata.department,
            bid_link: data.getfprfiprofiledata.bid_link,
            //naic_code: data.getfprfiprofiledata.naic_code,
            deadline_for_questions: new Date(data.getfprfiprofiledata.deadline_for_questions),
            //minority_or_women_owned_business: data.getfprfiprofiledata.minority_or_women_owned_business,

            minority_business: data.getfprfiprofiledata.minority_business,
            checked: data.getfprfiprofiledata.minority_business,

            women_owned_business: data.getfprfiprofiledata.women_owned_business,
            checked2: data.getfprfiprofiledata.women_owned_business,

            need_certifications: data.getfprfiprofiledata.need_certifications,

            checked1: data.getfprfiprofiledata.need_certifications,

            bid_rfp_reference_number: data.getfprfiprofiledata.bid_rfp_reference_number,
            status: data.getfprfiprofiledata.status,
            documents: data.getfprfiprofiledata.documents,
            prev_document: data.getfprfiprofiledata.documents,
            view_document: data.getfprfiprofiledata.documents,
            naic_code: data.getfprfiprofiledata.selected_naic_codes,
            certifications: data.getfprfiprofiledata.certification_ids,
            allcerificationdata: data.getfprfiprofiledata.certification_documents,
          });
        } else {
          this.setState({ getfprfiprofiledata: '' });
        }
      });
    //Get all customers
    fetch(AppConstants.API + '/cutomers/getallcutomers')
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        if (data.Status == 'Success') {
          this.setState({ customersdata: data.customersdata });
        } else {
          this.setState({ customersdata: '' });
        }
      });
    //Get naic codes
    fetch(AppConstants.API + '/users/getallnaiccodes')
      .then((response) => response.json())
      .then((data) => {
        //console.log(" naic codes ", data);
        if (data.Status == 'Success') {
          this.setState({ naiccodes: data.naiccodes });
        } else {
          this.setState({ naiccodes: '' });
        }
      });

    //Get Certification data
    fetch(AppConstants.API + '/certifications/getallcertifications')
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.Status == 'Success') {
          this.setState({ certificationsdata: data.certificationsdata });
        } else {
          this.setState({ certificationsdata: '' });
        }
      });
  }
  render() {
    var k = 1;
    const checkboxItems =
      this.state.need_certifications == 'Yes'
        ? this.state.certificationsdata.map((planet) => {
            return (
              <div class="form-group form-check">
                <input
                  type="checkbox"
                  name="certifications"
                  onChange={this.handleInputChangeFund}
                  checked={this.state.certifications.includes(planet._id) || ''}
                  value={planet._id}
                  id={planet._id}
                />
                <label for={planet._id}>{planet.certification_name}</label>
              </div>
            );
          })
        : null;

    // console.log(this.state.allcerificationdata);

    // const checkboxItems = this.state.checked1
    //   ? this.state.certificationsdata.map((planet, key) => {

    //     return (<div class="form-group form-check">
    //     <input type="checkbox" name="certifications" onChange={this.handleInputChangeFund} checked={this.state.certifications.includes(planet._id) || ''}  value={planet._id} id={planet._id} onClick={this.handleClick} /><label for={planet._id}>{planet.certification_name}</label>

    //     <span className="cert-doc" style={this.state.multidocids.includes(planet._id) ? {"display":"block"} : {"display":"none"}} id={`${'cert_'}${planet._id}`}>
    //     <div class = "file-field input-field fileupload">
    //         <div class = "filebtn">
    //           <span><i class="fa fa-upload" aria-hidden="true"></i></span>
    //           <input type = "file" name="certification_documents" onChange={ this.handleFileChange} id={`${'cert_'}${planet._id}`} />
    //         </div>
    //           <div class = "file-path-wrapper">
    //             <input class = "file-path validate" type = "text"
    //                 placeholder = "Please upload any certification document." />
    //           </div>
    //       </div>
    //       {this.state.allcerificationdata.map((value, index) => {
    //             return <div key={index}>
    //             {value.certificationid ==  planet._id? <span className="doc-icon"><a href={value.certificationurl} target="_blank"><i class="fa fa-file-pdf-o" aria-hidden="true"></i> Document {k++}</a></span> : ''}
    //             </div>
    //       })}
    //     </span>

    //     {/* {this.state.multidocids.includes(planet._id) ? <span><a href={this.state.multidocs[key]} target="_blank">{this.state.multidocs[key]}</a></span> : ''}  */}
    //     {/* <span>
    //     {planet._id == certification_documents.certificationid ? <span>{certification_documents.certificationurl}</span> : ''}</span> */}

    //     </div>);
    //     })
    //   : null;

    if (localStorage.jwtToken != undefined) {
      return (
        <div>
          <ReactTitle title="Edit Opportunity" />
          <div className="inner-banner" />
          <div className="inner-content rfpviewdetail">
            <Container>
              {/* <div>
                      <Row className="justify-content-center">
                        <Col md="8">
                          <button onClick={BrowserHistory.goBack} className="btn btn-sm fright back-btn" >Back</button>
                        </Col>
                      </Row>
                    </div> */}

              <div className="white-bg pad50 edit-sec">
                <Row className="justify-content-center text-btn-div">
                  <Col md="12" className="edit-sec-top">
                    <Row className="justify-content-center">
                      <Col md="8">
                        <h1 className="color-yellow sub-ex-title">Edit Opportunity</h1>
                        <h3>
                          Opportunity Basic Information
                          {/* <button onClick={BrowserHistory.goBack} className="btn btn-sm fright back-btn" >Back</button> */}
                        </h3>
                      </Col>
                      <Col md="4">
                        {' '}
                        <button onClick={BrowserHistory.goBack} className="btn btn-sm fright back-btn">
                          Back
                        </button>
                      </Col>
                    </Row>
                  </Col>
                  <Col md="12" className="vendot-info-detail ">
                    <form name="vendorprofile" onSubmit={this.handleSubmit}>
                      {/* <h1 className="color-yellow">Edit RFP</h1> */}
                      <div>
                        <div className="form-block">
                          <div className="form-block">
                            <Row>
                              <div className="form-field col-md-6">
                                <MDBInput
                                  label="Name Of Opportunity *"
                                  name="name_ofrfp"
                                  onChange={this.handleInputChange}
                                  value={this.state.name_ofrfp}
                                />
                                <span className="error" style={{ color: 'red' }}>
                                  {this.state.errors['name_ofrfp']}
                                </span>
                              </div>
                              <div className="form-field col-md-6">
                                <MDBInput
                                  label="Department *"
                                  name="department"
                                  onChange={this.handleInputChange}
                                  value={this.state.department}
                                />
                                <span className="error" style={{ color: 'red' }}>
                                  {this.state.errors['department']}
                                </span>
                              </div>
                            </Row>
                            <Row>
                              <div className="form-field col-md-6">
                                <MDBInput
                                  label="Biz link"
                                  name="bid_link"
                                  onChange={this.handleInputChange}
                                  value={this.state.bid_link}
                                />
                                <span className="error" style={{ color: 'red' }}>
                                  {this.state.errors['bid_link']}
                                </span>
                              </div>
                              <div className="form-field col-md-6">
                                <MDBInput
                                  label="Reference Number"
                                  name="bid_rfp_reference_number"
                                  onChange={this.handleInputChange}
                                  value={this.state.bid_rfp_reference_number}
                                />
                                {/* <MDBInput label="Biz link *"  name="bid_link" onChange={ this.handleInputChange } value={this.state.bid_link}/>
                                                        <span className="error" style={{color: 'red'}}>{this.state.errors["bid_link"]}</span>
                                                        </div>
                                                        <div className="form-field col-md-6 form-tags">
                                                    <div className="md-form">
                                                    <Typeahead
                                                        clearButton
                                                        labelKey={option => `${option.NAICS_Description}`+` (${option.NAICS})`}
                                                        options={this.state.naiccodes}
                                                        name="naic_code"
                                                        value={this.state.naiccodes}
                                                        onChange={(selected) => {
                                                            this.setState({naic_code:selected})
                                                            }}  class="form-control" 
                                                        selected={this.state.naic_code}    
                                                    />
                                                    {this.state.naic_code.length > 0 ? <label className="active">NAICS Codes *</label> : <label>NAICS Codes *</label>}
                                                    </div>
                                                    
                                               <span className="error" style={{color: 'red'}}>{this.state.errors["naic_code"]}</span> */}
                              </div>
                            </Row>

                            <Row>
                              <div className="form-field col-md-6">
                                <div className="md-form graph-field postdate-z-index" style={{ 'z-index': '9' }}>
                                  <div class="input-field phoneblock">
                                    {/* <DatePicker
                                      selected={this.state.rfp_post_date}
                                      onChange={this.handleChange}
                                      dateFormat="MM/dd/yyyy"
                                      timeCaption="time"
                                      name="rfp_post_date"
                                      className="form-control"
                                      onChangeRaw={(e) => e.preventDefault()}
                                    />
                                    <i class="fa fa-calendar" aria-hidden="true" /> */}
                                    {this.state.rfp_post_date ? (
                                      <label className="active">Posted Date</label>
                                    ) : (
                                      <label>Posted Date</label>
                                    )}
                                    <DatePicker
                                      onChange={this.handleChange}
                                      value={this.state.rfp_post_date}
                                      name="rfp_post_date"
                                      className="form-control date-picker-react"
                                    />
                                  </div>
                                </div>
                                {/* <span className="error" style={{color: 'red'}}>{this.state.errors["rfp_post_date"]}</span>  */}
                              </div>
                              <div className="form-field col-md-6">
                                <div className="md-form graph-field postdate-z-index">
                                  <div class="input-field phoneblock">
                                    {/* <DatePicker selected={this.state.due_dateandtime} onChange={this.handleChange1} showTimeSelect timeIntervals={15} dateFormat="MM/dd/yyyy h:mm aa" timeCaption="time" onChangeRaw={(e) => e.preventDefault()} name="rfp_post_date" className="form-control" />
                                    <i class="fa fa-calendar" aria-hidden="true" /> */}
                                    {this.state.due_dateandtime ? (
                                      <label className="active">Due Date & Time</label>
                                    ) : (
                                      <label>Due Date & Time</label>
                                    )}
                                    <DatePicker
                                      onChange={this.handleChange1}
                                      value={this.state.due_dateandtime}
                                      name="due_dateandtime"
                                      className="form-control date-picker-react"
                                    />
                                  </div>
                                </div>
                                {/* <span className="error" style={{color: 'red'}}>{this.state.errors["due_dateandtime"]}</span>  */}
                              </div>
                            </Row>
                            <Row>
                              <div className="form-field col-md-6">
                                <div style={{ position: 'relative' }}>
                                  <div className="md-form graph-field postdate-z-index">
                                    <div class="input-field phoneblock">
                                      {/* <DatePicker selected={this.state.deadline_for_questions} onChange={this.handleChange2} dateFormat="MM/dd/yyyy" timeCaption="time" name="rfp_post_date" className="form-control" onChangeRaw={(e) => e.preventDefault()} />
                                      <i class="fa fa-calendar" aria-hidden="true" /> */}
                                      {this.state.deadline_for_questions ? (
                                        <label className="active">Deadline for questions</label>
                                      ) : (
                                        <label>Deadline for questions</label>
                                      )}
                                      <DatePicker
                                        onChange={this.handleChange2}
                                        value={this.state.deadline_for_questions}
                                        name="deadline_for_questions"
                                        className="form-control date-picker-react"
                                      />
                                    </div>
                                  </div>
                                  {/* <span className="error" style={{color: 'red'}}>{this.state.errors["deadline_for_questions"]}</span>  */}
                                </div>
                              </div>
                              <div className="form-field col-md-6">
                                <div className="md-form">
                                  <div class="file-field input-field fileupload fileupload-grid">
                                    <div class="filebtn">
                                      <span>
                                        <i class="fa fa-upload" aria-hidden="true" />
                                        Select File
                                      </span>
                                      <input type="file" name="documents" onChange={this.handleInputFileChange} />
                                    </div>

                                    <div class="file-path-wrapper">
                                      <input class="file-path validate" type="text" placeholder="" />
                                    </div>
                                    <em>Please upload any reference document. (e.g. pdf or doc)</em>
                                  </div>
                                  <span className="error" style={{ color: 'red' }}>
                                    {this.state.errors['documents']}
                                  </span>

                                  {this.state.view_document ? (
                                    <div className="file-field input-field fileupload">
                                      <div className="filebtn">
                                        <a href={this.state.view_document} target="_blank">
                                          <i class="fa fa-download" aria-hidden="true" />
                                          Download Document
                                        </a>
                                      </div>
                                    </div>
                                  ) : (
                                    ''
                                  )}

                                  {/* <input type="file" name="documents" onChange={ this.handleInputFileChange } /><br/> */}
                                </div>
                              </div>
                            </Row>
                            <Row>
                              <div className="form-field col-md-12 marg-top">
                                <MDBInput
                                  type="textarea"
                                  label="Description *"
                                  rows="2"
                                  name="description"
                                  placeholder="Description"
                                  onChange={this.handleInputChange}
                                  value={this.state.description}
                                />

                                <span className="error" style={{ color: 'red' }}>
                                  {this.state.errors['description']}
                                </span>
                              </div>
                            </Row>

                            <Row className="check-box-area">
                              <div className="form-field col-md-12">
                                {' '}
                                <h6>Would you like to give special consideration to either group below?</h6>
                              </div>
                            </Row>
                            <Row className="check-box-area">
                              <div className="form-field col-md-3">
                                <div class="form-group form-check">
                                  <input
                                    type="checkbox"
                                    id="checkboxinline22"
                                    name="minority_business"
                                    value={this.state.minority_business}
                                    checked={this.state.checked}
                                    onChange={this.handleCheckClickMinority}
                                  />
                                  <label for="checkboxinline22">Minority business</label>
                                </div>
                              </div>
                              <div className="form-field col-md-3">
                                <div class="form-group form-check">
                                  <input
                                    type="checkbox"
                                    id="checkboxinline21"
                                    name="women_owned_business"
                                    value={this.state.women_owned_business}
                                    checked={this.state.checked2}
                                    onChange={this.handleCheckClickWomen}
                                  />
                                  <label for="checkboxinline21">Women owned business</label>
                                </div>
                              </div>
                            </Row>
                          </div>
                          <Row>
                            <div className="form-field col-md-12">
                              {/* <MDBInput label="Reference Number"  name="bid_rfp_reference_number" onChange={ this.handleInputChange } value={this.state.bid_rfp_reference_number}/> */}

                              <div className="form-field col-md-12 p-0 cst-fcs form-tags">
                                <div className="md-form">
                                  <p>NAICS Codes *</p>
                                  <div className="md-form">
                                    <Typeahead
                                      clearButton
                                      labelKey={(option) => `${option.NAICS_Description}` + ` (${option.NAICS})`}
                                      options={this.state.naiccodes}
                                      name="naic_code"
                                      value={this.state.naiccodes}
                                      onChange={(selected) => {
                                        this.setState({ naic_code: selected });
                                      }}
                                      class="form-control"
                                      selected={this.state.naic_code}
                                      multiple
                                    />
                                  </div>
                                  {/* {this.state.naic_code.length > 0 ? <label className="active">NAICS Codes *</label> : <label>NAICS Codes *</label>} */}
                                  <span className="error" style={{ color: 'red' }}>
                                    {this.state.errors['naic_code']}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </Row>
                          {/* <Row>
                                                    <div className="form-field col-md-12">
                                                    <div class="form-group form-check">
                                                            <input type="checkbox" id="checkboxinline2" name="need_certifications"
                                                            value={this.state.need_certifications}                      
                                                            checked={this.state.checked1} onChange={this.handleCheckClickCertification} />
                                                            <label for="checkboxinline2">What certifications would you like the applying company to have?</label>
                                                        </div>
                                                        {checkboxItems}
                                                        <span className="error" style={{color: 'red'}}>{this.state.errors["certify"]}</span>   
                                                    </div>
                                                </Row>                                                 */}
                          <Row className="list-view edit-profile-radio-block" id="certfied-block">
                            <div className="form-field col-md-12">
                              <h6 className="col-md-6 float-left p-0">
                                Would you like the applying company to be certified?
                              </h6>
                              <div className="form-field col-md-6 radio-block">
                                <div>
                                  <div class="form-group radio-block form-check inline">
                                    <input
                                      type="radio"
                                      name="need_certifications"
                                      id="need_certifications_yes"
                                      value="Yes"
                                      checked={this.state.need_certifications == 'Yes'}
                                      onChange={this.handleCheckClickCertification}
                                    />
                                    <label for="need_certifications_yes"> Yes &nbsp; &nbsp;</label>
                                  </div>
                                  <div class="form-group radio-block form-check inline">
                                    <input
                                      type="radio"
                                      name="need_certifications"
                                      id="need_certifications_no"
                                      value="No"
                                      checked={this.state.need_certifications == 'No'}
                                      onChange={this.handleCheckClickCertification}
                                    />
                                    <label for="need_certifications_no"> No &nbsp; &nbsp;</label>
                                  </div>
                                </div>
                              </div>
                              {checkboxItems}
                              <span className="error" style={{ color: 'red' }}>
                                {this.state.errors['certify']}
                              </span>
                            </div>
                          </Row>
                          <div className="form-block">
                            <h3>Contact Information</h3>
                            <Row>
                              <div className="form-field col-md-6">
                                <MDBInput
                                  label="First Name *"
                                  name="contact_first_name"
                                  onChange={this.handleInputChange}
                                  value={this.state.contact_first_name}
                                />
                                <span className="error" style={{ color: 'red' }}>
                                  {this.state.errors['contact_first_name']}
                                </span>
                              </div>
                              <div className="form-field col-md-6">
                                <MDBInput
                                  label="Last Name *"
                                  name="contact_last_name"
                                  onChange={this.handleInputChange}
                                  value={this.state.contact_last_name}
                                />
                                <span className="error" style={{ color: 'red' }}>
                                  {this.state.errors['contact_last_name']}
                                </span>
                              </div>
                            </Row>
                            <Row>
                              <div className="form-field col-md-6">
                                {/* <div className="md-form"> 
                                                    <div className="input-field phoneblock">
                                                    <NumberFormat
                                                        format="(###) ###-####" mask=""
                                                        name="contact_phonenumber"
                                                        onChange={this.handleInputChange}
                                                        value={this.state.contact_phonenumber} class="form-control" id="icon_telephone"/>
                                                        {this.state.contact_phonenumber ? <label className="active">Phone Number *</label> : <label>Phone Number *</label>}
                                                        </div> 
                                                        </div>
                                                        <span className="error" style={{color: 'red'}}>{this.state.errors["contact_phonenumber"]}</span> */}
                                <MDBInput
                                  label="Phone Number"
                                  type="text"
                                  name="contact_phonenumber"
                                  value={this.state.contact_phonenumber}
                                  onChange={this.handlePhoneChange}
                                  maxlength="15"
                                />
                                {/* <span className="error" style={{color: 'red'}}>{this.state.errors["contact_phonenumber"]}</span> */}
                              </div>
                              <div className="form-field col-md-6">
                                <MDBInput
                                  label="Email"
                                  name="contact_email"
                                  onChange={this.handleInputChange}
                                  value={this.state.contact_email}
                                />
                                {/* <span className="error" style={{color: 'red'}}>{this.state.errors["contact_email"]}</span> */}
                              </div>
                            </Row>
                            <Row>
                              <div className="form-field col-md-12">
                                <div class="form-group form-check">
                                  <input
                                    type="checkbox"
                                    id="checkboxinline23"
                                    name="sendmail"
                                    value={this.state.need_certifications}
                                    onChange={this.handleInputChange}
                                  />
                                  <label for="checkboxinline23">Send an email to the vendors after update</label>
                                </div>
                              </div>
                            </Row>
                          </div>
                        </div>
                      </div>

                      <span style={this.state.loading && isFile ? {} : { display: 'none' }}>
                        <div class="alert alert-warning" role="alert">
                          <strong>
                            Posting opportunity will take a while depending on size of reference document.
                          </strong>
                        </div>
                      </span>

                      <span className="fleft" style={{ color: 'green' }}>
                        {this.state.successerrMsg}
                      </span>
                      <span className="error fleft" style={{ color: 'red' }}>
                        {this.state.errMsg}
                      </span>
                      <span className="btn btn-sm fright" onClick={this.handleSubmit} title="Update Opportunity">
                        Update Opportunity
                        <div
                          style={this.state.loading ? {} : { display: 'none' }}
                          class="image-fill w-25 loader-login-div postrfp_load"
                        >
                          <img src={Loading} alt="No Image" className="can-click " />
                        </div>
                      </span>
                    </form>
                  </Col>
                </Row>
              </div>
            </Container>
          </div>
        </div>
      );
    } else {
      window.location.href = AppConstants.STAGRFP + '/';
    }
  }
}
postrfprfi.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  dashboard: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

//export default ChangepasswordPage;
export default connect(
  mapStateToProps,
  { getVendorProfile, dashboard }
)(postrfprfi);
