import React, { Component,Children } from 'react';
import Header from './Header';
import Footer from './Footer';
import home from "../pages/home";
import about from "../pages/about";
import services from "../pages/services";
import styleguide from "../pages/styleguide";
import setcustomerpassword from "../pages/setcustomerpassword";
import setvendorpassword from "../pages/setvendorpassword";
import customerotp from '../pages/customerotp';
import vendorotp from '../pages/vendorotp';
import companypassword from '../pages/companypassword';
import vendorpassword from '../pages/vendorpassword';
import dashboard from "../pages/dashboard";
import vendorinfo from "../pages/vendor-info";
import rfprfis from "../pages/rfprfis";
import rfplist from "../pages/rfplist";
import vendors from "../pages/vendors";
import companyvendors from "../pages/companyvendors";
import vendordetails from "../pages/viewvendordetails";
import companies from "../pages/companies";
import companydashboard from "../pages/companydashboard";
import postrfprfi from "../pages/postrfprfi";
import editrfprfi from "../pages/editrfprfi";
import companyinfo from "../pages/company-info";
import viewrfpinfo from "../pages/rfpviewdetails";
import viewcompanydetails from "../pages/viewcompanydetails";
import { Switch } from 'react-router';
import {render} from 'react-dom';
import viewcustomerrfprfidetails from '../pages/viewcustomerrfprfidetails';
import rfpwonlosedetails from "../pages/rfpwonlosedetails";
import AppConstants from '../AppConstants';

import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import UserLogin from '../pages/UserLogin';

function BasicExample() {
  return (
    <Router>
    <div>
       {/* <Route exact path="/" component={home} /> */}
       {/* <Route path="/home" component={home} /> */}
       {/* <Route path="/about" component={about} /> */}
       {/* <Route path="/services" component={services} /> */}
       <Route path={`${AppConstants.STAGRFP}${"/styleguide"}`} component={styleguide} />
       {/* <Route path={`${AppConstants.STAGRFP}${"/setcustomerpassword/:id"}`} component={setcustomerpassword} /> */}
       {/* <Route path={`${AppConstants.STAGRFP}${"/setvendorpassword/:id"}`} component={setvendorpassword} /> */}
       <Route path={`${AppConstants.STAGRFP}${"/customerotp/:company_id"}`} component={customerotp} />
       <Route path={`${AppConstants.STAGRFP}${"/vendorotp/:vendor_id"}`} component={vendorotp} />
       <Route path={`${AppConstants.STAGRFP}${"/companypassword/:company_id"}`} component={companypassword} />
       <Route path={`${AppConstants.STAGRFP}${"/vendorpassword/:vendor_id"}`} component={vendorpassword} />
       <Route path={`${AppConstants.STAGRFP}${"/userlogin"}`} component={UserLogin} />
       {/* <Route path="/dashboard" component={dashboard} /> */}
       {/* <Route path="/vendorinfo" component={vendorinfo} /> */}
       {/* <Route path="/rfprfis" component={rfprfis} /> */}
       {/* <Route path="/vendors" component={vendors} /> */}
      {/*  <Route path="/companies" component={companies} /> */}
       <Route path={`${AppConstants.STAGRFP}${"/rfplist"}`} component={rfplist} />
       {/* <Route path="/companydashboard" component={companydashboard} /> */}
       {/* <Route path="/vendorslist" component={companyvendors} /> */}
       {/* <Route path="/viewvendordetails/:id" component={vendordetails} /> */}
       {/* <Route path="/postrfprfi" component={postrfprfi} /> */}
       {/* <Route path="/editrfprfi/:id" component={editrfprfi} /> */}
       {/* <Route path="/companyinfo" component={companyinfo} /> */}
       {/* <Route path="/viewrfpinfo/:id" component={viewrfpinfo} /> */}
       {/* <Route path="/viewcompanydetails/:id" component={viewcompanydetails} /> */}
       {/* <Route exact path="/viewcustomerrfprfidetails/:vendor_id/:rfprfi_id" component={viewcustomerrfprfidetails} />
       <Route path="/rfpwonlosedetails/:vendor_id/:rfprfi_id" component={rfpwonlosedetails} /> */}
    </div>
  </Router>
  );
}
export default BasicExample;

   
