import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { MDBInput, MDBBtn } from 'mdbreact';
import axios from 'axios';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { Container, Row, Col, Collapse, Button, CardBody, Card } from 'reactstrap';
import AppConstants from '../AppConstants';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { logoutUser, getProfile, getVendorProfile, dashboard } from '../actions/authentication';
import nologo from './nologo.png';
import { ReactTitle } from 'react-meta-tags';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import moment from 'moment';
import PropTypes from 'prop-types';
import Loading from '../pages/loader.gif';
import { Typeahead } from 'react-bootstrap-typeahead';

//export default class  dashboard extends Component{
class companyvendors extends React.Component {
  constructor(props) {
    //console.log(props);
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      vendordashboarddata: [],
      errors: {},
      errMsg: false,
      favorite_vendors: false,
      checked: false,
      allfav: 0,
      msg: '',
      badge: '',
      loading: '',
      collapse: false,
      naiccodes: [],
      vendor_company_name: '',
      muslim_owned_business: 'No',
      memphis_chamber_mmember: 'No',
      industriesdata: [],
      industrytypes: [],
      naic_code: [],
      certificationsdata: [],
      certifications: [],
      searchloading: '',
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleBusinessModelChange = this.handleBusinessModelChange.bind(this);
    this.handleChamberModelChange = this.handleChamberModelChange.bind(this);
    this.handleInputChangeFund = this.handleInputChangeFund.bind(this);
  }

  handleInputChangeFund(e) {
    //console.log(this.state.certifications);
    const certifications = this.state.certifications;
    let index;
    // check if the check box is checked or unchecked
    if (e.target.checked) {
      // add the numerical value of the checkbox to options array
      certifications.push(e.target.value);
    } else {
      // or remove the value from the unchecked checkbox from the array
      index = certifications.indexOf(e.target.value);
      certifications.splice(index, 1);
    }
    //console.log(certifications);
    // update the state with the new array of options
    this.setState({ certifications: certifications });
  }

  handleInputChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  handleBusinessModelChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  handleChamberModelChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  toggle() {
    this.setState((state) => ({ collapse: !state.collapse }));
  }

  FavouriteVendor = (params) => (e) => {
    NotificationManager.success('Favorited', '', 500);

    e.preventDefault();
    const vendorfavdata = {
      vendor_id: params,
      company_id: this.props.auth.user._id,
    };
    axios.post(AppConstants.API + '/companyvendorfav/favourite', vendorfavdata).then((res) => {
      //console.log(res.data)
      if (res.data.Status == 'Success') {
        fetch(AppConstants.API + '/vendors/getallvendorsforvendors/' + this.props.auth.user._id)
          .then((response) => response.json())
          .then((data) => {
            //console.log(data);
            if (data.Status == 'Success') {
              this.setState({ vendordashboarddata: data.vendordashboarddata });
            } else {
              this.setState({ vendordashboarddata: [] });
            }
          });
      } else {
        console.log('no data');
        this.setState({ vendordashboarddata: [] });
      }
    });
  };
  UnFavouriteVendor = (params) => (e) => {
    e.preventDefault();
    const vendorfavdata = {
      vendor_id: params,
      company_id: this.props.auth.user._id,
    };
    axios.post(AppConstants.API + '/companyvendorfav/unfavourite', vendorfavdata).then((res) => {
      if (res.data.Status == 'Success') {
        fetch(AppConstants.API + '/vendors/getallvendorsforvendors/' + this.props.auth.user._id)
          .then((response) => response.json())
          .then((data) => {
            //console.log(data);
            if (data.Status == 'Success') {
              //console.log
              this.setState({ vendordashboarddata: data.vendordashboarddata, checked: false });
            } else {
              this.setState({ vendordashboarddata: [] });
            }
          });
      } else {
        console.log('no data');
        this.setState({ vendordashboarddata: [] });
      }
    });
  };

  componentDidMount() {
    this.props.dashboard('vendors');
    window.scrollTo(0, 0);
    // fetch(AppConstants.API+'/vendors/companyrfprfinaicrelatedvendors/'+this.props.auth.user._id).then(response => response.json())
    //.then(data => {
    fetch(AppConstants.API + '/vendors/getallvendorsforvendors/' + this.props.auth.user._id)
      .then((response) => response.json())
      .then((data) => {
        //console.log(data);
        if (data.Status == 'Success') {
          this.setState({ vendordashboarddata: data.vendordashboarddata });
        } else {
          this.setState({ vendordashboarddata: [] });
        }
      });

    //Get badge
    fetch(AppConstants.API + '/vendors/getgmcbadge')
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        if (data.Status == 'Success') {
          this.setState({ badge: data.badge });
        } else {
          this.setState({ badge: '', msg: data.Status });
        }
      });

    //Get naic codes
    fetch(AppConstants.API + '/users/getallnaiccodes')
      .then((response) => response.json())
      .then((data) => {
        //console.log(" naic codes ", data);
        if (data.Status == 'Success') {
          this.setState({ naiccodes: data.naiccodes });
        } else {
          this.setState({ naiccodes: '' });
        }
      });

    //Get Industries data
    fetch(AppConstants.API + '/industries/getallindustries')
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        if (data.Status == 'Success') {
          this.setState({ industriesdata: data.industriesdata });
        } else {
          this.setState({ industriesdata: '' });
        }
      });

    fetch(AppConstants.API + '/certifications/getallcertifications')
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        if (data.Status == 'Success') {
          this.setState({ certificationsdata: data.certificationsdata });
        } else {
          this.setState({ certificationsdata: '' });
        }
      });
  }

  //checkbox
  /* handlefav = (e) => {
    if(this.state.checked == false) {
        this.setState({
          checked: !this.state.checked,
          favorite_vendors:!this.state.checked
        });
      }else{
        this.setState({
          checked: !this.state.checked,
          favorite_vendors:!this.state.checked
        });
      }
    
  } */

  handleCheckClickCertification = (e) => {
    if (this.state.checked == false) {
      this.setState({ loading: 1 });
      this.setState({
        checked: !this.state.checked,
        favorite_vendors: !this.state.checked,
      });
    } else {
      this.setState({ loading: 1 });
      //console.log(this.state.favorite_vendors);
      this.setState({
        checked: !this.state.checked,
        favorite_vendors: !this.state.checked,
      });
    }
    //console.log(this.state.favorite_vendors);
    if (this.state.checked == true) {
      this.setState({ loading: 1 });
      //console.log('All fav');
      this.state.allfav = 0;
      this.getFavorites(this.state.allfav);
    } else {
      this.setState({ loading: 1 });
      this.state.allfav = 1;
      this.getFavorites(this.state.allfav);
    }
  };

  getFavorites(fav) {
    //console.log(fav);
    fetch(AppConstants.API + '/vendors/getallfavvendors/' + this.props.auth.user._id + '/' + fav)
      .then((response) => response.json())
      .then((data) => {
        //console.log(data);
        this.setState({ loading: '' });
        if (data.Status == 'Success') {
          this.setState({ vendordashboarddata: data.vendordashboarddata });
        } else {
          this.setState({ vendordashboarddata: [] });
        }
      });

    //Get badge
    fetch(AppConstants.API + '/vendors/getgmcbadge')
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.Status == 'Success') {
          this.setState({ badge: data.badge });
        } else {
          this.setState({ badge: '', msg: data.Status });
        }
      });
  }

  renderShowsTotal(start, to, total) {
    return (
      <p className="page-show">
        Showing rows {start} to {to} of {total}
      </p>
    );
  }
  actionBtn(cell, row) {
    if (row.favstatus == 1) {
      return (
        <div className="action-block">
          <Link
            to={`${AppConstants.STAGRFP}${'/viewvendordetails/'}${row._id}`}
            className="view-icon"
            title="View Vendor Details"
          >
            <i class="fa fa-eye" aria-hidden="true" />
          </Link>
          {/* <a href="" onClick={this.Action} className="btn btn-sm" title="View Vendor Details"><i class="fa fa-eye" aria-hidden="true"></i>View</a> &nbsp;  */}
          {/* <Link to={"/rfprfis"} className="btn btn-sm" title="Save Vendor"><i class="fa fa-heart" aria-hidden="true"></i>Favourite</Link> */}
          <a
            href="javascript:void(0);"
            onClick={this.UnFavouriteVendor(row._id)}
            className="view-icon unfav-icon"
            title="Favorite Vendor"
          >
            <i class="fa fa-heart" aria-hidden="true" />
          </a>
        </div>
      );
    } else {
      return (
        <div className="action-block">
          <Link
            to={`${AppConstants.STAGRFP}${'/viewvendordetails/'}${row._id}`}
            className="view-icon"
            title="View Vendor Details"
          >
            <i class="fa fa-eye" aria-hidden="true" />
          </Link>
          {/* <a href="" onClick={this.Action} className="btn btn-sm" title="View Vendor Details"><i class="fa fa-eye" aria-hidden="true"></i>View</a> &nbsp;  */}
          {/* <Link to={"/rfprfis"} className="btn btn-sm" title="Save Vendor"><i class="fa fa-heart" aria-hidden="true"></i>Favourite</Link> */}
          <a
            href="javascript:void(0);"
            onClick={this.FavouriteVendor(row._id)}
            className="view-icon fav-icon"
            title="Favorite Vendor"
          >
            <i class="fa fa-heart" aria-hidden="true" />
          </a>
          <NotificationContainer />
        </div>
      );
    }
  }
  logoInfo(cell, row) {
    var defauldbadge = 0;
    var defauldbadge1 = 0;
    var alldatadiff = moment(new Date()).diff(row.created_date, 'days');

    if (alldatadiff <= 7) {
      var gcm = '';
      var imdd = '';
      var chambermember = '<span class="new-bdg">New</span>' + row.vendor_company_name + '<div class="badges-icons">';

      {
        row.badges.map((recipe) => {
          if (recipe.alias_name == 'GMC') {
            defauldbadge = 1;
          }
          if (recipe.badge && recipe.badge != 'undefined' && recipe.badge != '')
            chambermember +=
              '<figure><img src="' +
              recipe.badge +
              '" alt="' +
              recipe.certification_name +
              '" title="' +
              recipe.certification_name +
              '" /></figure>';
        });
        chambermember += imdd;
      }
      if (row.memphis_chamber_member == 'Yes' && defauldbadge == 0) {
        gcm = this.state.badge;
        imdd =
          '<figure><img src="' + gcm + '" alt="Greater Memphis Chamber" title="Greater Memphis Chamber" /></figure>';
      } else {
        gcm = '';
      }
      chambermember += imdd;
      chambermember += '</div>';

      return <div className="vn-bad-icn-block" dangerouslySetInnerHTML={{ __html: chambermember }} />;
    } else {
      var gcm = '';
      var imdd = '';
      var chambermember = '<span></span>' + row.vendor_company_name + '<div class="badges-icons">';

      {
        row.badges.map((recipe) => {
          if (recipe.alias_name == 'GMC') {
            defauldbadge1 = 1;
          }
          if (recipe.badge && recipe.badge != 'undefined' && recipe.badge != '')
            chambermember +=
              '<figure><img src="' +
              recipe.badge +
              '" alt="' +
              recipe.certification_name +
              '" title="' +
              recipe.certification_name +
              '" /></figure>';
        });
        chambermember += imdd;
      }
      if (row.memphis_chamber_member == 'Yes' && defauldbadge1 == 0) {
        gcm = this.state.badge;
        imdd =
          '<figure><img src="' + gcm + '" alt="Greater Memphis Chamber" title="Greater Memphis Chamber" /></figure>';
      } else {
        gcm = '';
      }
      chambermember += imdd;
      chambermember += '</div>';
      return <div className="vn-bad-icn-block" dangerouslySetInnerHTML={{ __html: chambermember }} />;
    }
  }
  companyInfo(cell, row) {
    return (
      <div className="contact-info">
        {/* <b>Name: </b>{row.vendor_company_name} <br/>  */}
        <span className="location">
          {row.vendor_company_address}, <br />
          {row.state}, {row.city} {row.zipcode}{' '}
        </span>
        {row.vendor_company_phoneno && row.vendor_company_phoneno != '' ? (
          <span className="phone-no"> {row.vendor_company_phoneno}</span>
        ) : (
          ''
        )}
        {row.vendor_company_website && row.vendor_company_website != 'undefined' && row.vendor_company_website != '' ? (
          <span className="website">
            <a href={row.vendor_company_website} target="_blank">
              {row.vendor_company_website}
            </a>
          </span>
        ) : (
          ''
        )}
      </div>
    );
  }
  naicInfo(cell, row) {
    return (
      <div>
        {row.industry} <br />
        <b>NAICS Codes:</b>
        <br />
        <span className="naiccodes">
          <ul style={{ listStyle: 'none', padding: '0' }}>
            {row.naiccodesdes.map((recipe) => {
              return (
                <li>
                  {recipe}
                  <span>,</span>
                </li>
              );
            })}
          </ul>
          {row.naiccodesdes == '' && <span>No NAICS Codes</span>}
        </span>
      </div>
    );
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ searchloading: 1 });

    //console.log(this.state.allfav);
    let formData = new FormData();
    formData.append('vendor_company_name', this.state.vendor_company_name);
    formData.append('naic_code', JSON.stringify(this.state.naic_code));
    formData.append('certifications', JSON.stringify(this.state.certifications));
    formData.append('industrytypes', JSON.stringify(this.state.industrytypes));
    formData.append('minorityownedbusiness', this.state.muslim_owned_business);
    formData.append('chambermember', this.state.memphis_chamber_mmember);
    formData.append('fav', this.state.allfav);
    formData.append('vendor_id', this.props.auth.user._id);

    axios.post(AppConstants.API + '/vendors/advancesearchforvendors', formData).then((res) => {
      if (res.data.Status == 'Success') {
        this.setState({ searchloading: '', vendordashboarddata: res.data.vendorsdata });
        console.log(res.data.vendorsdata);
      } else {
        this.setState({ searchloading: '', vendordashboarddata: [] });
      }
    });
  };

  render() {
    const options = {
      page: 1, // which page you want to show as default
      sizePerPageList: [
        {
          text: '10',
          value: 10,
        },
        {
          text: '25',
          value: 25,
        },
        {
          text: 'All',
          value: this.state.vendordashboarddata.length,
        },
      ], // you can change the dropdown list for size per page
      sizePerPage: 10, // which size per page you want to locate as default
      pageStartIndex: 1, // where to start counting the pages
      paginationSize: 5, // the pagination bar size.
      prePage: '<', // Previous page button text
      nextPage: '>', // Next page button text
      firstPage: 'First', // First page button text
      lastPage: 'Last', // Last page button text
      paginationShowsTotal: this.renderShowsTotal, // Accept bool or function
      paginationPosition: 'bottom', // default is bottom, top and both is all available
    };

    if (localStorage.jwtToken != undefined) {
      const checkboxItems = this.state.certificationsdata.map((planet) => {
        return (
          <div class="form-group form-check inline cst-c-block">
            <input
              type="checkbox"
              name="checkboxItems"
              onChange={this.handleInputChangeFund}
              value={planet._id}
              id={planet._id}
            />
            <label for={planet._id} title={planet.certification_name}>
              {planet.alias_name}
            </label>
          </div>
        );
      });
      return (
        <div>
          <ReactTitle title="Vendors" />
          <div className="inner-banner" />
          <div className="inner-content">
            <Container>
              {/* <h1>Vendors List</h1> */}
              <div className="dashboard-rfp-list">
                <div class="advanced-search vendor-advance rfpviewdetail">
                  <a className="as-title" onClick={this.toggle} style={{ marginBottom: '1rem' }}>
                    Advanced Search
                  </a>
                  <form name="vendoradvancedsearch" onSubmit={this.handleSubmit}>
                    <Collapse isOpen={this.state.collapse}>
                      <Card className="vendot-info-detail">
                        <CardBody className="col-md-12 m-auto">
                          <h3 className="text-center">Advanced Search</h3>
                          <div className="form-field">
                            <MDBInput
                              label="Company Name"
                              className="adv-title"
                              name="vendor_company_name"
                              onChange={this.handleInputChange}
                              value={this.state.vendor_company_name}
                            />
                            <span className="error" style={{ color: 'red' }}>
                              {this.state.errors['vendor_company_name']}
                            </span>
                          </div>

                          <div className="form-field col-md-12 form-tags p-0">
                            <div className="md-form">
                              <p>Industry Type</p>
                              <Typeahead
                                clearButton
                                labelKey={(option) => `${option.industryname}`}
                                multiple
                                options={this.state.industriesdata}
                                name="industrytypes"
                                value={this.state.industriesdata}
                                onChange={(selected) => {
                                  this.setState({ industrytypes: selected });
                                }}
                                class="form-control"
                                selected={this.state.industrytypes}
                              />
                            </div>
                          </div>
                          <div className="form-field-grid">
                            <div className="form-field col-md-12 m-0 p-0">
                              <div className="form-field col-md-6">
                                <h6>Minority Owned Business</h6>
                              </div>
                              <div className="form-field col-md-6">
                                <div>
                                  <div class="form-group radio-block form-check inline">
                                    <input
                                      type="radio"
                                      name="muslim_owned_business"
                                      id="muslim_owned_business_yes"
                                      value="Yes"
                                      checked={this.state.muslim_owned_business == 'Yes'}
                                      onChange={this.handleBusinessModelChange}
                                    />
                                    <label for="muslim_owned_business_yes"> Yes &nbsp; &nbsp;</label>
                                  </div>
                                  <div class="form-group radio-block form-check inline">
                                    <input
                                      type="radio"
                                      name="muslim_owned_business"
                                      id="muslim_owned_business_no"
                                      value="No"
                                      checked={this.state.muslim_owned_business == 'No'}
                                      onChange={this.handleBusinessModelChange}
                                    />
                                    <label for="muslim_owned_business_no"> No &nbsp; &nbsp;</label>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* <div className="form-field col-md-12  m-0 p-0 pb-3">
                              <div className="form-field col-md-6">
                                <h6>Memphis Chamber Member </h6>
                              </div>
                              <div className="form-field col-md-6">
                                <div>
                                  <div class="form-group radio-block form-check inline">
                                    <input
                                      type="radio"
                                      name="memphis_chamber_mmember"
                                      id="memphis_chamber_mmember_yes"
                                      value="Yes"
                                      checked={this.state.memphis_chamber_mmember == 'Yes'}
                                      onChange={this.handleChamberModelChange}
                                    />
                                    <label for="memphis_chamber_mmember_yes"> Yes &nbsp; &nbsp;</label>
                                  </div>
                                  <div class="form-group radio-block form-check inline">
                                    <input
                                      type="radio"
                                      name="memphis_chamber_mmember"
                                      id="memphis_chamber_mmember_no"
                                      value="No"
                                      checked={this.state.memphis_chamber_mmember == 'No'}
                                      onChange={this.handleChamberModelChange}
                                    />
                                    <label for="memphis_chamber_mmember_no"> No &nbsp; &nbsp;</label>
                                  </div>
                                </div>
                                <span className="error" style={{ color: 'red' }}>
                                  {this.state.errors['company_business_model']}
                                </span>
                              </div>
                            </div> */}
                          </div>

                          <div className="form-field col-md-12 form-tags  p-0">
                            {' '}
                            <div className="md-form">
                              <p>NAICS Codes</p>
                              <Typeahead
                                clearButton
                                labelKey={(option) => `${option.NAICS_Description}` + ` (${option.NAICS})`}
                                multiple
                                options={this.state.naiccodes}
                                name="naic_code"
                                value={this.state.naiccodes}
                                onChange={(selected) => {
                                  this.setState({ naic_code: selected });
                                }}
                                class="form-control"
                                selected={this.state.naic_code}
                              />
                            </div>
                          </div>

                          <div className="certification-cbox p-0">
                            <label style={{ fontSize: '20px' }}>Certifications</label>
                            <div className="clearfix" />
                            {checkboxItems}
                          </div>

                          <span class="btn btn-sm w-100 cst-search" onClick={this.handleSubmit} title="Search Vendors">
                            Search{' '}
                            <div
                              style={this.state.searchloading ? {} : { display: 'none' }}
                              class="image-fill w-25 loader-login-div postrfp_load"
                            >
                              <img src={Loading} alt="No Image" className="can-click " />
                            </div>
                          </span>
                        </CardBody>
                      </Card>
                    </Collapse>
                  </form>
                </div>
                <div className="dashboard-rfp-head adv-search-title com-search-title">
                  <h6>Vendors List</h6>
                  <h6 className="filter">
                    <em>Filter: </em>
                    <div class="form-group form-check inline fav-block">
                      <input
                        id="checkboxinline1"
                        type="checkbox"
                        name="favorite_vendors"
                        value={this.state.favorite_vendors}
                        checked={this.state.checked}
                        onChange={this.handleCheckClickCertification}
                      />
                      <label for="checkboxinline1">
                        {' '}
                        Favorite Vendors{' '}
                        <div
                          style={this.state.loading ? {} : { display: 'none' }}
                          class="image-fill w-25 loader-login-div vendor-loading"
                        >
                          <img src={Loading} alt="No Image" className="can-click " />
                        </div>
                      </label>
                    </div>
                  </h6>{' '}
                </div>

                {/* <div className="dashboard-rfp-head">
                        <h6>Filter:  <input
                            type="checkbox"
                            name="favorite_vendors"
                            value={this.state.favorite_vendors}                      
                            checked={this.state.checked} onChange={this.handleCheckClickCertification}
                            />  Favorite Vendors</h6>
                        </div> */}

                <BootstrapTable
                  className="vd-badges adv-search "
                  data={this.state.vendordashboarddata}
                  pagination={true}
                  options={options}
                  search={true}
                  searchPlaceholder={'Search by Name or Industry or NAICS Codes'}
                >
                  {/* <TableHeaderColumn dataField='_id' isKey={ true } dataSort={ true } hidden={true}>ID</TableHeaderColumn> */}

                  <TableHeaderColumn
                    dataField="vendor_company_name"
                    dataFormat={this.logoInfo.bind(this)}
                    isKey={true}
                    dataSort={true}
                    width={'20%'}
                  >
                    Name
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField="vendor_company_address"
                    dataSort={true}
                    width={'25%'}
                    dataFormat={this.companyInfo.bind(this)}
                  >
                    Company Address{' '}
                  </TableHeaderColumn>

                  <TableHeaderColumn dataField="state" dataSort={true} hidden={true}>
                    NAICS Codes
                  </TableHeaderColumn>

                  <TableHeaderColumn dataField="city" dataSort={true} hidden={true}>
                    NAICS Codes
                  </TableHeaderColumn>

                  <TableHeaderColumn dataField="zipcode" dataSort={true} hidden={true}>
                    NAICS Codes
                  </TableHeaderColumn>

                  <TableHeaderColumn dataField="naiccodesdes" dataSort={true} hidden={true}>
                    NAICS Codes
                  </TableHeaderColumn>
                  <TableHeaderColumn dataField="industry" dataFormat={this.naicInfo.bind(this)} dataSort={true}>
                    Industry Type / NAICS Codes
                  </TableHeaderColumn>
                  {/* <TableHeaderColumn dataField='industry' dataSort={ true }>Industry Type</TableHeaderColumn> */}
                  <TableHeaderColumn dataField="Action" width={'15%'} dataFormat={this.actionBtn.bind(this)}>
                    Actions
                  </TableHeaderColumn>
                </BootstrapTable>
              </div>
            </Container>
          </div>
        </div>
      );
    } else {
      window.location.href = AppConstants.STAGRFP + '/';
    }
  }
}
companyvendors.propTypes = {
  dashboard: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
});
//export default Header;
export default connect(
  mapStateToProps,
  { dashboard }
)(withRouter(companyvendors));
