import React, { Component } from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AppConstants from '../AppConstants';
import 'react-day-picker/lib/style.css';
import moment from 'moment';
import nologo from './logo.jpg';
import { ReactTitle } from 'react-meta-tags';
import BrowserHistory from 'react-router/lib/browserHistory';
import { dashboard, handlevendordashboard } from '../actions/authentication';
class viewvendordetails extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      info1: true,
      info2: false,
      info3: false,
      info4: false,
      showCertInfo: false,
      showSocialInfo: false,
      showContactInfo: false,
      showBasic: true,
      getcustomersdata: [],
      media: [],
      accolades: [],
      naiccodesdes: [],
      certifications: [],
      membership: [],
      certification_documents: [],
      contactinfo: '#b7b7b7',
      baseinfo: '#56607a',
      basebottom: '4px solid #56607a',
      contactbottom: '4px solid #b7b7b7',
      certibottom: '4px solid #b7b7b7',
      certiinfo: '#b7b7b7',
      soclinkbottom: '4px solid #b7b7b7',
      soclink: '#b7b7b7',
      newcertifications: [],
      vendor_company_industrey: '',
      subindustries: [],
      subindustriesdata: [],
      industryname: [],
    };
    this.show = this.show.bind(this);
    this.hide = this.hide.bind(this);
    this.handleBasic = this.handleBasic.bind(this);
    this.handleContactInfo = this.handleContactInfo.bind(this);
    this.handleCertInfo = this.handleCertInfo.bind(this);
    this.handleSocialInfo = this.handleSocialInfo.bind(this);
    this.handleIndustryChange = this.handleIndustryChange.bind(this);
  }

  handleIndustryChange(vendor_company_industrey) {
    //console.log(event.target.value);
    if (vendor_company_industrey != '') {
      fetch(AppConstants.API + '/industries/getsubindustries/' + vendor_company_industrey)
        .then((response) => response.json())
        .then((data) => {
          // console.log(" sub industries ", data.subindustriesdata);
          if (data.Status == 'Success') {
            this.setState({
              subindustriesdata: data.subindustriesdata,
              vendor_company_industrey: vendor_company_industrey,
            });
          } else {
            this.setState({
              vendor_company_industrey: vendor_company_industrey,
            });
          }
        });
    } else {
      this.setState({
        vendor_company_industrey: vendor_company_industrey,
      });
    }
  }
  handleBasic(e) {
    //alert('dfddfdf');
    if (this.state.trip_purposes == 'Other') {
      this.setState({ showSocialInfo: true });
    } else {
      this.setState({ showSocialInfo: false });
    }
    this.setState({
      showContactInfo: false,
      info2: false,
      info3: false,
      info4: false,
    });
    this.setState({ showBasic: true, showCertInfo: false });
    this.setState({
      baseinfo: '#56607a',
      basebottom: '4px solid #56607a',
      contactinfo: '#b7b7b7',
      contactbottom: '4px solid #b7b7b7',
      certibottom: '4px solid #b7b7b7',
      certiinfo: '#b7b7b7',
      soclinkbottom: '4px solid #b7b7b7',
      soclink: '#b7b7b7',
    });
  }

  show() {
    this.setState({ showCertInfo: true });
  }
  hide() {
    this.setState({ showCertInfo: false });
  }

  handleContactInfo(e) {
    e.preventDefault();
    console.log('gr');
    this.setState({ showBasic: false });
    this.setState({ showCertInfo: false });
    this.setState({
      showContactInfo: true,
      info2: true,
      showSocialInfo: false,
      info3: false,
      info4: false,
    });
    this.setState({
      contactinfo: '#56607a',
      contactbottom: '4px solid #56607a',
      certibottom: '4px solid #b7b7b7',
      certiinfo: '#b7b7b7',
      soclinkbottom: '4px solid #b7b7b7',
      soclink: '#b7b7b7',
      baseinfo: '#b7b7b7',
      basebottom: '4px solid #b7b7b7',
    });
  }
  handleCertInfo(e) {
    e.preventDefault();
    this.setState({ showBasic: false });
    this.setState({ showContactInfo: false });
    this.setState({
      showCertInfo: true,
      info3: true,
      showSocialInfo: false,
      info4: false,
    });
    this.setState({
      certibottom: '4px solid #56607a',
      certiinfo: '#56607a',
      soclinkbottom: '4px solid #b7b7b7',
      soclink: '#b7b7b7',
      baseinfo: '#b7b7b7',
      basebottom: '4px solid #b7b7b7',
      contactinfo: '#b7b7b7',
      contactbottom: '4px solid #b7b7b7',
    });
  }
  handleSocialInfo(e) {
    e.preventDefault();
    this.setState({ showBasic: false });
    this.setState({ showContactInfo: false });
    this.setState({ showCertInfo: false });
    this.setState({ showSocialInfo: true, info4: true });
    this.setState({
      soclinkbottom: '4px solid #56607a',
      soclink: '#56607a',
      baseinfo: '#b7b7b7',
      basebottom: '4px solid #b7b7b7',
      contactinfo: '#b7b7b7',
      contactbottom: '4px solid #b7b7b7',
      certibottom: '4px solid #b7b7b7',
      certiinfo: '#b7b7b7',
    });
  }
  componentDidMount() {
    if (this.props.auth.user.type == 'company') {
      this.props.dashboard('vendors');
    } else {
      this.props.handlevendordashboard('vendorvendors');
    }
    window.scrollTo(0, 0);
    //Get vendors details
    fetch(AppConstants.API + '/vendors/getcompanysidevendordetails/' + this.props.match.params.id)
      // fetch(AppConstants.API + '/vendors/getvendordetails/' + this.props.match.params.id)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.Status == 'Success') {
          let certdata;
          if (data.getvendorsdata.certifications == undefined) {
            certdata = 0;
          } else {
            certdata = data.getvendorsdata.certifications;
          }
          var subcat = [];
          if (data.getvendorsdata.subindustries && data.getvendorsdata.subindustries.length > 0) {
            subcat = data.getvendorsdata.subindustries;
          }

          const today = moment(data.getvendorsdata.businessStartedDate).format('DD-MM-YYYY');
          this.setState({
            id: this.props.match.params.id,
            vendor_id: data.getvendorsdata._id,
            vendor_company_name: data.getvendorsdata.vendor_company_name,
            vendor_company_email: data.getvendorsdata.vendor_company_email,
            vendor_company_type: data.getvendorsdata.company,
            vendor_company_industrey: data.getvendorsdata.industry,
            vendor_company_address: data.getvendorsdata.vendor_company_address,
            vendor_product_needs: data.getvendorsdata.vendor_product_needs || "N/A",
            vendor_company_address2: data.getvendorsdata.vendor_company_address2,
            vendor_company_phoneno: data.getvendorsdata.vendor_company_phoneno || "N/A",
            vendor_company_website: data.getvendorsdata.vendor_company_website,
            vendor_primary_contact_firstname: data.getvendorsdata.vendor_primary_contact_firstname || "N/A",
            vendor_primary_contact_lastname: data.getvendorsdata.vendor_primary_contact_lastname || "N/A",
            vendor_primary_contact_middlename: data.getvendorsdata.vendor_primary_contact_middlename || "N/A",
            vendor_primary_contact_title: data.getvendorsdata.vendor_primary_contact_title,
            vendor_primary_contact_phoneno: data.getvendorsdata.vendor_primary_contact_phoneno || "N/A",
            vendor_primary_email: data.getvendorsdata.vendor_primary_email || "N/A",
            vendor_primary_cellno: data.getvendorsdata.vendor_primary_cellno,
            vendor_primary_officeno: data.getvendorsdata.vendor_primary_officeno,
            vendor_primary_department: data.getvendorsdata.vendor_primary_department || "N/A",
            certificationsandmembership: data.getvendorsdata.certificationsandmembership,
            //naic_code: data.getvendorsdata.naic_code,
            naiccodesdes: data.getvendorsdata.naiccodesdes,
            descriptionofproductsandservices: data.getvendorsdata.descriptionofproductsandservices,
            companyleadership: data.getvendorsdata.companyleadership || "N/A",
            department_min_revenue: data.getvendorsdata.department_min_revenue,
            department_max_revenue: data.getvendorsdata.department_max_revenue || "N/A",
            employees: data.getvendorsdata.employees || "N/A",
            locationsOfOffices: data.getvendorsdata.locationsOfOffices,
            latitude: data.getvendorsdata.latitude,
            longitude: data.getvendorsdata.longitude,
            country: data.getvendorsdata.country,
            state: data.getvendorsdata.state,
            city: data.getvendorsdata.city,
            zipcode: data.getvendorsdata.zipcode,
            companyLogo: data.getvendorsdata.companyLogo || nologo,
            view_companyLogo: data.getvendorsdata.companyLogo,
            prev_companyLogo: data.getvendorsdata.companyLogo,
            uploadVideo: data.getvendorsdata.uploadVideo,
            view_uploadVideo: data.getvendorsdata.uploadVideo,
            prev_uploadVideo: data.getvendorsdata.uploadVideo,
            instagramLink: data.getvendorsdata.instagramLink,
            facebookLink: data.getvendorsdata.facebookLink,
            linkedInLink: data.getvendorsdata.linkedInLink,
            youTubeLik: data.getvendorsdata.youTubeLik,
            twitterLink: data.getvendorsdata.twitterLink,
            businessStartedDate: today,
            //businessStartedDate: data.getvendorsdata.businessStartedDate,
            status: data.getvendorsdata.status,
            deleted: 0,
            accolades: data.getvendorsdata.accolades || "N/A",
            //multipleaccolades: data.getvendorsdata.accolades[0],
            // multipleValues:data.getvendorsdata.media[0],
            media: data.getvendorsdata.media || "N/A",
            leadershiptitle: data.getvendorsdata.leadershiptitle || "N/A",
            leadershipemail: data.getvendorsdata.leadershipemail || "N/A",
            membership: data.getvendorsdata.membership,
            vendor_business_model: data.getvendorsdata.vendor_business_model || "N/A",
            certifications: certdata,
            certification_documents: data.getvendorsdata.certification_documents,
            muslim_owned_business: data.getvendorsdata.muslim_owned_business || "N/A",
            memphis_chamber_mmember: data.getvendorsdata.memphis_chamber_mmember || "N/A",
            newcertifications: data.getvendorsdata.newcertifications,
            subindustries: subcat,
            vendor_company_industrey: data.getvendorsdata.vendor_company_industrey,
            industryname: data.getvendorsdata.industry,
          });
          //this.handleCountryChange();
          // this.handleStateChange();
          if (data.getvendorsdata.vendor_company_industrey && data.getvendorsdata.vendor_company_industrey != null) {
            this.handleIndustryChange(this.state.vendor_company_industrey);
          }
        } else {
          this.setState({ getcustomersdata: '' });
        }
      });
  }
  render() {
    var k = 1;
    if (localStorage.jwtToken != undefined) {
      let vendors;
      if (this.props.auth.user.type == 'company') {
        vendors = (
          <Link
            to={`${AppConstants.STAGRFP}${'/vendorslist'}`}
            className="btn btn-sm fright"
            title="Back to Vendors List"
          >
            Back to Vendors List
          </Link>
        );
      } else {
        vendors = (
          <Link to={`${AppConstants.STAGRFP}${'/vendors/'}`} className="btn btn-sm fright" title="Back to Vendors List">
            Back to Vendors List
          </Link>
        );
      }
      let businessmodel;
      if (this.state.vendor_business_model == 'Both') {
        businessmodel = 'B2B & B2C';
      } else if (this.state.vendor_business_model == 'B2B') {
        businessmodel = 'B2B';
      } else if (this.state.vendor_business_model == 'B2C') {
        businessmodel = 'B2C';
      } else {
        businessmodel = 'N/A';
      }

      return <div>
          <ReactTitle title="Vendor Profile" />
          <div className="inner-banner" />
          <div className="inner-content rfpviewdetail view-vendor-details">
            <Container>
              <div className="text-btn-div">
                <h1 className="color-yellow ex-title">{this.state.vendor_company_name} </h1>
                <button onClick={BrowserHistory.goBack} className="btn btn-sm fright back-btn">
                  Back
                </button>
              </div>
              <div className="white-bg pad50 vendor-tabs-sec">
                <Row className="justify-content-center">
                  <Col md="11">
                    {/* <h1 className="color-yellow">{this.state.vendor_company_name}Vendor Profile</h1> */}

                    <Row className="vendor-tab-list">
                      <span className="col-md-3">
                        <Button className="basic-info" style={{ borderBottom: this.state.basebottom }} onClick={this.handleBasic}>
                          {/* //disabled={!this.state.info1} */}
                          <span style={{ color: this.state.baseinfo }}>Basic Information</span>
                        </Button>
                      </span>
                      <span className="col-md-3">
                        <Button //className="contact-info"
                          onClick={this.handleContactInfo} style={{ borderBottom: this.state.contactbottom }}>
                          {/* //disabled={!this.state.info2} */}
                          <span style={{ color: this.state.contactinfo }}>Contact Information</span>
                        </Button>
                      </span>
                      <span className="col-md-3">
                        <Button className="certificate-info " onClick={this.handleCertInfo} style={{ borderBottom: this.state.certibottom } //disabled={!this.state.info3}
                          }>
                          <span style={{ color: this.state.certiinfo }}>Certifications & Memberships</span>
                        </Button>
                      </span>
                      <span className="col-md-3">
                        <Button className="social-info" onClick={this.handleSocialInfo} style={{ borderBottom: this.state.soclinkbottom } //disabled={!this.state.info4}
                          }>
                          <span style={{ color: this.state.soclink }}>Social Links</span>
                        </Button>
                      </span>
                    </Row>
                    <div>
                      {this.state.showBasic ? <div className="vendot-info-detail">
                          <div className="col-md-12 avatar-main">
                            <div className="avatar-block">
                              <img src={this.state.companyLogo} />
                            </div>
                          </div>

                          <div className="vendor-detail">
                            <Row className="marg">
                              <div className="form-field  col-md-4">
                                <span>Name</span>
                              </div>
                              <div className="form-field  col-md-8">
                                <h6>{this.state.vendor_company_name}</h6>
                              </div>
                            </Row>
                            <Row className="marg">
                              <div className="form-field  col-md-4">
                                <span>Email</span>
                              </div>
                              <div className="form-field  col-md-8">
                                <h6>{this.state.vendor_company_email}</h6>
                              </div>
                            </Row>
                            <Row className="marg">
                              <div className="form-field  col-md-4">
                                <span>Company Type</span>
                              </div>
                              <div className="form-field  col-md-8">
                                <h6>{this.state.vendor_company_type ? this.state.vendor_company_type : "N/A"}</h6>
                              </div>
                            </Row>
                            <Row className="marg">
                              <div className="form-field  col-md-4">
                                <span>Industry Type</span>
                              </div>
                              <div className="form-field  col-md-8">
                                <h6>{this.state.industryname ? this.state.industryname : "N/A"}</h6>
                              </div>
                            </Row>
                            <Row className="marg">
                              <div className="form-field col-md-4">
                                <span>Sub Industry</span>
                              </div>
                              <div className="form-field  col-md-8">
                                <ul className="recipe-list recipe-list-view-list" style={{ listStyle: "none", padding: "0" }}>
                                  {this.state.subindustries && this.state.subindustries != undefined && this.state.subindustries.length > 0 && this.state.subindustries.map(
                                      (recipe) => {
                                        if (this.state.subindustries && this.state.subindustries.length > 0) {
                                          if (
                                            this.state.subindustriesdata &&
                                            this.state.subindustriesdata.length > 0
                                          ) {
                                            return (
                                              <React.Fragment>
                                                {this.state.subindustriesdata.map((value, index) => {
                                                  return (
                                                    <React.Fragment>
                                                      {value.subcatid == recipe ? (
                                                        <li>
                                                          {value.subindustryname}
                                                          <br />
                                                        </li>
                                                      ) : (
                                                        ""
                                                      )}
                                                    </React.Fragment>
                                                  );
                                                })}
                                              </React.Fragment>
                                            );
                                          }
                                        } else {
                                          return <h6 style={{ color: "#ced0d4" }}>No Sub Industries</h6>;
                                        }
                                      }
                                    )}
                                  {this.state.subindustries && this.state.subindustries.length == 0 && <h6
                                      style={{ color: "#ced0d4" }}
                                    >
                                      No Sub Industries
                                    </h6>}
                                </ul>
                              </div>
                            </Row>
                            <Row className="marg">
                              <div className="form-field  col-md-4">
                                <span>Address</span>
                              </div>
                              <div className="form-field  col-md-8">
                                <h6>{this.state.vendor_company_address}</h6>
                              </div>
                            </Row>
                            <Row className="marg">
                              <div className="form-field  col-md-4">
                                <span>City</span>
                              </div>
                              <div className="form-field  col-md-8">
                                <h6>{this.state.city}</h6>
                              </div>
                            </Row>
                            <Row className="marg">
                              <div className="form-field  col-md-4">
                                <span>State</span>
                              </div>
                              <div className="form-field  col-md-8">
                                <h6>{this.state.state}</h6>
                              </div>
                            </Row>
                            <Row className="marg">
                              <div className="form-field  col-md-4">
                                <span>Zip Code</span>
                              </div>
                              <div className="form-field  col-md-8">
                                <h6>{this.state.zipcode}</h6>
                              </div>
                            </Row>
                            <Row className="marg">
                              <div className="form-field  col-md-4">
                                <span>Product Needs</span>
                              </div>
                              <div className="form-field  col-md-8">
                                <h6>{this.state.vendor_product_needs}</h6>
                              </div>
                            </Row>
                            <Row className="marg">
                              <div className="form-field  col-md-4">
                                <span>Business Model</span>
                              </div>
                              <div className="form-field  col-md-8">
                                <h6>{businessmodel}</h6>
                              </div>
                            </Row>
                            <Row className="marg">
                              <div className="form-field  col-md-4">
                                <span>Phone Number</span>
                              </div>
                              <div className="form-field  col-md-8">
                                <h6>{this.state.vendor_company_phoneno}</h6>
                              </div>
                            </Row>
                            <Row className="marg">
                              <div className="form-field  col-md-4">
                                <span>Business Start Date</span>
                              </div>
                              <div className="form-field  col-md-8">
                                {/* <h6>{this.state.businessStartedDate}</h6> */}
                                {this.state.businessStartedDate == "undefined" || this.state.businessStartedDate == "" ? <h6>
                                    {this.state.businessStartedDate}
                                  </h6> : <h6>N/A</h6>}
                              </div>
                            </Row>
                            <Row className="marg">
                              <div className="form-field  col-md-4">
                                <span>Revenue Range</span>
                              </div>
                              <div className="form-field  col-md-8">
                                <h6>{this.state.department_max_revenue}</h6>
                              </div>
                            </Row>
                            <Row className="marg">
                              <div className="form-field  col-md-4">
                                <span>No. of Employees</span>
                              </div>
                              <div className="form-field  col-md-8">
                                <h6>{this.state.employees}</h6>
                              </div>
                            </Row>
                            <Row className="marg">
                              <div className="form-field  col-md-4">
                                <span>Website</span>
                              </div>
                              <div className="form-field  col-md-8">
                                <h6>
                                  {this.state.vendor_company_website ? <div>
                                      <a href={this.state.vendor_company_website} target="_blank">
                                        {this.state.vendor_company_website}
                                      </a>
                                    </div> : <h6>N/A</h6>}
                                </h6>
                              </div>
                            </Row>
                            <Row className="marg">
                              <div className="form-field  col-md-4">
                                <span>Media Links</span>
                              </div>
                              <div className="form-field  col-md-8">
                                {this.state.media.length > 0 ? <ul className="media-links">
                                    {this.state.media.map((test, index) => {
                                      return <li>
                                          {" "}
                                          <h6>
                                            <a href={test} target="_blank">
                                              {test}
                                            </a>
                                          </h6>
                                        </li>;
                                    })}
                                  </ul> : ""}
                                {this.state.media.length == 0 && <h6>No Media Links</h6>}
                              </div>
                            </Row>
                            <Row className="marg">
                              <div className="form-field  col-md-4">
                                <span>Accolades</span>
                              </div>
                              <div className="form-field  col-md-8">
                                {this.state.accolades.length > 0 ? <ul className="media-links">
                                    {this.state.accolades.map((accolade, index) => {
                                      return <li>
                                          <h6>{accolade}</h6>
                                        </li>;
                                    })}
                                  </ul> : ""}
                                {this.state.accolades.length == 0 && <h6>No Accolades</h6>}
                              </div>
                            </Row>
                            <Row className="marg">
                              <div className="form-field  col-md-4">
                                <span>NAICS Codes</span>
                              </div>
                              <div className="form-field  col-md-8">
                                <ul className="recipe-list recipe-list-view-list" style={{ listStyle: "none", padding: "0" }}>
                                  {this.state.naiccodesdes.map((recipe) => {
                                    return <li>{recipe}</li>;
                                  })}
                                  {this.state.naiccodesdes.length == 0 && <li>No Naics codes</li>}
                                </ul>
                              </div>
                            </Row>
                          </div>
                          <div className="vendor-detail">
                            <h2 className="vi-title
">
                              Company Leadership
                            </h2>
                            <Row className="marg">
                              <div className="form-field  col-md-4">
                                <span>Title</span>
                              </div>
                              <div className="form-field  col-md-8">
                                <h6>{this.state.leadershiptitle}</h6>
                              </div>
                            </Row>
                            <Row className="marg">
                              <div className="form-field  col-md-4">
                                <span>Name</span>
                              </div>
                              <div className="form-field  col-md-8">
                                <h6>{this.state.companyleadership}</h6>
                              </div>
                            </Row>
                            <Row className="marg">
                              <div className="form-field  col-md-4">
                                <span>Email</span>
                              </div>
                              <div className="form-field  col-md-8">
                                <h6>{this.state.leadershipemail}</h6>
                              </div>
                            </Row>
                            <Row className="marg">
                              <div className="form-field  col-md-4">
                                <span>Phone Number</span>
                              </div>
                              <div className="form-field  col-md-8">
                                <h6>{this.state.vendor_primary_contact_phoneno}</h6>
                              </div>
                            </Row>
                          </div>
                        </div> : null}
                      {this.state.showContactInfo ? <div className="vendot-info-detail">
                          <Row className="marg">
                            <div className="form-field  col-md-4">
                              <span>First Name</span>
                            </div>
                            <div className="form-field  col-md-8">
                              <h6>{this.state.vendor_primary_contact_firstname}</h6>
                            </div>
                          </Row>
                          <Row className="marg">
                            <div className="form-field  col-md-4">
                              <span>Middle Name</span>
                            </div>
                            <div className="form-field  col-md-8">
                              <h6>{this.state.vendor_primary_contact_middlename}</h6>
                            </div>
                          </Row>
                          <Row className="marg">
                            <div className="form-field  col-md-4">
                              <span>Last Name</span>
                            </div>
                            <div className="form-field  col-md-8">
                              <h6>{this.state.vendor_primary_contact_lastname}</h6>
                            </div>
                          </Row>
                          <Row className="marg">
                            <div className="form-field  col-md-4">
                              <span>Email</span>
                            </div>
                            <div className="form-field  col-md-8">
                              <h6>{this.state.vendor_primary_email}</h6>
                            </div>
                          </Row>
                          <Row className="marg">
                            <div className="form-field  col-md-4">
                              <span>Department</span>
                            </div>
                            <div className="form-field  col-md-8">
                              <h6>{this.state.vendor_primary_department}</h6>
                            </div>
                          </Row>
                        </div> : null}
                      {this.state.showCertInfo ? <div className="vendot-info-detail">
                          <Row className="marg">
                            <div className="form-field  col-md-4">
                              <span>Are you a Minority Owned Business?</span>
                            </div>
                            <div className="form-field  col-md-8">
                              <h6>{this.state.muslim_owned_business}</h6>
                            </div>
                          </Row>
                          {/* <Row className="marg">
                            <div className="form-field  col-md-4">
                              <span>Are you a Greater Memphis Chamber member?</span>
                            </div>
                            <div className="form-field  col-md-8">
                              <h6>{this.state.memphis_chamber_mmember}</h6>
                            </div>
                          </Row> */}
                          <Row className="marg">
                            <div className="form-field  col-md-4">
                              <span>Certifications</span>
                            </div>
                            <div className="form-field  col-md-8">
                              <ul className="recipe-list recipe-list-view-list" style={{ listStyle: "none", padding: "0" }}>
                                {/* {this.state.certifications.length > 0 &&  this.state.certifications.map(recipe => { */}
                                {this.state.newcertifications.length > 0 && this.state.newcertifications.map(
                                    (recipe) => {
                                      if (
                                        this.state.certification_documents &&
                                        this.state.certification_documents.length > 0
                                      ) {
                                        return (
                                          <li>
                                            {this.state.certification_documents.map((value, index) => {
                                              return (
                                                <div key={index}>
                                                  {value.certificationid == recipe._id ? (
                                                    <div>
                                                      <h6>{recipe.certification_name}</h6>
                                                      <div className="filebtn">
                                                        <a href={value.certificationurl} target="_blank">
                                                          <i class="fa fa-file-pdf-o" aria-hidden="true" />{" "}
                                                          Document {k++}{" "}
                                                        </a>
                                                      </div>
                                                    </div>
                                                  ) : (
                                                    ""
                                                  )}
                                                </div>
                                              );
                                            })}
                                          </li>
                                        );
                                      } else {
                                        return (
                                          <li>
                                            <div>{recipe.certification_name}</div>
                                          </li>
                                        );
                                      }
                                    }
                                  )}
                                {this.state.newcertifications.length == 0 && <h6>No Certifications</h6>}
                              </ul>
                            </div>
                          </Row>

                          <Row className="marg">
                            <div className="form-field  col-md-4">
                              <span>Membership</span>
                            </div>
                            <div className="form-field  col-md-8">
                              <ul className="recipe-list recipe-list-view-list" style={{ listStyle: "none", padding: "0" }}>
                                {this.state.membership.map((test, index) => {
                                  return <li>{test}</li>;
                                })}
                                {this.state.membership.length == 0 && <h6>No Membership</h6>}
                              </ul>
                            </div>
                          </Row>
                        </div> : null}
                      {this.state.showSocialInfo ? <div className="vendot-info-detail">
                          <div className="vendor-detail">
                            <Row className="marg">
                              <div className="form-field  col-md-4">
                                <span>Facebook</span>
                              </div>
                              <div className="form-field  col-md-8">
                                <h6>
                                  {this.state.facebookLink && this.state.facebookLink != "undefined" ? <a href={this.state.facebookLink} target="_blank">
                                      {this.state.facebookLink}
                                    </a> : <h6>N/A</h6>}
                                </h6>
                              </div>
                            </Row>
                            <Row className="marg">
                              <div className="form-field  col-md-4">
                                <span>LinkedIn</span>
                              </div>
                              <div className="form-field  col-md-8">
                                <h6>
                                  {this.state.linkedInLink && this.state.linkedInLink != "undefined" ? <a href={this.state.linkedInLink} target="_blank">
                                      {this.state.linkedInLink}
                                    </a> : <h6>N/A</h6>}
                                </h6>
                              </div>
                            </Row>
                            <Row className="marg">
                              <div className="form-field  col-md-4">
                                <span>YouTube</span>
                              </div>
                              <div className="form-field  col-md-8">
                                <h6>
                                  {this.state.youTubeLik && this.state.youTubeLik != "undefined" ? <a href={this.state.youTubeLik} target="_blank">
                                      {this.state.youTubeLik}
                                    </a> : <h6>N/A</h6>}
                                </h6>
                              </div>
                            </Row>
                            <Row className="marg">
                              <div className="form-field  col-md-4">
                                <span>Twitter</span>
                              </div>
                              <div className="form-field  col-md-8">
                                <h6>
                                  {this.state.twitterLink && this.state.twitterLink != "undefined" ? <a href={this.state.twitterLink} target="_blank">
                                      {this.state.twitterLink}
                                    </a> : <h6>N/A</h6>}
                                </h6>
                              </div>
                            </Row>
                            <Row className="marg">
                              <div className="form-field  col-md-4">
                                <span>Instagram</span>
                              </div>
                              <div className="form-field  col-md-8">
                                <h6>
                                  {this.state.instagramLink && this.state.instagramLink != "undefined" ? <a href={this.state.instagramLink} target="_blank">
                                      {this.state.instagramLink}
                                    </a> : <h6>N/A</h6>}
                                </h6>
                              </div>
                            </Row>
                          </div>
                        </div> : null}
                    </div>
                  </Col>
                </Row>
                {this.state.showContactInfo ? <Row className="justify-content-center">
                    <Col md="11">
                      <span class="btn btn-sm btn-white-bg fleft" onClick={this.handleBasic} title="Previous">
                        <i class="fa fa-angle-left" aria-hidden="true" />
                        Previous
                      </span>
                      <span class="btn btn-sm fright" onClick={this.handleCertInfo} title="Next">
                        Next
                        <i class="fa fa-angle-right" aria-hidden="true" />
                      </span>
                    </Col>
                  </Row> : null}
                {this.state.showCertInfo ? <Row className="justify-content-center">
                    <Col md="11">
                      <span class="btn btn-sm btn-white-bg fleft" onClick={this.handleContactInfo} title="Previous">
                        <i class="fa fa-angle-left" aria-hidden="true" />
                        Previous
                      </span>
                      <span class="btn btn-sm fright" onClick={this.handleSocialInfo} title="Next">
                        Next
                        <i class="fa fa-angle-right" aria-hidden="true" />
                      </span>
                    </Col>
                  </Row> : null}
                {this.state.showSocialInfo ? <Row className="justify-content-center">
                    <Col md="11">
                      <span class="btn btn-sm btn-white-bg fleft" onClick={this.handleCertInfo} title="Previous">
                        <i class="fa fa-angle-left" aria-hidden="true" />
                        Previous
                      </span>
                      <span class="fright">{vendors}</span>
                    </Col>
                  </Row> : null}
                {this.state.showBasic ? <Row className="justify-content-center">
                    <Col md="11">
                      <span class="btn btn-sm fright" onClick={this.handleContactInfo}>
                        Next
                        <i class="fa fa-angle-right" aria-hidden="true" />
                      </span>
                    </Col>
                  </Row> : null}
              </div>
            </Container>
          </div>
        </div>;
    } else {
      window.location.href = AppConstants.STAGRFP + '/';
    }
  }
}
viewvendordetails.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  dashboard: PropTypes.func.isRequired,
  handlevendordashboard: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(
  mapStateToProps,
  { dashboard, handlevendordashboard }
)(viewvendordetails);
